import React, { useState } from "react";
import styled from "styled-components";

import { type } from "../../theme";

import SelectorWrapper from "./SelectorWrapper";
import HiddenInput from "../utility/HiddenInput";
import PaddedLabel from "./PaddedLabel";

const Label = styled(PaddedLabel)`
  width: 100%;
  clear: both;
  transition: color 0.05s linear;
  color: ${({ selected }) => (selected ? "white" : "#fff7")};
  background-color: ${({ typeColour }) => type(typeColour)};
`;

export default ({
  name = "appraisal-selector-",
  value0 = "0",
  value1 = "1",
  value2 = "2",
  value3 = "3",
  value = value0,
  setValue = () => undefined,
  best = "best",
  second = "second",
  third = "third",
  fourth = "fourth",
  color = {
    best: "grass",
    second: "water",
    third: "dragon",
    fourth: "ghost"
  }
}) => {
  return (
    <SelectorWrapper>
      <HiddenInput
        type={"radio"}
        name={name}
        value={value0}
        id={name + value0}
        onChange={event => {
          setValue(event.target.value);
        }}
      />
      <Label
        htmlFor={name + value0}
        typeColour={color.best}
        selected={value === value0}
      >
        {best}
      </Label>
      <HiddenInput
        type={"radio"}
        name={name}
        value={value1}
        id={name + value1}
        onChange={event => {
          setValue(event.target.value);
        }}
      />
      <Label
        htmlFor={name + value1}
        typeColour={color.second}
        selected={value === value1}
      >
        {second}
      </Label>
      <HiddenInput
        type={"radio"}
        name={name}
        value={value2}
        id={name + value2}
        onChange={event => {
          setValue(event.target.value);
        }}
      />
      <Label
        htmlFor={name + value2}
        typeColour={color.third}
        selected={value === value2}
      >
        {third}
      </Label>
      <HiddenInput
        type={"radio"}
        name={name}
        value={value3}
        id={name + value3}
        onChange={event => {
          setValue(event.target.value);
        }}
      />
      <Label
        htmlFor={name + value3}
        typeColour={color.fourth}
        selected={value === value3}
      >
        {fourth}
      </Label>
    </SelectorWrapper>
  );
};
