import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.colour.light }) => {
  const c0 = shiny ? "#b2edf5" : "#80ccd6";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Squirtle Portrait"
      id="Squirtle_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Squirtle</title>
      <path
        d="M50.23,5.61c21.16,0,35.26,11.29,39.65,21.94s5,24.61,6.11,33.86,0,12.07,0,12.07S83.77,93.85,50.23,93.85h-.46C16.23,93.85,4,73.48,4,73.48s-1.1-2.82,0-12.07,1.72-23.2,6.11-33.86S28.61,5.61,49.77,5.61h.46Z"
        fill={c0}
      />
      <path
        d="M10.12,27.55C5.73,38.21,5.1,52.16,4,61.41S4,73.48,4,73.48,16.23,93.85,49.77,93.85h.46C83.77,93.85,96,73.48,96,73.48s1.1-2.82,0-12.07-1.72-23.2-6.11-33.86S71.39,5.61,50.23,5.61h-.46C28.61,5.61,14.51,16.9,10.12,27.55ZM1.66,74.39c-.22-.57-1.27-3.88-.16-13.28.21-1.7.39-3.58.59-5.57.89-8.88,2-19.93,5.7-28.94A30.71,30.71,0,0,1,13.31,18a40.27,40.27,0,0,1,9-7.52A52,52,0,0,1,49.77,3.09h.46a52,52,0,0,1,27.44,7.35,40.27,40.27,0,0,1,9,7.52,30.71,30.71,0,0,1,5.52,8.64c3.71,9,4.81,20.06,5.7,28.94.2,2,.38,3.87.59,5.57,1.11,9.4.06,12.71-.16,13.28l-.08.2-.11.18c-.13.23-3.41,5.62-11,11a56.1,56.1,0,0,1-14.83,7.34,71.42,71.42,0,0,1-22.08,3.3h-.46a71.42,71.42,0,0,1-22.08-3.3,56.1,56.1,0,0,1-14.83-7.34C5.26,80.39,2,75,1.85,74.77l-.11-.18Z"
        fill={outline}
      />
      <path
        d="M14.82,60.63c-3-3.92-3.92-25.55,7.37-26.81s10.34,26,9.25,27.43S17.8,64.54,14.82,60.63Z"
        fill={"#fff"}
      />
      <path
        d="M22.19,33.82a8.09,8.09,0,0,0-2.87.87,37.61,37.61,0,0,0-2.77,14.49,38.23,38.23,0,0,0,2.36,13.47c4.83.91,11.72-.37,12.53-1.4C32.53,59.84,33.47,32.57,22.19,33.82Z"
        fill={outline}
      />
      <path
        d="M24.59,34c-2.49.39-4.44,3.7-4.44,7.72,0,4.28,2.21,7.76,4.94,7.76S30,46,30,41.74a13.22,13.22,0,0,0-.08-1.42C28.79,37.2,27.08,34.77,24.59,34Z"
        fill={"#fff"}
      />
      <path
        d="M24.93,52.16c-3.33,0-6,3-6,6.74A7.23,7.23,0,0,0,20,62.82a26.56,26.56,0,0,0,10.33-1,7.22,7.22,0,0,0,.61-3C31,55.18,28.26,52.16,24.93,52.16Z"
        fill={"#a13452"}
      />
      <path
        d="M31.75,35.32a.4.4,0,0,1-.39-.29c-.58-2.19-3.57-4.3-3.6-4.32a.4.4,0,0,1-.1-.57.4.4,0,0,1,.56-.09c.13.09,3.26,2.3,3.92,4.77a.4.4,0,0,1-.29.49Z"
        fill={outline}
      />
      <path
        d="M84.8,60.63c3-3.92,3.92-25.55-7.36-26.81s-10.35,26-9.25,27.43S81.83,64.54,84.8,60.63Z"
        fill={"#fff"}
      />
      <path
        d="M77.44,33.82a8,8,0,0,1,2.86.87,37.61,37.61,0,0,1,2.78,14.49,38,38,0,0,1-2.37,13.47c-4.82.91-11.72-.37-12.52-1.4C67.09,59.84,66.15,32.57,77.44,33.82Z"
        fill={outline}
      />
      <path
        d="M75,34c2.5.39,4.44,3.7,4.44,7.72,0,4.28-2.21,7.76-4.93,7.76S69.6,46,69.6,41.74a13.22,13.22,0,0,1,.08-1.42C70.83,37.2,72.54,34.77,75,34Z"
        fill={"#fff"}
      />
      <path
        d="M74.69,52.16c3.34,0,6,3,6,6.74a7.24,7.24,0,0,1-1.13,3.92,26.56,26.56,0,0,1-10.33-1,7.39,7.39,0,0,1-.61-3C68.66,55.18,71.36,52.16,74.69,52.16Z"
        fill={"#a13452"}
      />
      <path
        d="M67.88,35.32h-.11a.4.4,0,0,1-.28-.49c.65-2.47,3.78-4.68,3.92-4.77a.4.4,0,0,1,.56.09.41.41,0,0,1-.1.57s-3,2.14-3.6,4.32A.4.4,0,0,1,67.88,35.32Z"
        fill={outline}
      />
      <path
        d="M45.85,72a.4.4,0,0,1-.4-.38c0-.79-1.6-1.74-2.58-2.16a.41.41,0,0,1-.21-.53.41.41,0,0,1,.53-.21c.31.13,3,1.32,3.07,2.85a.4.4,0,0,1-.38.43Z"
        fill={outline}
      />
      <path
        d="M53.85,72h0a.39.39,0,0,1-.38-.43c.09-1.53,2.76-2.72,3.06-2.85a.4.4,0,0,1,.32.74c-1,.42-2.53,1.37-2.58,2.16A.4.4,0,0,1,53.85,72Z"
        fill={outline}
      />
      <path
        d="M49.7,81.17c-1.4,0-2.81-1-4.3-2.15A11.45,11.45,0,0,0,41,76.62c-.7-.12-2.66-.19-5.14-.28-12-.43-23.82-1.15-23.88-4.81a.4.4,0,0,1,.8,0c0,.75,1.11,2.13,8.27,3,4.8.62,10.6.83,14.84,1,2.61.09,4.49.16,5.24.28a12,12,0,0,1,4.74,2.55c1.38,1,2.69,2,3.82,2a.41.41,0,0,1,0,.81Z"
        fill={outline}
      />
      <path
        d="M49.85,81.17a.41.41,0,0,1,0-.81c1.13,0,2.43-1,3.82-2a12,12,0,0,1,4.73-2.55c.76-.12,2.64-.19,5.25-.28,4.23-.15,10-.36,14.83-1,7.17-.92,8.26-2.3,8.27-3a.41.41,0,0,1,.81,0c-.06,3.66-11.83,4.38-23.88,4.81-2.48.09-4.44.16-5.14.28A11.38,11.38,0,0,0,54.15,79C52.65,80.12,51.24,81.17,49.85,81.17Z"
        fill={outline}
      />
    </SVG>
  );
};
