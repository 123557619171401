import React from "react";
import { useNameMap, usePokemon } from "../component/PokemonProvider";
import _form from "../assets/data/form";

export default (number, form = 0) => {
  const pokemon = usePokemon();
  const nameMap = useNameMap();
  let name =
    (nameMap && nameMap[number]) ||
    (pokemon && pokemon[number] && pokemon[number].name) ||
    `#${number}`;
  if (_form && _form[form]) {
    name += " " + _form[form];
  }
  return name;
};
