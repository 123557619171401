import React, { createContext, useContext } from "react";

const PokemonContext = createContext([]);
const PokemonMapContext = createContext({});

export const usePokemon = () => useContext(PokemonContext);
export const useNameMap = () => useContext(PokemonMapContext);

export default ({ pokemonData, pokemonNameNumberMap, children }) => (
  <PokemonContext.Provider value={pokemonData}>
    <PokemonMapContext.Provider value={pokemonNameNumberMap}>
      {children}
    </PokemonMapContext.Provider>
  </PokemonContext.Provider>
);
