import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.colour.light }) => {
  const c3 = shiny ? "#cb4862" : "#ad3722";
  const c0 = shiny ? "#d12e51" : "#d8720d";
  const c2 = shiny ? "#ffd88c" : "#ffd88c";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Chimchar Portrait"
      id="Chimchar_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Chimchar</title>
      <path
        d="M65,28.78C62.21,22.53,51,8.65,42,6.36c3.07,6.37-.84,15.62-.84,21.33C26.72,32,77.84,33.92,65,28.78Z"
        fill={c0}
      />
      <path
        d="M42,6.36C51,8.65,62.21,22.53,65,28.78c5.41,2.17-.58,3.08-8.22,3.08-10.45,0-24-1.7-15.63-4.17,0-5.71,3.91-15,.84-21.33M37.48,2.63l2.31,4.8c1.69,3.51.72,8.62-.14,13.12-.36,1.88-.7,3.68-.86,5.35a8.35,8.35,0,0,0-.95.49,3.16,3.16,0,0,0-1.71,3.21c.31,2.15,2.68,2.84,3.56,3.11a32.67,32.67,0,0,0,4.75.9,102.45,102.45,0,0,0,12.37.73A58.08,58.08,0,0,0,64.26,34,13.47,13.47,0,0,0,67,33.36a3.42,3.42,0,0,0,2.54-2.55,3.12,3.12,0,0,0-1.35-3.14,7.54,7.54,0,0,0-1.32-.79,60.07,60.07,0,0,0-9.24-12.5c-3.74-4-9.38-9-15-10.43L37.48,2.63Z"
        fill={outline}
      />
      <path
        d="M92.29,45.75c-3.23-.6-5.5,1-7.4,3.38A35.28,35.28,0,0,0,51.53,26.19C34.93,26.19,21,37.3,17.22,52.26,14.55,48.45,11.68,45,7.13,46.14c-7.19,1.81-4,36.68,17.12,36A36.07,36.07,0,0,0,78.13,83l0,0C99.63,82.71,99.63,47.11,92.29,45.75Z"
        fill={c2}
      />
      <path
        d="M51.53,26.19A35.28,35.28,0,0,1,84.89,49.13c1.63-2,3.53-3.5,6.07-3.5a7.73,7.73,0,0,1,1.33.12c7.34,1.36,7.34,37-14.13,37.23h0l0,0a36.07,36.07,0,0,1-53.88-.78h-.57C3.1,82.19,0,47.93,7.13,46.14a7.06,7.06,0,0,1,1.72-.22c3.58,0,6.05,3,8.37,6.34C21,37.3,34.93,26.19,51.53,26.19m0-2.48a38.88,38.88,0,0,0-12.64,2.1,38,38,0,0,0-10.84,5.84,37.17,37.17,0,0,0-8.27,8.85,35.61,35.61,0,0,0-3.48,6.6A15.54,15.54,0,0,0,15.13,46a9.49,9.49,0,0,0-6.28-2.53,9.49,9.49,0,0,0-2.33.3c-1.62.4-3.76,1.81-4.78,6.2a28.81,28.81,0,0,0-.28,9.76,39.38,39.38,0,0,0,2,8.43,31.65,31.65,0,0,0,4.15,8c4,5.44,9.33,8.39,15.52,8.55a38.68,38.68,0,0,0,44.27,9.1,37.65,37.65,0,0,0,6.79-3.94,37.05,37.05,0,0,0,5-4.4,18.56,18.56,0,0,0,14.45-8.29A33.25,33.25,0,0,0,99,61.07,32.41,32.41,0,0,0,98.24,50c-1.36-4.93-3.74-6.35-5.5-6.68A9.9,9.9,0,0,0,91,43.15a8.78,8.78,0,0,0-5.19,1.7,36.19,36.19,0,0,0-4-6.45,37.26,37.26,0,0,0-8.17-7.78,38.23,38.23,0,0,0-10.28-5.09,38.83,38.83,0,0,0-11.8-1.82Z"
        fill={outline}
      />
      <path
        d="M65,28.78C62.21,22.53,51,8.65,42,6.36c3.07,6.37-.84,15.62-.84,21.33C26.72,32,16.21,45,16.21,60.43a33,33,0,0,0,.79,7.2,165.25,165.25,0,0,1,13.34-27s4.35,4.89,6,4.89c.27-3,.81-8.15.81-8.15S48,49.56,50.45,49.56,63.76,37.33,63.76,37.33s.54,5.16.81,8.15c1.64,0,6-4.89,6-4.89s9.86,15.73,14.54,30.55a33.36,33.36,0,0,0,1.77-10.71A34.27,34.27,0,0,0,65,28.78Z"
        fill={c0}
      />
      <path
        d="M92.29,45.75c7.34,1.36,7.34,37-14.13,37.23-.57,0-3-20.72,0-23.91C82.59,54.37,85,44.39,92.29,45.75Z"
        fill={c2}
      />
      <path
        d="M7.13,46.14c-7.24,1.82-3.94,37.1,17.5,36,.57,0,.64-20.62-2.56-23.62C17.35,54.13,14.37,44.32,7.13,46.14Z"
        fill={c2}
      />
      <path
        d="M7.79,51.73c-2.72,11.41,8.37,28.46,13.5,25.2S10.5,40.32,7.79,51.73Z"
        fill={c3}
      />
      <path
        d="M92.79,52.55C95.51,64,84.41,81,79.29,77.74S90.07,41.13,92.79,52.55Z"
        fill={c3}
      />
      <path d="M63.46,51.41a6,6,0,0,0-2.23,3.13" fill={c2} />
      <path
        d="M61.23,54.94l-.12,0a.41.41,0,0,1-.26-.5,6.47,6.47,0,0,1,2.25-3.24l.1-.08a.41.41,0,0,1,.56,0,.4.4,0,0,1,0,.56l-.12.1a5.49,5.49,0,0,0-2,2.85A.4.4,0,0,1,61.23,54.94Z"
        fill={outline}
      />
      <ellipse
        cx="32.81"
        cy="65.55"
        fill={c3}
        rx="7.34"
        ry="15.1"
        transform="translate(-10.43 6.36) rotate(-9.56)"
      />
      <path
        d="M40.05,64.33c0-.08,0-.16,0-.24-1.72-5.19-4.8-8.65-7.66-8.17-3.54.6-5.42,7-4.19,14.28a22,22,0,0,0,3.5,9.09,4.68,4.68,0,0,0,3.66,1.15C39.31,79.77,41.43,72.55,40.05,64.33Z"
        fill={"#fff"}
      />
      <path
        d="M40.41,71.05c0-.5-.1-1-.19-1.51-.8-4.75-3.32-8.28-5.62-7.89S31.07,66.2,31.86,71s3.32,8.28,5.63,7.89a2,2,0,0,0,.54-.17A14.29,14.29,0,0,0,40.41,71.05Z"
        fill={outline}
      />
      <circle cx="35.23" cy="66.13" fill={"#fff"} r="2.17" />
      <ellipse
        cx="67.86"
        cy="65.55"
        fill={c3}
        rx="15.1"
        ry="7.34"
        transform="translate(-8.05 121.58) rotate(-80.44)"
      />
      <path
        d="M60.62,64.33c0-.08,0-.16,0-.24,1.72-5.19,4.8-8.65,7.66-8.17,3.55.6,5.42,7,4.2,14.28A22.17,22.17,0,0,1,69,79.29a4.66,4.66,0,0,1-3.66,1.15C61.35,79.77,59.23,72.55,60.62,64.33Z"
        fill={"#fff"}
      />
      <path
        d="M60.25,71.05c0-.5.11-1,.19-1.51.8-4.75,3.32-8.28,5.63-7.89S69.6,66.2,68.8,71s-3.32,8.28-5.63,7.89a2,2,0,0,1-.54-.17A14.29,14.29,0,0,1,60.25,71.05Z"
        fill={outline}
      />
      <circle cx="65.43" cy="66.13" fill={"#fff"} r="2.17" />
      <path
        d="M50.45,92.17a22.18,22.18,0,0,1-6-.83,18.85,18.85,0,0,1-7.28-3.75.4.4,0,1,1,.54-.58h0a18.52,18.52,0,0,0,7,3.58c4.09,1.14,10.69,1.59,18.9-2.81a.39.39,0,0,1,.54.16.41.41,0,0,1-.16.54A30.08,30.08,0,0,1,52.86,92,21.75,21.75,0,0,1,50.45,92.17Z"
        fill={outline}
      />
      <path
        d="M48,86.91a.39.39,0,0,1-.27-.11l-1.32-1.25A.4.4,0,0,1,47,85l1.32,1.25a.4.4,0,0,1,0,.56A.37.37,0,0,1,48,86.91Z"
        fill={outline}
      />
      <path
        d="M51.53,86.91a.39.39,0,0,1-.29-.13.4.4,0,0,1,0-.56L52.58,85a.4.4,0,0,1,.54.58L51.8,86.8A.39.39,0,0,1,51.53,86.91Z"
        fill={outline}
      />
      <path d="M37.48,51.41a6,6,0,0,1,2.23,3.13" fill={c2} />
      <path
        d="M39.71,54.94a.4.4,0,0,1-.38-.28,5.49,5.49,0,0,0-2-2.85l-.12-.1a.4.4,0,0,1,0-.56.41.41,0,0,1,.56,0,.44.44,0,0,0,.1.08,6.41,6.41,0,0,1,2.24,3.24.39.39,0,0,1-.25.5Z"
        fill={outline}
      />
    </SVG>
  );
};
