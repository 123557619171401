export const HoursMinutes = Intl.DateTimeFormat("en-GB", {
  hour: "2-digit",
  minute: "2-digit"
});

export const MinutesSeconds = Intl.DateTimeFormat("en-GB", {
  minute: "2-digit",
  second: "2-digit"
});

export const raidTimer = unixMs => {
  let hours = Math.floor(unixMs / 3600000);
  let minutes = Math.floor((unixMs % 3600000) / 60000);
  let seconds = Math.floor((unixMs % 60000) / 1000);
  return `${hours ? `${hours}:` : ""}${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;
};
