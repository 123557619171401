import React from "react";
import StyledSVG from "../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 101 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.6439 0.728149L39.0293 22.6908L12.4964 12.5794L22.6037 39.1164L0.645164 50.7311L12.8104 57.1657C16.4449 48.908 24.6995 43.1425 34.3006 43.1425C38.9248 43.1425 43.2366 44.4799 46.8704 46.7891C45.1508 43.526 44.1776 39.8085 44.1776 35.8638C44.1776 26.3697 49.8152 18.1925 57.9254 14.4969L50.6439 0.728149ZM55.076 55.6855C56.7956 58.9486 57.7688 62.666 57.7688 66.6108C57.7688 76.1431 52.0856 84.348 43.9227 88.0221L50.6439 100.728L62.2586 78.7714L88.7939 88.8877L78.6843 62.3457L100.645 50.7311L89.3667 44.7662C85.8601 53.3128 77.4559 59.332 67.6458 59.332C63.0216 59.332 58.7098 57.9946 55.076 55.6855Z"
      fill="currentcolor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M86.9501 37.7972C85.9798 47.6035 77.7073 55.2632 67.6458 55.2632C57.5844 55.2632 49.3119 47.6035 48.3416 37.7972H58.1389C59.0351 42.2279 62.9509 45.5635 67.6458 45.5635C72.3408 45.5635 76.2566 42.2279 77.1528 37.7972H86.9501ZM86.9501 33.9304H77.1528C76.2566 29.4997 72.3408 26.1641 67.6458 26.1641C62.9509 26.1641 59.0351 29.4997 58.1389 33.9304H48.3416C49.3119 24.1241 57.5844 16.4644 67.6458 16.4644C77.7073 16.4644 85.9798 24.1241 86.9501 33.9304Z"
      fill="currentcolor"
    />
    <circle cx="67.6458" cy="35.8638" r="5.81982" fill="currentcolor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M53.6048 68.5442C52.6345 78.3504 44.3621 86.0101 34.3006 86.0101C24.2391 86.0101 15.9666 78.3504 14.9963 68.5442H24.7936C25.6898 72.9748 29.6056 76.3104 34.3006 76.3104C38.9955 76.3104 42.9114 72.9748 43.8076 68.5442H53.6048ZM53.6048 64.6773H43.8076C42.9114 60.2467 38.9955 56.911 34.3006 56.911C29.6057 56.911 25.6898 60.2467 24.7936 64.6773H14.9963C15.9666 54.871 24.2391 47.2113 34.3006 47.2113C44.3621 47.2113 52.6345 54.871 53.6048 64.6773Z"
      fill="currentcolor"
    />
    <circle cx="34.3006" cy="66.6107" r="5.81982" fill="currentcolor" />
  </StyledSVG>
);
