import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c0 = shiny ? "#bfbec5" : "#467295";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Beldum Portrait"
      id="Beldum_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Beldum</title>
      <polygon
        fill={c0}
        points="32.63 2.24 71.55 2.24 71.55 23.78 32.56 20.7 32.63 2.24"
      />
      <path
        d="M72.8,25.14,31.31,21.85,31.39,1H72.8Zm-39-5.6L70.3,22.43V3.49H33.88Z"
        fill={outline}
      />
      <polygon
        fill={c0}
        points="9.41 31.09 8.53 72.15 34.58 96.43 67.81 96.48 92.95 75.19 92.95 32.9 71.52 12.25 28.11 12.7 9.41 31.09"
      />
      <path
        d="M71.52,12.25,93,32.89v42.3L67.82,96.48l-33.24,0-26-24.28.88-41.06L28.1,12.69l43.42-.44m1-2.51h-1l-43.41.44h-1l-.72.71L7.66,29.31,6.93,30l0,1L6,72.1l0,1.12.82.76L32.87,98.27l.72.67h1l33.23,0h.92l.7-.59L94.57,77.1l.88-.75V31.83l-.76-.74L73.26,10.45l-.74-.71Z"
        fill={outline}
      />
      <rect
        fill={outline}
        height="25.05"
        transform="translate(-23.2 28.82) rotate(-50.35)"
        width="0.8"
        x="18.66"
        y="26.56"
      />
      <rect
        fill={outline}
        height="0.8"
        transform="translate(-20.34 9.36) rotate(-18.06)"
        width="22.6"
        x="7.97"
        y="68.25"
      />
      <rect
        fill={outline}
        height="0.8"
        transform="translate(-57.17 85.62) rotate(-64.97)"
        width="19.27"
        x="29.02"
        y="87.3"
      />
      <rect
        fill={outline}
        height="19.7"
        transform="translate(-25.49 26.44) rotate(-19.46)"
        width="0.8"
        x="63.98"
        y="77.7"
      />
      <rect
        fill={outline}
        height="23.2"
        transform="translate(-19.29 103.94) rotate(-58.52)"
        width="0.8"
        x="82.71"
        y="57.58"
      />
      <rect
        fill={outline}
        height="0.8"
        transform="translate(-5.76 66.96) rotate(-42.1)"
        width="23.94"
        x="72.15"
        y="40.57"
      />
      <rect
        fill={outline}
        height="0.8"
        transform="translate(16.43 71.66) rotate(-63.28)"
        width="22.94"
        x="54.89"
        y="22.1"
      />
      <rect
        fill={outline}
        height="24.66"
        transform="translate(-6.88 23.11) rotate(-33.53)"
        width="0.8"
        x="34.51"
        y="10.64"
      />
      <circle cx="74.61" cy="70.43" fill={"#16001a"} r="8.24" />
      <polygon
        fill={"#16001a"}
        points="23.84 43.01 23.84 67.15 41.2 82.31 62.1 81.72 78.88 66.56 78.29 46.25 62.69 29.77 39.44 29.77 23.84 43.01"
      />
      <circle cx="51.87" cy="55.45" fill={"#9a0530"} r="12.44" />
      <circle cx="56.51" cy="49.05" fill={"#fff"} r="3.24" />
    </SVG>
  );
};
