import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c3 = shiny ? "#64a973" : "#007baa";
  const c0 = shiny ? "#b5d498" : "#59c5de";
  const c2 = shiny ? "#212737" : "#192a3b";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Ivysaur Portrait"
      id="Ivysaur_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Ivysaur</title>
      <path
        d="M96.87,51.84a31.33,31.33,0,0,0-6.73-17.58c2.22-9.41,4.13-19.3,2.7-20.66-1.6-1.52-13.69,2-23.88,5.3a50.68,50.68,0,0,0-18.71-4,49.46,49.46,0,0,0-16.82,3.29C22.74,14.59,8,10.07,6.22,11.76c-1.5,1.43,1.49,12.14,4.62,21.88a31.61,31.61,0,0,0-7.21,18.2c-1.38,24.78,8.26,33.5,44.27,36h4.7C88.61,85.34,98.25,76.62,96.87,51.84Z"
        fill={c0}
      />
      <path
        d="M7.82,11.4c4.48,0,16.52,3.72,25.61,6.76A49.63,49.63,0,0,1,50,14.87h.5A50.92,50.92,0,0,1,69,18.9c8.5-2.77,18.32-5.67,22.27-5.67a2.49,2.49,0,0,1,1.61.37c1.43,1.36-.48,11.25-2.7,20.66a31.33,31.33,0,0,1,6.73,17.58c1.38,24.78-8.26,33.5-44.27,36H47.9c-36-2.52-45.65-11.24-44.27-36a31.61,31.61,0,0,1,7.21-18.2C7.71,23.9,4.72,13.19,6.22,11.76a2.47,2.47,0,0,1,1.6-.36m0-2.51h0A4.72,4.72,0,0,0,4.49,10C3,11.4,2.93,14.07,4.35,20.15c.79,3.37,2,7.74,3.7,13A33.68,33.68,0,0,0,1.13,51.71C.75,58.43,1.2,63.85,2.5,68.27a23.4,23.4,0,0,0,7.64,11.86c3.64,3,8.49,5.28,14.82,7a126.73,126.73,0,0,0,22.77,3.28h5a126.73,126.73,0,0,0,22.77-3.28c6.33-1.67,11.18-3.94,14.82-7A23.4,23.4,0,0,0,98,68.27c1.3-4.42,1.75-9.84,1.37-16.56a33.62,33.62,0,0,0-6.52-18c1.17-5,2-9.21,2.49-12.41a40.34,40.34,0,0,0,.5-5.18c0-1.52-.13-3.22-1.28-4.31a4.69,4.69,0,0,0-3.33-1.06c-2,0-5.33.61-10.07,1.88-3.36.89-7.43,2.11-12.11,3.62A53,53,0,0,0,50.5,12.37H50a51.75,51.75,0,0,0-16.6,3.14c-5.31-1.76-10-3.2-13.83-4.28C13.93,9.66,10.09,8.89,7.82,8.89Z"
        fill={outline}
      />
      <path
        d="M17.39,27.13,5.87,14.38c.34,4.06,2.61,11.93,5,19.26A43.36,43.36,0,0,1,17.39,27.13Z"
        fill={c2}
      />
      <path
        d="M90.14,34.26c1.74-7.37,3.28-15,3.19-18.66L82.81,26.89A42.6,42.6,0,0,1,90.14,34.26Z"
        fill={c2}
      />
      <path
        d="M38.73,29.59c0,4.13,10.55,15.6,13.76,15.14s10.1-16.51,6.88-18.81S38.73,25.46,38.73,29.59Z"
        fill={c3}
      />
      <path
        d="M34.14,47.49c.46-2.76,6.42-4.13,7.8-2.76s0,10.56-1.38,11S33.68,50.24,34.14,47.49Z"
        fill={c3}
      />
      <path
        d="M30,52.93a.4.4,0,0,1-.4-.4,12.13,12.13,0,0,0-3.1-8,.41.41,0,0,1,0-.57.39.39,0,0,1,.56,0,12.79,12.79,0,0,1,3.33,8.54A.4.4,0,0,1,30,52.93Z"
        fill={outline}
      />
      <path
        d="M65.8,53.85a.4.4,0,0,1-.4-.4c0-4.2,2.7-8.31,2.82-8.48a.4.4,0,0,1,.55-.11.38.38,0,0,1,.11.55s-2.68,4.08-2.68,8A.4.4,0,0,1,65.8,53.85Z"
        fill={outline}
      />
      <path
        d="M79.14,69.9s.11,5.46,1.34,6.08,5.1-7.11,5.1-7.11"
        fill={"#fff"}
      />
      <path
        d="M17.67,69.59s-.1,5.46-1.34,6.08-5.09-7.11-5.09-7.11"
        fill={"#fff"}
      />
      <path
        d="M26.8,60.79c1.38-1.83-5.51-21.1-9.64-21.1S8.9,55.74,10.28,57.12,25.42,62.63,26.8,60.79Z"
        fill={"#fff"}
      />
      <path
        d="M14,54.14a14,14,0,0,0,.91,5c4.59,1.49,11,2.93,11.94,1.7,1.05-1.4-2.7-12.93-6.38-18.33A13.62,13.62,0,0,0,14,54.14Z"
        fill={"#d1334c"}
      />
      <path
        d="M20.4,51.74c-.4,2.76.1,5.12,1.12,5.27s2.16-2,2.56-4.73A12.69,12.69,0,0,0,24.21,50c-.4-1-.83-2-1.27-2.93C21.93,46.91,20.8,49,20.4,51.74Z"
        fill={outline}
      />
      <path
        d="M69.86,61.34c-1.3-1.89,6.41-20.85,10.54-20.67S88,57.07,86.52,58.39,71.15,63.23,69.86,61.34Z"
        fill={"#fff"}
      />
      <path
        d="M83,55.25a13.89,13.89,0,0,1-1.12,4.9c-4.65,1.3-11.14,2.45-12,1.19-1-1.44,3.26-12.8,7.16-18A13.64,13.64,0,0,1,83,55.25Z"
        fill={"#d1334c"}
      />
      <path
        d="M76.64,52.58c.28,2.77-.32,5.1-1.34,5.21S73.22,55.73,72.94,53a12.2,12.2,0,0,1,0-2.33c.44-1,.91-1.94,1.4-2.87C75.32,47.68,76.36,49.83,76.64,52.58Z"
        fill={outline}
      />
      <path
        d="M44.3,72.66a.41.41,0,0,1-.28-.11l-2.3-2.3a.4.4,0,1,1,.57-.57L44.58,72a.4.4,0,0,1,0,.57A.43.43,0,0,1,44.3,72.66Z"
        fill={outline}
      />
      <path
        d="M53,72.66a.44.44,0,0,1-.29-.11.42.42,0,0,1,0-.57l2.3-2.3a.4.4,0,0,1,.56,0,.4.4,0,0,1,0,.57l-2.29,2.3A.43.43,0,0,1,53,72.66Z"
        fill={outline}
      />
      <path
        d="M90,65.44a.41.41,0,0,0-.49.31c-.17.78-1.38,1.92-6.14,2.93-3.42.72-7.6,1.16-11,1.52a46.24,46.24,0,0,0-5.55.75h0C60.35,73.34,50.32,76.9,49,76.9h-.1c-1.29,0-11.32-3.56-17.75-5.94h0a46.24,46.24,0,0,0-5.55-.75c-3.36-.36-7.53-.8-11-1.52-4.77-1-6-2.15-6.15-2.93a.41.41,0,1,0-.8.18c.67,3,8.81,4.14,17.81,5.09a47.12,47.12,0,0,1,5.4.72c1.73.64,16.17,6,18,6H49c1.85,0,16.3-5.34,18-6a47.46,47.46,0,0,1,5.4-.72c9-1,17.14-2.06,17.81-5.09A.41.41,0,0,0,90,65.44Z"
        fill={outline}
      />
    </SVG>
  );
};
