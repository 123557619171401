import React from "react";

export default ({ screen, ...props }) => (
  <svg
    {...props}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 160 240"
  >
    <polygon
      points="150,80 160,80 160,30 150,30 150,20 140,20 140,10 130,10 130,0 30,0 30,10 20,10 20,20 10,20 10,30 0,30 0,80 10,80 10,30 20,30 20,20 30,20 30,10 130,10 130,20 140,20 140, 30 150,30"
      fill="#464759"
    />
    <rect x="10" y="80" height="140" width="140" fill="#C8C8C0" />
    <rect x="20" y="190" height="40" width="120" fill="#F8F8F8" />
    <rect x="90" y="220" height="10" width="10" fill="#8A8A8A" />
    <rect x="90" y="200" height="10" width="10" fill="#8A8A8A" />
    <rect x="70" y="220" height="10" width="10" fill="#8A8A8A" />
    <rect x="70" y="200" height="10" width="10" fill="#8A8A8A" />
    <rect x="50" y="220" height="10" width="10" fill="#8A8A8A" />
    <rect x="50" y="200" height="10" width="10" fill="#8A8A8A" />
    <rect x="30" y="220" height="10" width="10" fill="#8A8A8A" />
    <rect x="30" y="200" height="10" width="10" fill="#8A8A8A" />
    <rect x="110" y="210" height="20" width="20" fill="#8A8A8A" />
    <polygon
      points="0,80 0,240 160,240 160,80 150,80 150,170 140,170 140,180 150,180 150,220 140,220 140,230 20,230 20,220 10,220 10,180 20,180 20,170 10,170 10,80"
      fill="#606078"
    />
    <polygon
      points="130,160 140,160 140,100 130,100 130,90 30,90 30,100 20,100 20,160 30,160 30,100 130,100"
      fill="#606078"
    />
    <polygon points="140,190 140,180 20,180 20,190" fill="#606078" />
    <rect x="110" y="200" height="10" width="20" fill="#606078" />
    <polygon
      points="150,80 150,30 140,30 140,20 130,20 130,10 30,10 30,20 20,20 20,30 10,30 10,80"
      fill="#C7B7BF"
    />
    <polygon
      points="140,30 140,20 120,20 120,10 110,10 110,20 50,20 50,10 40,10 40,20 20,20 20,30"
      fill="#9090A0"
    />
    <polygon
      points="150,70 150,40 10,40 10,70 20,70 20,80 140,80, 140,70"
      fill="#F7F7F7"
    />
    {screen || <rect x="30" y="100" height="60" width="100" fill="#78b0b8" />}
    <rect x="40" y="110" height="30" width="10" fill="#C8C8C0" />
    <rect x="40" y="120" height="10" width="10" fill="#F8F8F8" />
    <polygon
      points="130,170 130,160 120,160 120,150 130,150 130,140 120,140 120,150 40,150 40,140 30,140 30,150 40,150 40,160 30,160 30,170 40,170 40,160 120,160 120,170"
      fill="#909090"
    />
    <rect x="40" y="160" height="10" width="80" fill="#E0E0D0" />
  </svg>
);
