import React from "react";
import styled from "styled-components";
import { height } from "styled-system";
import { rawWidth, selectedSpace, selectedBg } from "../utility/styling";
import { colour, space } from "../theme";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  ${height}
  ${rawWidth("100%")}
  ${selectedSpace({ selectedMb: space("smaller") })}
  ${selectedBg(colour("medium"))}
`;

const Bar = styled.div`
  height: 100%;
  box-sizing: border-box;
  ${rawWidth("50%")}
  ${selectedBg(colour("light"))}
`;

export default props => (
  <Wrapper {...props}>
    <Bar width={props.percent} bg={props.fg}>
      {props.children}
    </Bar>
    {props.outerChildren}
  </Wrapper>
);
