import React from "react";
import styled from "styled-components";

import { selectedFontSize } from "../utility/styling";
import { fontSize } from "../theme";

export default styled.p`
  margin: 0;
  ${fontSize("text")}
`;
