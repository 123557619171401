import React from "react";
import Popup from "../Popup";
import { injectIntl } from "react-intl";
import Text from "../Text";

const messages = {
  help: {
    id: "help",
    defaultMessage: "Help"
  },
  text: {
    id: "help.raid.text",
    defaultMessage:
      "Search the map for raids or gyms. You can report or join a raid by clicking on them."
  }
};

export default injectIntl(({ showing, dismiss, intl }) => (
  <Popup
    title={intl.formatMessage(messages.help)}
    showing={showing}
    dismiss={dismiss}
  >
    <Text>{intl.formatMessage(messages.text)}</Text>
  </Popup>
));
