import React from "react";
import styled from "styled-components";
import name from "../../utility/name";
import { fontSize } from "../../theme";

const Image = styled.img`
  width: 100%;
  text-align: center;
  ${fontSize("text")}
`;

export default ({ number, form, className }) => {
  return (
    <Image
      src={`https://pokeboxadvance.net/assets/portrait/${number}.svg`}
      alt={`${name(number, form)}`}
      className={className}
    />
  );
};
