import React, { useState } from "react";
import styled from "styled-components";

import Header from "../component/Header";
import Input from "../component/Input";
import Loading from "../component/Loading";
import Move from "../component/Move";
import Page from "../component/Page";
import PercentageBar from "../component/PercentageBar";
import Section from "../component/Section";
import _Text from "../component/Text";
import Title from "../component/Title";
import AddPokemon from "../component/box/Add";
import {
  selectedBorderRadius,
  selectedSpace,
  selectedTop,
  selectedRight
} from "../utility/styling";
import { borderRadius, fontSize, space } from "../theme";

const Pokemon = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  ${selectedSpace({ selectedM: space("large") })}
  ${selectedBorderRadius(borderRadius("main"))}
  ${fontSize("text")}
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Text = styled(_Text)`
  ${selectedSpace({ selectedPy: space("tinier"), selectedPx: space("tiny") })}
`;

const Sum = styled.div`
  position: absolute;
  ${selectedTop(space("tinier"))}
  ${selectedRight(space("tiny"))}
`;

export default ({
  box,
  pushBox,
  pokemonData,
  pokemonNameNumberMap,
  location
}) => {
  const [adding, setAdding] = useState(false);
  const dismiss = () => setAdding(false);
  const queryString = location.search.substring(1);
  let query = {};
  for (let field of queryString.split("&")) {
    let fieldSplit = field.split("=");
    query[fieldSplit[0]] = fieldSplit[1];
  }
  return (
    <Page title={"Box"}>
      <Header>
        <Title>Box</Title>
        <Input
          type={"button"}
          value={adding ? "x" : "Add a Pokémon"}
          width={"auto"}
          onClick={() => setAdding(!adding)}
        />
      </Header>
      {adding && (
        <AddPokemon
          pushBox={pushBox}
          dismiss={dismiss}
          query={query}
          pokemonData={pokemonData}
          pokemonNameNumberMap={pokemonNameNumberMap}
        />
      )}
      {box ? (
        <Content>
          {box.map((pokemon, key) => (
            <Pokemon key={key}>
              <Section>
                {pokemon.nickname ||
                  pokemon.pokemon.name ||
                  "#" + pokemon.pokemon.number}
              </Section>
              <PercentageBar
                percent={pokemon.iv.stamina / 15}
                outerChildren={
                  <Sum>{pokemon.pokemon.baseStamina + pokemon.iv.stamina}</Sum>
                }
              >
                <Text>
                  {pokemon.pokemon.baseStamina}&nbsp;+&nbsp;{pokemon.iv.stamina}
                </Text>
              </PercentageBar>
              <PercentageBar
                percent={pokemon.iv.attack / 15}
                outerChildren={
                  <Sum>{pokemon.pokemon.baseAttack + pokemon.iv.attack}</Sum>
                }
              >
                <Text>
                  {pokemon.pokemon.baseAttack}&nbsp;+&nbsp;{pokemon.iv.attack}
                </Text>
              </PercentageBar>
              <PercentageBar
                percent={pokemon.iv.defense / 15}
                outerChildren={
                  <Sum>{pokemon.pokemon.baseDefense + pokemon.iv.defense}</Sum>
                }
              >
                <Text>
                  {pokemon.pokemon.baseDefense}&nbsp;+&nbsp;{pokemon.iv.defense}
                </Text>
              </PercentageBar>
              {pokemon.fast.map((move, key) => (
                <Move
                  name={move.name}
                  power={move.power}
                  time={move.time}
                  key={key}
                />
              ))}
              {pokemon.charge.map((move, key) => (
                <Move
                  name={move.name}
                  power={move.power}
                  time={move.time}
                  key={key}
                />
              ))}
              <div>{pokemon.location}</div>
            </Pokemon>
          ))}
        </Content>
      ) : (
        <Loading />
      )}
    </Page>
  );
};
