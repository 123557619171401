import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.colour.light }) => {
  const c1 = shiny ? "#f4da58" : "#a5b4c8";
  const c2 = shiny ? "#bfbec5" : "#467295";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Metagross Portrait"
      id="Metagross_Portrait"
      viewBox="0 0 100 52"
    >
      <title>{shiny && "Shiny "}Metagross</title>
      <rect
        fill={outline}
        height="0.8"
        transform="translate(-3.05 24.35) rotate(-23.2)"
        width="2.35"
        x="56.61"
        y="19.21"
      />
      <rect
        fill={outline}
        height="5.88"
        transform="translate(5.08 51.11) rotate(-47.97)"
        width="0.8"
        x="59.57"
        y="16.91"
      />
      <polygon
        fill={c1}
        points="80.05 32.33 87.53 46.19 93.47 32.33 83.79 24.19 80.05 32.33"
      />
      <path
        d="M83.79,24.19l9.68,8.14L87.53,46.19,80.05,32.33l3.74-8.14m-.54-2.4-.82,1.78-3.74,8.14-.31.67.36.66L86.21,46.9l1.47,2.72,1.22-2.84,5.94-13.86.44-1-.85-.71-9.68-8.14-1.5-1.26Z"
        fill={outline}
      />
      <polygon
        fill={c1}
        points="21.99 32.33 14.51 46.19 8.57 32.33 18.25 24.19 21.99 32.33"
      />
      <path
        d="M18.25,24.19,22,32.33,14.51,46.19,8.57,32.33l9.68-8.14m.54-2.4-1.5,1.26L7.61,31.19l-.85.71.44,1,5.94,13.86,1.22,2.84,1.46-2.72L23.3,33l.36-.66-.31-.67-3.74-8.14-.82-1.78Z"
        fill={outline}
      />
      <path
        d="M97.13,24.3l-3.95-.42C88.63,18.73,75.25,3.88,71.61,3c-3,0-20-.24-21.55-.22S32.12,3,29.17,3c-3.64.91-17,15.76-21.57,20.91l-3.95.42-.88,3.3,2,7.92,12.47.64L28.07,49.38H72.71L83.56,36.16,96,35.52l2-7.92Z"
        fill={c2}
      />
      <path
        d="M73.07,50.12H27.71L16.85,36.89,4.16,36.24,2,27.6l1.06-4,4.17-.44c2.34-2.64,6.71-7.5,11-11.86C26.43,2.88,28.35,2.4,29,2.24l.09,0h.1c1.47,0,6.29-.06,11-.12S49.25,2,50.06,2s5.43,0,10.32.11,9.77.11,11.23.11h.1l.09,0c.63.16,2.55.64,10.79,9.07,4.25,4.36,8.62,9.22,11,11.86l4.17.44,1.06,4-2.16,8.64-12.69.65ZM28.42,48.63H72.36l10.83-13.2,12.25-.63,1.8-7.19L96.54,25l-3.73-.4-.19-.22C82,12.33,73.91,4.45,71.51,3.72L60.37,3.6c-4.9-.06-9.53-.11-10.3-.1h0c-.77,0-5.21,0-9.91.1l-10.87.12c-2.4.73-10.46,8.61-21.11,20.65L8,24.59,4.24,25l-.7,2.62,1.8,7.19,12.25.63Z"
        fill={outline}
      />
      <line fill={c2} x1="31.38" x2="28.9" y1="22.18" y2="24.3" />
      <rect
        fill={outline}
        height="0.8"
        transform="translate(-7.87 25.2) rotate(-40.58)"
        width="3.27"
        x="28.51"
        y="22.84"
      />
      <rect
        fill={outline}
        height="0.8"
        transform="translate(7.29 32.67) rotate(-49.29)"
        width="14.72"
        x="31.88"
        y="7.99"
      />
      <path
        d="M35.77,28.44,2.25,28l1.1-4.09H97.43l1.1,4.1ZM3.29,27.21l32.48.43,61.72-.43-.67-2.51H4Z"
        fill={outline}
      />
      <rect fill={outline} height="0.8" width="66.2" x="17.29" y="36.66" />
      <rect
        fill={outline}
        height="9.93"
        transform="translate(10.97 45.42) rotate(-39.12)"
        width="0.8"
        x="69"
        y="2.31"
      />
      <rect
        fill={outline}
        height="0.8"
        transform="translate(-0.35 9.02) rotate(-7.87)"
        width="6.95"
        x="61.92"
        y="6.66"
      />
      <polygon
        fill={outline}
        points="62.2 15.1 61.55 7.77 56.74 3.03 57.3 2.46 62.32 7.41 62.99 15.03 62.2 15.1"
      />
      <line fill={c2} x1="70.53" x2="74.58" y1="22.18" y2="24.3" />
      <rect
        fill={outline}
        height="4.58"
        transform="translate(18.29 76.72) rotate(-62.34)"
        width="0.8"
        x="72.16"
        y="20.95"
      />
      <line fill={outline} x1="58.25" x2="62.15" y1="24.18" y2="21.51" />
      <rect
        fill={outline}
        height="0.8"
        transform="translate(-2.38 37.99) rotate(-34.38)"
        width="4.73"
        x="57.84"
        y="22.45"
      />
      <line fill={outline} x1="42.63" x2="38.32" y1="23.56" y2="21.51" />
      <rect
        fill={outline}
        height="4.78"
        transform="translate(2.73 49.39) rotate(-64.54)"
        width="0.8"
        x="40.08"
        y="20.15"
      />
      <path
        d="M35.3,24.68a5.58,5.58,0,0,0,5.34-3.59c-2.08-1.25-5.85-3.5-9.14-5.37a5,5,0,0,0-1.81,3.79A5.4,5.4,0,0,0,35.3,24.68Z"
        fill={outline}
      />
      <path
        d="M65.66,24.29c3.74,0,6.77-2.27,6.77-5.06a4.64,4.64,0,0,0-2.29-3.79c-4.5,2.14-8.92,4.63-10.76,5.69C60.38,23,62.82,24.29,65.66,24.29Z"
        fill={outline}
      />
      <path
        d="M35.52,23.58a3.5,3.5,0,0,0,3.63-3.35c0-.12,0-.23,0-.35C37.84,19,36.25,18,34.63,17a3.4,3.4,0,0,0-2.74,3.24A3.5,3.5,0,0,0,35.52,23.58Z"
        fill={"#9a0530"}
      />
      <path
        d="M36.62,21.82a1.65,1.65,0,0,0,1.65-1.65,1.78,1.78,0,0,0-.1-.56c-.57-.34-1.17-.7-1.8-1.06a1.64,1.64,0,0,0,.25,3.27Z"
        fill={outline}
      />
      <path
        d="M62.73,19.89a3.35,3.35,0,0,0,6.67,0A3.13,3.13,0,0,0,67.07,17c-1.54.79-3,1.57-4.26,2.25A3.1,3.1,0,0,0,62.73,19.89Z"
        fill={"#9a0530"}
      />
      <path
        d="M63.94,19.34a1.72,1.72,0,1,0,3.43,0A1.83,1.83,0,0,0,66,17.51l-1.88,1A2.15,2.15,0,0,0,63.94,19.34Z"
        fill={outline}
      />
      <path
        d="M78.22,39.14C75.16,35.08,57.54,23,57.54,23s11.51-6.25,19.35-8.79c-4.62-5.5-2-2.42-4.62-5.5-5.18,1.42-21,9.53-22,10.11-1-.61-14.48-9-19.62-10.55a48.18,48.18,0,0,0-5.45,5.06c2.2.66,16.71,9.45,16.71,9.45S25.33,34.26,22.14,38.22c1.31,1.36,3.94,3.18,6.15,5,2.63-2.34,20.9-15.35,22.08-16.1,1.14.79,19.13,14.12,21.68,16.54C74.31,41.91,76.86,40.46,78.22,39.14Z"
        fill={c1}
      />
      <circle cx="33.49" cy="19.66" fill={"#fff"} r="0.62" />
      <circle cx="68.23" cy="19.23" fill={"#fff"} r="0.6" />
    </SVG>
  );
};
