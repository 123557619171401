import React from "react";

import Flex from "../utility/Flex";
import RaidTimer from "./RaidTimer";
import Star from "../../assets/raid/Star";

import { tier as _tier } from "../../assets/raid";
import { portrait } from "../../assets/portrait";
import styled from "styled-components";
import { selectedColour, selectedFontSize } from "../../utility/styling";
import { colour, fontSize, headingSize, type } from "../../theme";
import Portrait from "../../assets/portrait/Portrait";

const Container = styled(Flex)`
  position: relative;
  flex-direction: column;
  justify-content: end;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 45px;
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 8px;
  padding: 0 8px;
  margin-right: 16px;
  background: ${({ ex }) => (ex ? colour("main") : "white")};
  ${({ ex }) => ex && `color: white;`}
`;

const ParticipantsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -8px;
`;

const Timer = styled(RaidTimer)`
  font-weight: bold;
`;

const Bubble = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: ${({ ex }) => (ex ? colour("main") : "white")};
`;

const Participants = styled.div`
  position: absolute;
  font-weight: normal;
  ${fontSize("heading.small")}
  ${selectedColour(type("dragon"))}
`;

const StarContainer = styled(Flex)`
  justify-content: center;
  margin-top: 2px;
  max-width: 50px;
`;

const FlexStar = styled(Star)`
  flex: 0 0 10px;
`;

const countParticipants = participants => {
  let count = 0;
  for (const participant of participants) {
    count += participant.group || 1;
  }
  return count;
};

export default ({
  tier,
  pokemon,
  form,
  start,
  end,
  participants,
  ex,
  setFeatured
}) => {
  return (
    <Container onClick={setFeatured}>
      <IconWrapper>
        {pokemon ? (
          <Portrait number={pokemon} form={form} />
        ) : (
          // portrait[pokemon] || (
          //   <Text>
          //     <PortraitAlternative number={pokemon} form={form} />
          //   </Text>
          // )
          _tier(tier)
        )}
      </IconWrapper>
      <StarContainer>{new Array(tier).fill(<FlexStar />)}</StarContainer>
      <Bar ex={ex}>
        <ParticipantsWrapper>
          <Bubble ex={ex} />
          <Participants>
            {participants ? countParticipants(participants) : 0}
          </Participants>
        </ParticipantsWrapper>
        <Timer start={start} end={end} />
      </Bar>
    </Container>
  );
};
