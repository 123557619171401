import React from "react";
import styled from "styled-components";

import SelectorWrapper from "./SelectorWrapper";
import HiddenInput from "../utility/HiddenInput";
import IconLabel from "./IconLabel";
import _Icon from "../IconSizeWrapper";
import Text from "../Text";
import _Mystic from "../../assets/icon/team/Mystic";
import _Valor from "../../assets/icon/team/Valor";
import _Instinct from "../../assets/icon/team/Instinct";

import { selectedSpace } from "../../utility/styling";
import { space } from "../../theme";
import { injectIntl } from "react-intl";

const Icon = styled(_Icon)`
  ${selectedSpace({ selectedMr: space("tiny") })}
`;

const Mystic = styled(_Mystic)`
  width: 100%;
`;

const Valor = styled(_Valor)`
  width: 100%;
`;

const Instinct = styled(_Instinct)`
  width: 100%;
`;

const messages = {
  mystic: {
    id: "team.mystic",
    defaultMessage: "Mystic"
  },
  valor: {
    id: "team.valor",
    defaultMessage: "Valor"
  },
  instinct: {
    id: "team.instinct",
    defaultMessage: "Instinct"
  }
};

export default injectIntl(({ team, setTeam, intl }) => (
  <SelectorWrapper>
    <HiddenInput
      type={"radio"}
      name={"team"}
      value={"mystic"}
      id={"team-mystic"}
      onChange={event => setTeam(event.target.value)}
    />
    <IconLabel htmlFor={"team-mystic"} selected={team === "mystic"}>
      <Icon>
        <Mystic />
      </Icon>
      <Text>{intl.formatMessage(messages.mystic)}</Text>
    </IconLabel>
    <HiddenInput
      type={"radio"}
      name={"team"}
      value={"valor"}
      id={"team-valor"}
      onChange={event => setTeam(event.target.value)}
    />
    <IconLabel htmlFor={"team-valor"} selected={team === "valor"}>
      <Icon>
        <Valor />
      </Icon>
      <Text>{intl.formatMessage(messages.valor)}</Text>
    </IconLabel>
    <HiddenInput
      type={"radio"}
      name={"team"}
      value={"instinct"}
      id={"team-instinct"}
      onChange={event => setTeam(event.target.value)}
    />
    <IconLabel htmlFor={"team-instinct"} selected={team === "instinct"}>
      <Icon>
        <Instinct />
      </Icon>
      <Text>{intl.formatMessage(messages.instinct)}</Text>
    </IconLabel>
  </SelectorWrapper>
));
