import React from "react";
import styled from "styled-components";

import { Link } from "@reach/router";
import Flex from "./utility/Flex";
import { margin, space, themeGet } from "styled-system";
import Title from "./Title";
import RoundButton from "./RoundButton";
import { color, anchor, h, fontSize, transition } from "../theme";
import { useIntl } from "react-intl";

const SlidingMenu = styled(Flex)`
  position: fixed;
  height: 100vh;
  width: auto;
  max-width: 100vw;
  top: 0;
  left: 0;
  padding: 2rem;
  z-index: 2147483646;
  background: white;
  transition: transform 150ms ${transition("in")}, box-shadow 50ms linear;
  box-shadow: ${({ visible }) => (visible ? themeGet("shadow.high") : "none")};
  ${({ visible }) => !visible && "transform: translateX(-100%);"}
  ${color("light")}
`;

const Items = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  padding-right: 1.5em;
  ${fontSize("small")}
`;

const MenuItem = styled(Link)`
  ${anchor}
  ${h}
  position: relative;
  margin-bottom: 1rem;
  text-decoration: none;
  float: left;
  clear: both;

  &:before {
    content: "> ";
  }

  :hover,
  :focus {
    transform: translateX(0.5em);
  }
  :active {
    transform: translateX(0.3em);
  }
`;

const MenuTitle = styled(Title)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default ({ menu, visible, toggleMenu, ...props }) => {
  const intl = useIntl();
  return (
    <SlidingMenu visible={visible} {...props} column>
      <Flex mb={"2rem"} justify={"space-between"} align={"center"}>
        <MenuTitle mr={"1rem"}>
          {intl.formatMessage({ id: "menu.title", defaultMessage: "Menu" })}
        </MenuTitle>
        <RoundButton onClick={toggleMenu} fontSize={"medium"}>
          &#x2715;
        </RoundButton>
      </Flex>
      <Items column>
        {menu &&
          menu.map(({ text, to }, index) => (
            <MenuItem key={index} to={to} onClick={toggleMenu}>
              {intl.formatMessage(text)}
            </MenuItem>
          ))}
      </Items>
    </SlidingMenu>
  );
};
