import React, { useState } from "react";
import Select from "../Select";
import { useNameMap } from "../PokemonProvider";
import Input from "../Input";
import { injectIntl, useIntl } from "react-intl";
import { useNotification } from "../NotificationProvider";
import Loadable from "./Loadable";
import ChildSpacing from "./ChildSpacing";
import name from "../../utility/name";

const messages = {
  submit: {
    id: "raid.resolve",
    defaultMessage: "Report Pokémon"
  },
  notification: {
    id: "raid.notification.resolve",
    defaultMessage: "Reported {pokemonName} in Raid at {gymName}!"
  },
  error: {
    id: "raid.notification.resolve.error",
    defaultMessage: "Could not report Pokémon: {message}"
  }
};

export default ({ raidId, gymName, hatches = [], onSubmitComplete }) => {
  const [number, setNumber] = useState(hatches[0] || undefined);
  const [loading, setLoading] = useState(false);
  const displayNotification = useNotification();
  const nameMap = useNameMap();
  const intl = useIntl();
  return (
    <Loadable loading={loading}>
      <ChildSpacing>
        <Select
          onChange={event => setNumber(event.target.value)}
          inverted
          required
        >
          {hatches.map((hatch, index) => (
            <option key={index} value={hatch}>
              {hatch[0]
                ? name(hatch[0], hatch[1])
                : // ? `${(nameMap && nameMap[hatch[0]]) ||
                  //     (pokemon &&
                  //       pokemon[hatch[0]] &&
                  //       pokemon[hatch[0]].name) ||
                  //     `#${hatch[0]}`} ${_form &&
                  //     _form[hatch[1]] &&
                  //     " " + _form[hatch[1]]}`
                  name(hatch)}
            </option>
          ))}
        </Select>
        <Input
          type={"submit"}
          value={intl.formatMessage(messages.submit)}
          disabled={!number}
          onClick={() => {
            fetch("https://pokeboxadvance.net/api/raid/resolve.php", {
              method: "POST",
              mode: "cors",
              cache: "no-cache",
              body: JSON.stringify({ raid: raidId, pokemon: number })
            })
              .then(response => {
                if (response.ok && response.status === 200) {
                  return response.json();
                }
              })
              .then(json => {
                if (json.success) {
                  displayNotification({
                    success: true,
                    text: intl.formatMessage(messages.notification, {
                      pokemonName: nameMap[number] || `#${number}`,
                      gymName
                    })
                  });
                  setNumber(undefined);
                  setLoading(false);
                  onSubmitComplete && onSubmitComplete();
                } else {
                  throw new Error(json.message);
                }
              })
              .catch(error => {
                setLoading(false);
                displayNotification({
                  success: false,
                  text: intl.formatMessage(messages.error, {
                    message: error.message
                  })
                });
              });
          }}
          inverted
        />
      </ChildSpacing>
    </Loadable>
  );
};
