import React from "react";
import styled from "styled-components";

import { selectedSpace } from "../utility/styling";
import { space } from "../theme";

export default styled.div`
  > div,
  > input:not(:last-child) {
    ${selectedSpace({ selectedMb: space("tiny") })}
  }
`;
