import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c0 = shiny ? "#a20137" : "#354480";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Lugia Portrait"
      id="Lugia_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Lugia</title>
      <path
        d="M82,18.24c-2.28-.61-15.09,27.29-15.55,29.58s-5.33,24.09-4.72,24.54,7.31-6.7,8.53-9.3S84.25,18.85,82,18.24Z"
        fill={c0}
      />
      <path
        d="M81.88,18.23H82c2.29.61-10.52,42.23-11.74,44.82s-7.52,9.32-8.46,9.32a.08.08,0,0,1-.07,0c-.61-.45,4.27-22.25,4.72-24.54s13-29.59,15.47-29.59m0-.71c-.61,0-1.48.36-4,4.66-1.4,2.37-3.17,5.72-5.12,9.68-3.35,6.81-6.77,14.5-7,15.82-.08.42-.32,1.52-.65,3-.69,3.22-1.86,8.6-2.76,13.1-.5,2.49-.86,4.46-1.09,5.85-.13.81-.22,1.42-.26,1.87-.06.64-.07,1.11.31,1.39a.8.8,0,0,0,.5.17c.31,0,.73-.1,2-1.29.75-.68,1.69-1.63,2.64-2.66a32,32,0,0,0,4.44-5.78c.64-1.37,4-12.14,6.94-22.29,1.68-5.86,3-10.92,3.89-14.65,1.45-6.24,1.36-7.71,1.07-8.32a1,1,0,0,0-.62-.55.84.84,0,0,0-.27,0Z"
        fill={outline}
      />
      <path
        d="M14.8,18.24c2.29-.61,15.09,27.29,15.55,29.58s5.34,24.09,4.73,24.54-7.32-6.7-8.54-9.3S12.51,18.85,14.8,18.24Z"
        fill={c0}
      />
      <path
        d="M14.88,18.23c2.48,0,15,27.33,15.47,29.59s5.34,24.09,4.73,24.54a.11.11,0,0,1-.08,0c-.94,0-7.28-6.8-8.46-9.32s-14-44.21-11.74-44.82h.08m0-1.52h0a1.84,1.84,0,0,0-.47.06,1.8,1.8,0,0,0-1.16,1c-.35.75-.56,2.05,1,8.84.87,3.74,2.22,8.83,3.9,14.7,3,10.36,6.32,21,7,22.4.89,1.9,3.73,5.06,4.58,6s1.92,2,2.68,2.71c1.24,1.12,1.89,1.5,2.58,1.5a1.6,1.6,0,0,0,1-.33,2.16,2.16,0,0,0,.63-2.12c0-.46-.13-1.09-.26-1.92-.23-1.4-.6-3.38-1.1-5.88-.91-4.51-2.07-9.89-2.77-13.11-.32-1.52-.56-2.62-.65-3-.3-1.53-4-9.72-7.1-16-2-4-3.74-7.35-5.15-9.74a31.86,31.86,0,0,0-2.15-3.28c-.71-.92-1.51-1.77-2.56-1.77Z"
        fill={outline}
      />
      <path
        d="M47.93,48.2,27.16,66.49s5.84,32.17,20.77,31S69.31,67,69.31,67Z"
        fill={"#fff"}
      />
      <path
        d="M47.93,48.2,69.31,67S62.85,96.22,47.93,97.44c-.28,0-.56,0-.83,0-14.31,0-19.94-31-19.94-31L47.93,48.2m0-2-1,.89L26.15,65.35l-.65.57.16.85a86.49,86.49,0,0,0,4.69,15.8A40.14,40.14,0,0,0,36.8,93.64C39.91,97.2,43.38,99,47.1,99c.31,0,.63,0,.95,0,3.8-.31,7.37-2.25,10.63-5.76A42.17,42.17,0,0,0,65.6,82.38a81.38,81.38,0,0,0,5.2-15.1L71,66.4l-.68-.6L48.93,47.05l-1-.88Z"
        fill={outline}
      />
      <path
        d="M30.51,72.36S35.27,96,47.93,96,65.35,72.36,65.35,72.36Z"
        fill={"#635"}
      />
      <path
        d="M32,70.08S36.4,93.71,48,93.71,64,70.08,64,70.08Z"
        fill={"#bc647f"}
      />
      <path
        d="M72.66,56.2c-1.53-15.55-12.2-24.54-13.72-29S48.33,4.81,48.12,4.38h0c-.21.43-9.31,18.49-10.82,22.86S25.1,40.65,23.57,56.2c-.44,4.52,1.23,9.1,3.89,13.32a27.5,27.5,0,0,0,1,4.22c.23.46,1.31.45,2.33.34,6,7.2,13.85,12.71,16.58,14a.82.82,0,0,0,.75.28h0a.82.82,0,0,0,.75-.28c2.73-1.3,10.61-6.8,16.57-14,1,.1,2,.09,2.19-.35a24.82,24.82,0,0,0,.94-3.9C71.34,65.53,73.11,60.84,72.66,56.2Z"
        fill={"#fff"}
      />
      <path
        d="M48.12,4.37h0c.21.43,9.31,18.49,10.82,22.86s12.19,13.41,13.72,29c.45,4.64-1.32,9.33-4.09,13.64a24.82,24.82,0,0,1-.94,3.9c-.15.3-.67.4-1.31.4-.28,0-.58,0-.88,0-6,7.2-13.84,12.7-16.57,14a.88.88,0,0,1-.62.29H48a.89.89,0,0,1-.62-.29c-2.73-1.3-10.62-6.81-16.58-14a8.85,8.85,0,0,1-1,.06c-.63,0-1.16-.1-1.31-.4a27.5,27.5,0,0,1-1-4.22c-2.66-4.22-4.33-8.8-3.89-13.32,1.53-15.55,12.2-24.54,13.72-29S47.9,4.81,48.11,4.38h0m0-3.37S37.3,22.54,35.85,26.74a24.34,24.34,0,0,1-3,4.73C29,36.73,23.17,44.66,22.06,56.05c-.43,4.37.9,9.09,4,14,.65,3.47.95,4.08,1.08,4.34a2.82,2.82,0,0,0,2.67,1.25h.36C36,82.53,43.47,87.85,46.51,89.37a2.31,2.31,0,0,0,1.47.54h.27a2.33,2.33,0,0,0,1.48-.54c3-1.52,10.51-6.83,16.38-13.71h.21a4.48,4.48,0,0,0,1.24-.15A2.17,2.17,0,0,0,69,74.42a16.29,16.29,0,0,0,1-4c3.2-5.06,4.6-9.9,4.17-14.37-1.12-11.39-6.92-19.32-10.76-24.58a24.34,24.34,0,0,1-3-4.73C58.93,22.55,48.11,1,48.11,1Z"
        fill={outline}
      />
      <path
        d="M57.8,90.81c-1.37,1.22-1.3,1.37-1.3,1.37s.08-4.42.54-4.42S59.18,89.59,57.8,90.81Z"
        fill={"#fff"}
      />
      <path
        d="M38,90.81c1.37,1.22,1.3,1.37,1.3,1.37s-.08-4.42-.54-4.42S36.6,89.59,38,90.81Z"
        fill={"#fff"}
      />
      <path
        d="M82,18.24c-2.28-.61-15.09,27.29-15.55,29.58s-5.33,24.09-4.72,24.54,7.31-6.7,8.53-9.3S84.25,18.85,82,18.24Z"
        fill={c0}
      />
      <path
        d="M14.8,18.24c2.29-.61,15.09,27.29,15.55,29.58s5.34,24.09,4.73,24.54-7.32-6.7-8.54-9.3S12.51,18.85,14.8,18.24Z"
        fill={c0}
      />
      <path
        d="M69.25,53.46c.32,0-.78,10.21-1.1,11.3s-4.72,6.5-5.36,5.88S68.94,53.46,69.25,53.46Z"
        fill={"#fff"}
      />
      <path
        d="M64.69,68.47c.69,0,1.26-2.22,1.26-4.95a19.35,19.35,0,0,0-.12-2.1c-.76,1.91-1.5,3.87-2.06,5.48C64,67.87,64.32,68.47,64.69,68.47Z"
        fill={outline}
      />
      <path
        d="M64.61,66.61c.3,0,.55-.62.55-1.39A2.12,2.12,0,0,0,64.84,64l-.72,1.94C64.22,66.32,64.4,66.61,64.61,66.61Z"
        fill={"#fff"}
      />
      <path
        d="M27.59,53.46c-.31,0,.79,10.21,1.11,11.3s4.72,6.5,5.35,5.88S27.91,53.46,27.59,53.46Z"
        fill={"#fff"}
      />
      <path
        d="M32.16,68.47c-.69,0-1.26-2.22-1.26-4.95a17.11,17.11,0,0,1,.12-2.1c.76,1.91,1.5,3.87,2.06,5.48C32.85,67.87,32.52,68.47,32.16,68.47Z"
        fill={outline}
      />
      <path
        d="M32.24,66.61c-.3,0-.55-.62-.55-1.39A2.12,2.12,0,0,1,32,64c.25.67.49,1.32.71,1.94C32.63,66.32,32.45,66.61,32.24,66.61Z"
        fill={"#fff"}
      />
    </SVG>
  );
};
