import React from "react";
import StyledSVG from "../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 101 127"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M100.209 55.6735C96.5467 95.6654 50.2096 126.592 50.2096 126.592C50.2096 126.592 3.87227 95.6654 0.209457 55.6735H25.5939C27.9143 67.0892 38.0533 75.6834 50.2095 75.6834C62.3657 75.6834 72.5047 67.0892 74.8251 55.6735H100.209ZM100.192 45.7104C97.6799 20.4445 76.2608 0.709167 50.2095 0.709167C24.1581 0.709167 2.73896 20.4445 0.226615 45.7104H25.5939C27.9144 34.2948 38.0533 25.7006 50.2095 25.7006C62.3657 25.7006 72.5046 34.2948 74.8251 45.7104H100.192Z"
      fill="currentcolor"
    />
    <ellipse
      cx="50.2093"
      cy="50.6924"
      rx="15.0688"
      ry="14.9949"
      fill="currentcolor"
    />
  </StyledSVG>
);
