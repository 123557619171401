import React from "react";
import Flex from "../utility/Flex";
import Loading from "../Loading";

export default ({ loading, children }) =>
  loading ? (
    <Flex justify={"center"}>
      <Loading />
    </Flex>
  ) : (
    children
  );
