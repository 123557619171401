import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c3 = shiny ? "#566b4d" : "#4a2306";
  const c4 = shiny ? "#fac58d" : "#ff8f8d";
  const c0 = shiny ? "#cceabb" : "#d2a780";
  const c2 = shiny ? "#7f9473" : "#683b1e";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Swinub Portrait"
      id="Swinub_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Swinub</title>
      <path
        d="M89.9,69.37c2.7-13.65-4.67-54.26-39-55.12h-2C14.51,15.11,7.19,56,10,69.48c-1.24,1-8.93,7.09-8.55,7.35s2.63-.28,2.77,0-1.25.83-1,1.11,1.52-.14,1.94,0-.28.55,0,.55,3.18-.83,3.74-.69-.14.69.41.69,6.73-.87,8.84-.5,6.24,2.24,9.71,3A129.47,129.47,0,0,0,50,83.61a129.33,129.33,0,0,0,20.72-2.33c3.93-.8,9.11-2.84,11-3.3s8.5.51,9,.51-.14-.55.41-.69,3.46.69,3.74.69-.41-.42,0-.55,1.66.27,1.94,0-1.11-.83-1-1.11,2.35.28,2.77,0S90.4,69.76,89.9,69.37Z"
        fill={c0}
      />
      <path
        d="M49.92,14.24h1c34.3.86,41.67,41.47,39,55.12.5.39,9.1,7.19,8.7,7.46a.94.94,0,0,1-.47.08c-.58,0-1.48-.16-2-.16-.18,0-.3,0-.33.08-.14.28,1.25.83,1,1.11a.56.56,0,0,1-.38.09c-.39,0-1-.12-1.32-.12a.78.78,0,0,0-.24,0c-.41.13.28.55,0,.55s-2.75-.71-3.57-.71l-.17,0c-.55.14.14.69-.41.69-.37,0-4.75-.63-7.42-.63a7,7,0,0,0-1.54.12c-1.91.46-7.09,2.5-11,3.3A129.33,129.33,0,0,1,50,83.61,129.47,129.47,0,0,1,27.84,81c-3.47-.76-7.61-2.64-9.71-3a8.18,8.18,0,0,0-1.42-.09c-2.69,0-7,.59-7.42.59-.55,0,.14-.55-.41-.69l-.17,0c-.82,0-3.32.71-3.57.71s.41-.42,0-.55a.78.78,0,0,0-.24,0c-.37,0-.93.12-1.32.12a.56.56,0,0,1-.38-.09c-.28-.28,1.11-.83,1-1.11,0-.06-.15-.08-.33-.08-.49,0-1.39.16-2,.16a.94.94,0,0,1-.47-.08C1,76.57,8.71,70.47,10,69.48,7.19,56,14.51,15.11,48.9,14.25h1m0-1.39H48.86A37.79,37.79,0,0,0,32,17.12a38,38,0,0,0-11.9,10C13.87,34.92,10.93,44,9.55,50.25a63.91,63.91,0,0,0-1.47,11,39.43,39.43,0,0,0,.36,7.71c-.95.75-2.58,2.06-4.12,3.33-1.14.94-2.05,1.72-2.7,2.3C.42,75.62,0,76.1,0,76.86A1.44,1.44,0,0,0,.63,78a2.14,2.14,0,0,0,1.21.31,1.57,1.57,0,0,0,.38.63,1.85,1.85,0,0,0,1.36.5,3.44,3.44,0,0,0,.46,0,1.45,1.45,0,0,0,1.1.49,5.77,5.77,0,0,0,1.28-.25c.36-.09,1-.25,1.57-.35l0,0a1.6,1.6,0,0,0,1.28.58c.17,0,.41,0,1.22-.12,1.51-.17,4.31-.48,6.2-.48a7.37,7.37,0,0,1,1.18.07,31.38,31.38,0,0,1,3.87,1.21,52.24,52.24,0,0,0,5.78,1.79A130,130,0,0,0,50,85a130.57,130.57,0,0,0,21-2.36,73.08,73.08,0,0,0,7.92-2.32c1.34-.45,2.49-.83,3.14-1a5.27,5.27,0,0,1,1.22-.09,61.51,61.51,0,0,1,6.18.51c.82.1,1.06.13,1.24.13A1.6,1.6,0,0,0,92,79.3l0,0c.54.1,1.21.26,1.57.35a5.77,5.77,0,0,0,1.28.25,1.45,1.45,0,0,0,1.1-.49,3.44,3.44,0,0,0,.46,0,1.85,1.85,0,0,0,1.36-.5,1.57,1.57,0,0,0,.38-.63A2.14,2.14,0,0,0,99.37,78a1.41,1.41,0,0,0,.63-1.12c0-.77-.46-1.28-1.75-2.43-.7-.62-1.69-1.46-2.92-2.47-1.52-1.25-3.07-2.48-3.92-3.16a39.27,39.27,0,0,0,.33-7.75,63.72,63.72,0,0,0-1.49-11c-1.4-6.24-4.36-15.3-10.57-23.05A36.61,36.61,0,0,0,51,12.86Z"
        fill={outline}
      />
      <path
        d="M19.19,52.66c2-3.35-3.24-7.09-4-15a61.81,61.81,0,0,0-3.53,9.66C13.65,50.68,17.65,55.24,19.19,52.66Z"
        fill={c2}
      />
      <path
        d="M28.93,20.6a37.7,37.7,0,0,0-8,7.66c-.2,7.23,5,16.18,7.38,13.88S28.28,28.45,28.93,20.6Z"
        fill={c2}
      />
      <path
        d="M58.27,15.1a40.35,40.35,0,0,0-7.34-.85h-2a40.23,40.23,0,0,0-7.42.87c.27,9.59,5.54,29.23,8.46,29.23S57.17,24.91,58.27,15.1Z"
        fill={c2}
      />
      <path
        d="M9.43,63.67c2.54,1.66,7,4.31,7,2.43S12.52,60,9.94,55.93A55.82,55.82,0,0,0,9.43,63.67Z"
        fill={c2}
      />
      <path
        d="M88.05,47a60.47,60.47,0,0,0-3.66-9.81c-.55,8.2-6,12-4,15.43C82,55.31,86.18,50.41,88.05,47Z"
        fill={c2}
      />
      <path
        d="M70.64,20.42c.7,7.85-1.69,19.52.61,21.72,2.45,2.33,7.75-6.92,7.38-14.2A37.61,37.61,0,0,0,70.64,20.42Z"
        fill={c2}
      />
      <path
        d="M90.41,63.51a55.69,55.69,0,0,0-.57-7.9c-2.56,4.2-6.68,8.42-6.68,10.49S87.92,65.15,90.41,63.51Z"
        fill={c2}
      />
      <path
        d="M29,63.88c1.4-.29,2.75-.63,4.08-1A29.91,29.91,0,0,0,37,61.35c.62-.3,1.22-.62,1.81-1,.15-.09.3-.17.44-.27l.43-.28c.28-.19.57-.38.84-.59a21.61,21.61,0,0,0,3.08-2.84,10.71,10.71,0,0,1-2.52,3.5,11.94,11.94,0,0,1-1.71,1.37c-.3.2-.61.39-.92.57s-.64.35-1,.51a16.88,16.88,0,0,1-4.13,1.33,17.35,17.35,0,0,1-2.14.26c-.36,0-.72,0-1.08,0A9.45,9.45,0,0,1,29,63.88Z"
        fill={c3}
      />
      <path
        d="M71,63.88a9.81,9.81,0,0,1-1.08.08c-.36,0-.71,0-1.07,0a17.28,17.28,0,0,1-2.15-.26,16.81,16.81,0,0,1-4.12-1.33c-.33-.16-.65-.33-1-.51s-.63-.37-.93-.57A12.52,12.52,0,0,1,59,59.9a10.83,10.83,0,0,1-2.51-3.5,21,21,0,0,0,3.07,2.84c.27.21.56.4.84.59l.44.28c.14.1.28.18.43.27.59.35,1.2.67,1.82,1a29.77,29.77,0,0,0,3.85,1.49C68.23,63.25,69.59,63.59,71,63.88Z"
        fill={c3}
      />
      <ellipse cx="50.08" cy="69.63" fill={c4} rx="10.59" ry="7.2" />
      {/* <path */}
      {/* d="M50.08,77.31C44,77.31,39,73.87,39,69.63s5-7.69,11.08-7.69,11.08,3.45,11.08,7.69S56.19,77.31,50.08,77.31Zm0-14.4c-5.58,0-10.11,3-10.11,6.72s4.53,6.71,10.11,6.71,10.1-3,10.1-6.71S55.65,62.91,50.08,62.91Z" */}
      {/* fill={outline} */}
      {/* /> */}
      <line fill={c3} x1="46.22" x2="46.17" y1="67.72" y2="72.15" />
      <path
        d="M46.17,72.7h0a.55.55,0,0,1-.55-.56l.05-4.43a.55.55,0,0,1,.55-.55h0a.56.56,0,0,1,.55.56l-.05,4.43A.55.55,0,0,1,46.17,72.7Z"
        fill={outline}
      />
      <line fill={c3} x1="53.7" x2="53.65" y1="67.8" y2="72.23" />
      <path
        d="M53.65,72.79h0a.56.56,0,0,1-.55-.57l0-4.43a.55.55,0,0,1,.55-.54h0a.56.56,0,0,1,.55.56l0,4.43A.57.57,0,0,1,53.65,72.79Z"
        fill={outline}
      />
    </SVG>
  );
};
