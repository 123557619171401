import styled from "styled-components";

import PaddedLabel from "./PaddedLabel";

export default styled(PaddedLabel)`
  width: calc(100% / 3);
  float: left;
  justify-content: center;
  transition: opacity 0.05s linear;
  opacity: ${({ selected }) => (selected ? "1" : "0.5")};
`;
