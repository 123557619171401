import React from "react";

import Page from "../component/Page";
import PageGroup from "../component/PageGroup";
import Flex from "../component/utility/Flex";
import Loading from "../component/Loading";

const NotFound = props => (
  <Page title={"Not Found"} heading={"This page doesn't exist"} {...props}>
    <PageGroup>
      <Flex justify={"center"}>
        <Loading error />
      </Flex>
    </PageGroup>
  </Page>
);

export default NotFound;
