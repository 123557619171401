import React from "react";

import StyledSVG from "../portrait/StyledSVG";

export default ({ className }) => (
  <StyledSVG
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M50 100.294C77.6142 100.294 100 77.9087 100 50.2945C100 22.6802 77.6142 0.294456 50 0.294456C22.3858 0.294456 0 22.6802 0 50.2945C0 77.9087 22.3858 100.294 50 100.294ZM28.1541 47.0549L29.865 33.076H49.9253L57.6868 49.4211L43.4522 69.3701H25.3874L27.1712 55.0272H43.0066L44.0623 47.0549H28.1541ZM13.7748 77.6337L20.291 24.8125H55.5754C56.2792 24.8125 56.7767 24.9096 57.068 25.1037C57.3835 25.2736 57.6383 25.6012 57.8324 26.0866L65.4043 43.5966C65.5257 43.3296 65.6591 43.0869 65.8048 42.8685C65.9504 42.6258 66.1081 42.371 66.278 42.1041L76.6529 26.2322C76.9927 25.7468 77.3325 25.3949 77.6722 25.1765C78.0363 24.9338 78.4731 24.8125 78.9827 24.8125H90.0129L72.2481 48.9479L85.2441 77.6337H75.7793C75.0755 77.6337 74.5294 77.4517 74.1411 77.0876C73.7528 76.7236 73.4616 76.3231 73.2674 75.8863L64.7491 55.5005C64.652 55.6946 64.5428 55.8766 64.4214 56.0465C64.3244 56.2164 64.2151 56.3863 64.0938 56.5562L51.3163 75.8863C50.9037 76.5173 50.4183 76.9663 49.8601 77.2332C49.3262 77.5002 48.7438 77.6337 48.1128 77.6337H13.7748Z"
      fill="currentcolor"
    />
  </StyledSVG>
);
