import React from "react";
import styled from "styled-components";
import { useCookies } from "react-cookie";

import Page from "../component/Page";
import MoodMeter from "../component/buddy/MoodMeter.js";
import Flex from "../component/utility/Flex";
import ActionHistory from "../component/buddy/ActionHistory";
import ActionBlock from "../component/buddy/ActionBlock";

import { action, actions } from "../component/buddy/ActionHistory";

import Pawsteps from "../assets/icon/Pawsteps";
import Berry from "../assets/icon/Berry";
import Battle from "../assets/icon/Battle";
import Camera from "../assets/icon/Camera";
import Location from "../assets/icon/Location";
import Flower from "../assets/icon/Flower";
import Hand from "../assets/icon/Hand";
import Heading from "../component/Heading";
import { useIntl } from "react-intl";

export const getNextDay = () => {
  const now = new Date();
  const nowUnix = now.getTime();
  return new Date(
    nowUnix -
      (nowUnix % 86400000) +
      86400000 +
      now.getTimezoneOffset() * 60 * 1000
  );
};

const ActionButtons = styled(Flex)`
  padding: 0 1.5rem;
`;

const cooldown = 1800000;

export default props => {
  const intl = useIntl();
  const [cookies] = useCookies();
  const last = new Array(actions.length).fill(0);
  let mood = 0;
  if (cookies.history && cookies.history.length && cookies.history.length > 0) {
    let previousTime = cookies.history[0].time;
    let action;
    for (const history of cookies.history) {
      action = actions[history.action];
      if (
        !action.hasCooldown ||
        history.time - last[history.action] > cooldown
      ) {
        mood += action.delta;
        last[history.action] = history.time;
      }
      if (history.time - previousTime > cooldown) {
        mood -= Math.floor((history.time - previousTime) / cooldown);
      }
      previousTime = history.time;
    }
  }
  return (
    <Page
      title={intl.formatMessage({
        id: "buddy.mood",
        defaultMessage: "Buddy Mood"
      })}
      marginedHeader
      {...props}
    >
      <Heading>
        {intl.formatMessage({
          id: "buddy.interactions",
          defaultMessage: "Interactions"
        })}
      </Heading>
      <MoodMeter mood={mood} />
      <ActionButtons wrap>
        <ActionBlock
          category={intl.formatMessage({
            id: "buddy.interaction.walk",
            defaultMessage: "Walk"
          })}
          icon={<Pawsteps height={"1.5rem"} />}
          actions={[action.walk]}
        />
        <ActionBlock
          category={intl.formatMessage({
            id: "buddy.interaction.category.treat",
            defaultMessage: "Treat"
          })}
          icon={<Berry height={"1.5rem"} />}
          actions={[action.feed.berry]}
        />
        <ActionBlock
          category={intl.formatMessage({
            id: "buddy.interaction.play",
            defaultMessage: "Play"
          })}
          icon={<Hand height={"1.5rem"} />}
          actions={[action.play]}
        />
        <ActionBlock
          category={intl.formatMessage({
            id: "buddy.interaction.category.battle",
            defaultMessage: "Battle"
          })}
          icon={<Battle height={"1.8rem"} />}
          actions={[
            action.battle.gym,
            action.battle.raid,
            action.battle.leader,
            action.battle.trainer,
            action.battle.rocket
          ]}
        />
        <ActionBlock
          category={intl.formatMessage({
            id: "buddy.interaction.snapshot",
            defaultMessage: "Snapshot"
          })}
          icon={<Camera height={"1.3rem"} />}
          actions={[action.snapshot]}
        />
        <ActionBlock
          category={intl.formatMessage({
            id: "buddy.interaction.place",
            defaultMessage: "New place"
          })}
          icon={<Location height={"1.5rem"} />}
          actions={[action.place]}
        />
        <ActionBlock
          category={intl.formatMessage({
            id: "buddy.interaction.category.bonus",
            defaultMessage: "Bonus"
          })}
          icon={<Flower height={"1.5rem"} />}
          actions={[
            action.bonus.present,
            action.bonus.souvenir,
            action.bonus.location
          ]}
        />
      </ActionButtons>
      <ActionHistory history={cookies.history} />
    </Page>
  );
};
