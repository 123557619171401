import React from "react";
import styled from "styled-components";
import PageGroup from "./PageGroup";
import { selectedSpace } from "../utility/styling";
import { space } from "../theme";

export default styled.header`
  display: grid;
  grid-template-columns: auto auto 1fr;
  width: 100%;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;

  & > * {
    grid-row-start: 1;
    grid-row-end: 1;
  }
`;
