import React, { useState } from "react";
import { defineMessages, injectIntl } from "react-intl";

import Card from "../component/Card";
import CardHolder from "../component/CardHolder";
import ChildMarginBox from "../component/ChildMarginBox";
import TeamSelector from "../component/iv/TeamSelector";
import PokemonSelector from "../component/selector/PokemonSelector";
import Input from "../component/Input";
import InputGroup from "../component/InputGroup";
import AppraisalSelector from "../component/iv/AppraisalSelector";
import Page from "../component/Page";
import StatSelector from "../component/iv/StatSelector";
import Section from "../component/Section";
import Text from "../component/Text";
import Header from "../component/Header";
import Title from "../component/Title";

const messages = defineMessages({
  title: {
    id: "title.calculator",
    defaultMessage: "IV Calculator"
  },
  stat: {
    cp: {
      id: "stat.cp",
      defaultMessage: "CP"
    },
    hp: {
      id: "stat.hp",
      defaultMessage: "HP"
    },
    stardust: {
      id: "resource.stardust",
      defaultMessage: "Stardust"
    }
  },
  section: {
    yourPokemon: {
      id: "ivCalculator.section.pokemon",
      defaultMessage: "Your Pokémon..."
    },
    itsBestAttribute: {
      id: "ivCalculator.section.attribute",
      defaultMessage: "Its best attribute is its..."
    },
    itsStats: {
      id: "ivCalculator.section.stat",
      defaultMessage: "Its stats..."
    }
  },
  appraisal: {
    overall: {
      mystic: {
        best: {
          id: "appraisal.overall.mystic.best",
          defaultMessage:
            "Overall, your Pokemon is a wonder! What a breathtaking Pokemon!"
        },
        second: {
          id: "appraisal.overall.mystic.second",
          defaultMessage:
            "Overall, your Pokemon has certainly caught my attention."
        },
        third: {
          id: "appraisal.overall.mystic.third",
          defaultMessage: "Overall, your Pokemon is above average."
        },
        fourth: {
          id: "appraisal.overall.mystic.fourth",
          defaultMessage:
            "Overall, your Pokemon is not likely to make much headway in battle."
        }
      },
      valor: {
        best: {
          id: "appraisal.overall.valor.best",
          defaultMessage:
            "Overall, your Pokemon simply amazes me. It can accomplish anything!"
        },
        second: {
          id: "appraisal.overall.valor.second",
          defaultMessage:
            "Overall, your Pokemon is a strong Pokemon. You should be proud!"
        },
        third: {
          id: "appraisal.overall.valor.third",
          defaultMessage: "Overall, your Pokemon is a decent Pokemon."
        },
        fourth: {
          id: "appraisal.overall.valor.fourth",
          defaultMessage:
            "Overall, your Pokemon may not be great in battle, but I still like it!"
        }
      },
      instinct: {
        best: {
          id: "appraisal.overall.instinct.best",
          defaultMessage:
            "Overall, your Pokemon looks like it can really battle with the best of them!"
        },
        second: {
          id: "appraisal.overall.instinct.second",
          defaultMessage: "Overall, your Pokemon is really strong!"
        },
        third: {
          id: "appraisal.overall.instinct.third",
          defaultMessage: "Overall, your Pokemon is pretty decent!"
        },
        fourth: {
          id: "appraisal.overall.instinct.fourth",
          defaultMessage:
            "Overall, your Pokemon has room for improvement as far as battling goes."
        }
      }
    },
    stat: {
      mystic: {
        best: {
          id: "appraisal.stat.mystic.best",
          defaultMessage: "Its stats exceed my calculations. It's incredible!"
        },
        second: {
          id: "appraisal.stat.mystic.second",
          defaultMessage: "I am certainly impressed by its stats, I must say."
        },
        third: {
          id: "appraisal.stat.mystic.third",
          defaultMessage: "Its stats are noticeably trending to the positive."
        },
        fourth: {
          id: "appraisal.stat.mystic.fourth",
          defaultMessage: "Its stats are not out of the norm, in my estimation."
        }
      },
      valor: {
        best: {
          id: "appraisal.stat.valor.best",
          defaultMessage: "I'm blown away by its stats. WOW!"
        },
        second: {
          id: "appraisal.stat.valor.second",
          defaultMessage: "It's got excellent stats! How exciting!"
        },
        third: {
          id: "appraisal.stat.valor.third",
          defaultMessage:
            "Its stats indicate that in battle, it'll get the job done."
        },
        fourth: {
          id: "appraisal.stat.valor.fourth",
          defaultMessage: "Its stats don't point to greatness in battle."
        }
      },
      instinct: {
        best: {
          id: "appraisal.stat.instinct.best",
          defaultMessage:
            "Its stats are the best I've ever seen! No doubt about it!"
        },
        second: {
          id: "appraisal.stat.instinct.second",
          defaultMessage: "Its stats are really strong! Impressive."
        },
        third: {
          id: "appraisal.stat.instinct.third",
          defaultMessage: "It's definitely got some good stats. Definitely!"
        },
        fourth: {
          id: "appraisal.stat.instinct.fourth",
          defaultMessage:
            "Its stats are all right, but kinda basic, as far as I can see."
        }
      }
    }
  }
});

export default injectIntl(
  ({ saved, team = "mystic", pokemonData, pokemonNameNumberMap, intl }) => {
    const sanitizeTeam = team =>
        team === "mystic" || team === "valor" || team === "instinct"
          ? team
          : "mystic",
      [_team, _setTeam] = useState(sanitizeTeam(team)),
      [pokemon, setPokemon] = useState(undefined),
      [cp, setCp] = useState(undefined),
      [hp, setHp] = useState(undefined),
      [stardust, setStardust] = useState(undefined),
      setTeam = team => _setTeam(sanitizeTeam(team)),
      [appraisalOverall, setAppraisalOverall] = useState(undefined),
      [appraisalStats, setAppraisalStats] = useState({
        stamina: undefined,
        attack: undefined,
        defense: undefined
      }),
      [appraisalStatValue, setAppraisalStatValue] = useState(undefined);
    return (
      <Page title={"IV Calculator"}>
        <Header>
          <Title>{intl.formatMessage(messages.title)}</Title>
        </Header>
        <CardHolder>
          <Card>
            <TeamSelector team={_team} setTeam={_setTeam} />
            <ChildMarginBox>
              <PokemonSelector
                pokemon={pokemon}
                setPokemon={setPokemon}
                pokemonData={pokemonData}
                pokemonNameNumberMap={pokemonNameNumberMap}
              />
              <InputGroup>
                <Input
                  type={"number"}
                  placeholder={intl.formatMessage(messages.stat.cp)}
                />
                <Input
                  type={"number"}
                  placeholder={intl.formatMessage(messages.stat.hp)}
                />
                <Input
                  type={"number"}
                  placeholder={intl.formatMessage(messages.stat.stardust)}
                />
              </InputGroup>
            </ChildMarginBox>
            <Section>
              {intl.formatMessage(messages.section.yourPokemon)}
            </Section>
            <AppraisalSelector
              name={"appraisal-overall"}
              value={appraisalOverall}
              setValue={setAppraisalOverall}
              best={
                <Text>
                  {intl.formatMessage(messages.appraisal.overall[_team].best)}
                </Text>
              }
              second={
                <Text>
                  {intl.formatMessage(messages.appraisal.overall[_team].second)}
                </Text>
              }
              third={
                <Text>
                  {intl.formatMessage(messages.appraisal.overall[_team].third)}
                </Text>
              }
              fourth={
                <Text>
                  {intl.formatMessage(messages.appraisal.overall[_team].fourth)}
                </Text>
              }
            />
            <Section>
              {intl.formatMessage(messages.section.itsBestAttribute)}
            </Section>
            <StatSelector {...appraisalStats} setValue={setAppraisalStats} />
            <Section>{intl.formatMessage(messages.section.itsStats)}</Section>
            <AppraisalSelector
              name={"appraisal-stat"}
              value={appraisalStatValue}
              setValue={setAppraisalStatValue}
              best={
                <Text>
                  {intl.formatMessage(messages.appraisal.stat[_team].best)}
                </Text>
              }
              second={
                <Text>
                  {intl.formatMessage(messages.appraisal.stat[_team].second)}
                </Text>
              }
              third={
                <Text>
                  {intl.formatMessage(messages.appraisal.stat[_team].third)}
                </Text>
              }
              fourth={
                <Text>
                  {intl.formatMessage(messages.appraisal.stat[_team].fourth)}
                </Text>
              }
            />
          </Card>
        </CardHolder>
      </Page>
    );
  }
);
