import React from "react";
import styled from "styled-components";
import { colour } from "../theme";
import _Favicon from "../assets/favicon/Favicon";

const Favicon = styled(_Favicon)`
  max-width: 170px;
`;

const Screen = styled.rect`
  @keyframes screen {
    from {
      fill: #78b0b8;
    }
    to {
      fill: ${colour("main")};
    }
  }
  fill: ${({ error }) => (error ? colour("error") : "#78b0b8")};
  ${({ error }) => !error && "animation: screen 1s infinite alternate;"}
`;

export default ({ error, className }) => (
  <Favicon
    className={className}
    screen={
      <>
        <Screen x="30" y="100" height="60" width="100" error={error} />
        {error && (
          <>
            <rect x="75" y="105" height="25" width="10" fill={"white"} />
            <rect x="75" y="135" height="10" width="10" fill={"white"} />
          </>
        )}
      </>
    }
  />
);
