import React from "react";
import styled from "styled-components";
import { space } from "styled-system";

export default styled.div`
  display: flex;
  width: 100%;
  ${({ wrap }) => wrap && "flex-wrap: wrap;"}
  ${({ column }) => column && "flex-direction: column;"}
  ${({ justify }) => justify && `justify-content: ${justify};`}
  ${({ align }) => align && `align-items: ${align};`}
  ${space}
`;
