import React from "react";
import StyledSVG from "../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 106 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="21.2634" cy="52.4564" r="18.4542" fill="currentcolor" />
    <circle cx="18.5685" cy="80.027" r="18.4542" fill="currentcolor" />
    <circle cx="44.7503" cy="80.027" r="18.4542" fill="currentcolor" />
    <circle cx="66.6423" cy="66.7552" r="18.4542" fill="currentcolor" />
    <circle cx="37.0227" cy="32.5464" r="18.4542" fill="currentcolor" />
    <path
      d="M56.3166 80.2703C58.8595 87.5813 94.6034 70.9089 81.0592 55.2671C74.0672 47.1922 66.3852 44.8545 61.7202 39.3848C57.3487 34.2593 55.9956 25.9842 49.6683 19.2049C36.5887 5.19115 26.7538 64.1024 26.7538 64.1024L56.3166 80.2703Z"
      fill="currentcolor"
    />
    <path
      d="M59.2555 40.0632C52.0879 22.1953 59.2555 4.3274 72.2635 0.139648C80.7585 12.4238 77.8384 31.6876 59.2555 40.0632Z"
      fill="currentcolor"
    />
    <path
      d="M59.2322 40.0751C73.1137 23.1961 92.0461 22.3961 105.666 34.0275C96.4403 47.9633 73.3456 53.9327 59.2322 40.0751Z"
      fill="currentcolor"
    />
  </StyledSVG>
);
