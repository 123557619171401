import React from "react";
import StyledSVG from "../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 91 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M71.0613 33.9254C71.0613 33.9254 78.1055 12.0352 86.3045 20.0505C94.5035 28.0657 92.4137 100.936 45.5795 100.936C-1.2548 100.936 -5.62832 20.8526 6.92954 19.0867C19.4874 17.3207 19.0308 39.5619 22.6111 38.6034C26.1915 37.6449 19.9929 2.49532 32.6759 0.990937C45.3589 -0.513448 48.17 29.6321 48.17 29.6321C48.17 29.6321 51.6965 2.55419 62.6287 4.90879C73.5609 7.26339 71.0613 33.9254 71.0613 33.9254Z"
      fill="currentcolor"
    />
  </StyledSVG>
);
