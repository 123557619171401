import React from "react";
import styled from "styled-components";

import PoppingWrapper from "./PoppingWrapper";
import Text from "./Text";
import _CheckMark from "../assets/CheckMark";
import { colour as _colour, fontSize, h, headingSize, space } from "../theme";
import { CSSTransition } from "react-transition-group";
import { selectedHeight, selectedSpace } from "../utility/styling";

const NotificationWrapper = styled(PoppingWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  color: white;
  z-index: 2147483647;
  background: ${({ colour }) => _colour(colour)};
`;

const CheckMark = styled(_CheckMark)`
  ${selectedHeight(headingSize("smaller"))}
`;

const NotificationText = styled(Text)`
  ${h}
  ${fontSize("small")}
`;

export default ({
  text,
  success,
  showing,
  dismissElement,
  onExited,
  onClick,
  ...props
}) => (
  <CSSTransition in={showing} timeout={150} unmountOnExit onExited={onExited}>
    <NotificationWrapper
      colour={success === undefined ? "light" : success ? "success" : "error"}
      {...props}
    >
      <NotificationText>{text}</NotificationText>
      {dismissElement}
    </NotificationWrapper>
  </CSSTransition>
);
