import React from "react";
import styled from "styled-components";
import { button, color, fontSize as _fontSize } from "../theme";
import { margin } from "styled-system";

export default styled.button`
  ${button}
  display: flex;
  align-items: center;
  background: white;
  font-size: 2rem;
  ${({ fontSize = "text" }) => _fontSize(fontSize)}
  ${color("light")}
  ${margin}
`;
