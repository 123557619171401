import React from "react";
import StyledSVG from "../../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 86 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.7474 42.5914C19.1546 42.5914 21.212 18.75 21.212 18.75C21.212 18.75 30.9106 21.8983 42.7475 21.8983C54.5843 21.8983 64.2829 18.75 64.2829 18.75C64.2829 18.75 66.3403 42.5914 42.7474 42.5914Z"
      stroke="currentcolor"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M65.7639 10.7027C69.7203 -0.499907 79.2064 2.29905 82.802 10.7023"
      stroke="currentcolor"
      stroke-width="5"
      stroke-linecap="round"
    />
    <path
      d="M19.732 10.1924C15.7756 -1.01016 6.28942 1.7888 2.69387 10.1921"
      stroke="currentcolor"
      stroke-width="5"
      stroke-linecap="round"
    />
  </StyledSVG>
);
