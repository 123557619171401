import React from "react";
import styled from "styled-components";
import { background, colour, fontSize, h, transition } from "../../theme";
import Happy from "../../assets/icon/mood/Happy";
import Smile from "../../assets/icon/mood/Smile";
import Fun from "../../assets/icon/mood/Fun";
import Excited from "../../assets/icon/mood/Excited";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding: 0 3rem;
`;

const BarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  ${background("medium")}
`;

const Bar = styled.div`
  height: 100%;
  position: absolute;
  z-index: 0;
  background: linear-gradient(
    to right,
    ${colour("accent")},
    ${colour("interact")}
  );
  background-size: calc(100vw - 2rem);
  width: calc(${({ mood }) => Math.min(32, mood)}% / 0.32);
  transition: width 200ms ${transition("in")};
`;

const Marker = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${({ align = "start" }) => align};
  flex: 0;
  position: absolute;
  bottom: 0;
  height: 100%;
  padding: 0.5rem 0;
  width: 3px;
  z-index: 1;
  left: calc(${({ position }) => position}% / 0.32);
  color: ${({ achieved }) => (achieved ? "white" : "#fff5")};
  ${background("main")}
`;

const Mood = styled.div`
  padding: 2.5rem 1rem;
  text-align: right;
  z-index: 2;
  width: calc(${({ mood }) => Math.min(32, mood)}% / 0.32);
  transition: width 200ms ${transition("in")};
  ${h}
  ${fontSize("medium")}
`;

export default ({ mood, className }) => (
  <Wrapper className={className}>
    <BarContainer>
      <Bar mood={mood} />
      {/*<Marker position={1} />*/}
      <Marker position={2} achieved={2 <= mood && mood < 6}>
        <Happy flex={"0 0 3rem"} />
      </Marker>
      <Marker position={6} achieved={6 <= mood && mood < 16}>
        <Smile flex={"0 0 3rem"} />
      </Marker>
      <Marker position={16} achieved={16 <= mood && mood < 32}>
        <Fun flex={"0 0 3rem"} />
      </Marker>
      <Marker position={32} achieved={32 <= mood}>
        <Excited flex={"0 0 3rem"} />
      </Marker>
      <Mood mood={mood}>{mood}</Mood>
    </BarContainer>
  </Wrapper>
);
