import React from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { background, colour, fontSize, width } from "../theme";
import { selectedColour, selectedWidth } from "../utility/styling";
import Header from "./Header";
import Heading from "./Heading";
import PoppingWrapper from "./PoppingWrapper";
import RightButton from "./RightButton";
import RoundButton from "./RoundButton";

const PopupWrapper = styled(PoppingWrapper)`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: white;
  z-index: 2147483645;
  max-width: 1000px;
  ${selectedColour(colour("light"))}
  ${selectedWidth(width("popup"))}
`;

const LeftHeading = styled(Heading)`
  justify-self: start;
  grid-column-start: 1;
  grid-column-end: 1;
`;

const HeaderElements = styled.div`
  justify-self: start;
  grid-column-start: 2;
  grid-column-end: 2;
`;

const StartHeader = styled(Header)`
  align-items: start;
`;

const CloseButton = styled(RoundButton)`
  justify-self: end;
  grid-column-start: 3;
  grid-column-end: 3;
  color: white;
  ${background("light")}
`;

export default ({
  title,
  headerElements,
  showing,
  dismiss,
  onExited,
  children
}) => (
  <CSSTransition in={showing} timeout={150} unmountOnExit onExited={onExited}>
    <PopupWrapper m={"auto"}>
      <StartHeader noside>
        <LeftHeading>{title}</LeftHeading>
        <HeaderElements>{headerElements}</HeaderElements>
        <CloseButton onClick={dismiss} fontSize={"small"}>
          {"\u2715"}
        </CloseButton>
      </StartHeader>
      {children}
    </PopupWrapper>
  </CSSTransition>
);
