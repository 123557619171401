import React from "react";
import styled from "styled-components";

import { selectedBorderRadius } from "../../utility/styling";
import { borderRadius } from "../../theme";

const SelectorWrapper = styled.div`
  overflow: hidden;
  ${selectedBorderRadius(borderRadius("main"))}
`;

export default SelectorWrapper;
