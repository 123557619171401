import React from "react";
import styled from "styled-components";

import { space, background, color } from "../theme";
import { selectedSpace } from "../utility/styling";

import Section from "./Section";
import Link from "./Link";

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  ${color("light")}
  ${background("dark")}
  ${selectedSpace({
    selectedP: space("larger"),
    selectedPb: space("massive")
  })}
  
  & > * {
    margin-bottom: 1rem;
  }
`;

export default ({ children }) => (
  <Footer>
    <Section>
      Pok&eacute;mon is Copyright of Nintendo, Creatures Inc., Game Freak Inc.,
      and The Pok&eacute;mon Company 1995-2019. All images and names owned and
      trademarked by Nintendo, Niantic, The Pok&eacute;mon Company, and Game
      Freak are property of their respective owners and we do not claim
      ownership of these images and names. We reserve the rights to artwork
      created by us.
    </Section>
    <Section>
      This website uses cookies to provide functionality. Learn more about{" "}
      <Link to={"privacy"}>your privacy</Link>.
    </Section>
    {children}
    <Section>Copyright &copy; 2019 Pok&eacute;box Advance</Section>
  </Footer>
);
