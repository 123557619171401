import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage } from "react-intl";

import {
  selectedBg,
  selectedSpace,
  selectedHeight
} from "../../utility/styling";
import { colour, space, fontSize } from "../../theme";

import SelectorWrapper from "./SelectorWrapper";
import HiddenInput from "../utility/HiddenInput";
import IconLabel from "./IconLabel";
import Text from "../Text";
import Stamina from "../../assets/icon/Stamina";
import Attack from "../../assets/icon/Attack";
import Defense from "../../assets/icon/Defense";

const Wrapper = styled(SelectorWrapper)`
  ${selectedBg(colour("light"))}
`;

const Icon = styled.img`
  ${selectedSpace({ selectedMr: space("tiny") })}
  ${selectedHeight(fontSize("heading.large"))}
`;

const text = defineMessages({
  stamina: {
    id: "appraisal.stat.stamina",
    defaultMessage: "HP"
  },
  attack: {
    id: "appraisal.stat.attack",
    defaultMessage: "ATK"
  },
  defense: {
    id: "appraisal.stat.defense",
    defaultMessage: "DEF"
  }
});

export default ({ setValue, stamina, attack, defense }) => (
  <Wrapper>
    <HiddenInput
      type={"checkbox"}
      name={"stamina"}
      value={"stamina"}
      id={"stamina"}
      onChange={event => {
        stamina = event.target.checked;
        setValue({ stamina, attack, defense });
      }}
    />
    <IconLabel htmlFor={"stamina"} selected={stamina}>
      <Icon src={Stamina} alt={"Stamina"} />
      <Stamina />
      <Text>
        <FormattedMessage {...text.stamina} />
      </Text>
    </IconLabel>
    <HiddenInput
      type={"checkbox"}
      name={"attack"}
      value={"attack"}
      id={"attack"}
      onChange={event => {
        attack = event.target.checked;
        setValue({ stamina, attack, defense });
      }}
    />
    <IconLabel htmlFor={"attack"} selected={attack}>
      <Icon src={Attack} alt={"Attack"} title={"Attack"} />
      <Attack />
      <Text>
        <FormattedMessage {...text.attack} />
      </Text>
    </IconLabel>
    <HiddenInput
      type={"checkbox"}
      name={"defense"}
      value={"defense"}
      id={"defense"}
      onChange={event => {
        defense = event.target.checked;
        setValue({ stamina, attack, defense });
      }}
    />
    <IconLabel htmlFor={"defense"} selected={defense}>
      <Icon src={Defense} alt={"Defense"} title={"Defense"} />
      <Defense />
      <Text>
        <FormattedMessage {...text.defense} />
      </Text>
    </IconLabel>
  </Wrapper>
);
