import React from "react";
import styled from "styled-components";
import { flex, height, space, width } from "styled-system";

export default styled.svg`
  ${height}
  ${width}
  ${space}
  ${flex}
`;
