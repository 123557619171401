import React from "react";
import styled from "styled-components";
import { background, colour, space, transition } from "../../theme";
import { rawWidth, selectedSpace } from "../../utility/styling";
import { Link } from "@reach/router";
import themeGet from "@styled-system/theme-get";

const triangleWidth = "10rem";
// const height = "calc(20vw + 6rem)";
const height = "20rem";

const Box = styled(Link)`
  position: relative;
  display: grid;
  text-decoration: none;
  color: white;
  width: 100%;
  grid-template-rows: 1;
  grid-template-columns: calc(100% / 3) calc(200% / 3);
  overflow: hidden;
  height: ${height};

  &:hover .triangle,
  &:focus .triangle {
    border-top-color: ${colour("accent")};
  }

  &:hover .overlay,
  &:focus .overlay {
    ${rawWidth([
      "calc(100% / 1)",
      "calc(100% / 1.5)",
      "calc(100% / 2)",
      "calc(100% / 2.5)"
    ])}
    ${background("accent")}
  }

  &:active .triangle {
    border-top-color: ${colour("interact")};
  }

  &:active .overlay {
    ${background("interact")}
  }
`;

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  height: 100%;
  transition: width 200ms ${transition("in")}, background 50ms linear;
  background: ${({ background }) =>
    themeGet(background ? `colour.${background}` : "colour.light")};
  ${rawWidth([
    "calc(100% / 1.5)",
    "calc(100% / 2)",
    "calc(100% / 2.5)",
    "calc(100% / 3)"
  ])}
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  ${rawWidth([
    "calc(100vw / 2)",
    "calc(100vw / 2)",
    "calc(100vw / 2.5)",
    "calc(100vw / 3)"
  ])}
  ${selectedSpace({ selectedPy: space("large"), selectedPx: space("larger") })}
`;

const Triangle = styled.div`
  position: absolute;
  right: -${triangleWidth};
  width: ${triangleWidth};
  border-right: ${triangleWidth} solid transparent;
  border-top: ${height} solid ${colour("light")};
  transition: width 200ms ${transition("in")}, border-top-color 50ms linear;
  border-top-color: ${({ background }) =>
    themeGet(background ? `colour.${background}` : "colour.light")};
`;

const Picture = styled.div`
  float: right;
  height: 100%:
  justify-self: end;
  background: ${({ background }) =>
    background ? background : colour("medium")};
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  grid-column-start: 2;
  grid-column-end: 2;
`;

export default ({ background, textBackground, children, to, className }) => (
  <Box background={background} to={to || "/"} className={className}>
    <Overlay background={textBackground} className={"overlay"}>
      <TextBox>
        {children}
        <Triangle background={textBackground} className={"triangle"} />
      </TextBox>
    </Overlay>
    <Picture background={background} />
  </Box>
);
