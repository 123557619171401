import React from "react";
import styled from "styled-components";
import { selectedSpace } from "../utility/styling";
import { space } from "../theme";

export default styled.div`
  display: flex;
  > input:not(:first-child) {
    ${selectedSpace({ selectedMl: space("tinier") })}
  }
  > input:not(:last-child) {
    ${selectedSpace({ selectedMr: space("tinier") })}
  }
`;
