import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { injectIntl, useIntl } from "react-intl";

import { type } from "../../theme";
import { raidTimer } from "../../utility/time";

const Time = styled.div`
  color: ${({ running }) => (running ? type("fire") : "inherit")};
`;

export default ({ start, end, performance, className }) => {
  const now = Date.now();
  const [running, setRunning] = useState(start <= now);
  const [timer, setTimer] = useState(end - now);
  const intl = useIntl();
  useEffect(() => {
    const startHandler =
      now <= start + 1000 &&
      setTimeout(() => {
        setRunning(true);
      }, start - now);
    return () => clearTimeout(startHandler);
  });
  useEffect(() => {
    const endHandler =
      now <= end + 1000 &&
      setTimeout(() => {
        setRunning(false);
      }, end - now);
    return () => clearTimeout(endHandler);
  });
  useEffect(() => {
    const timerInterval =
      running &&
      setInterval(() => setTimer(end - Date.now()), performance || 1000);
    return () => clearInterval(timerInterval);
  }, [running, end, performance]);
  useEffect(() => {
    const now = Date.now();
    now < start || end < now ? setRunning(false) : setRunning(true);
  }, [start, end]);
  return (
    <Time className={className} running={running}>
      {running ? raidTimer(timer) : intl.formatTime(start)}
    </Time>
  );
};
