import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet/es/Helmet";
import Header from "./Header";
import Title from "./Title";
import Favicon from "../assets/favicon/Favicon";
import { background, colour, transition } from "../theme";

const StyledTitle = styled(Title)`
  display: flex;
  float: left;
`;

const Content = styled.div`
  padding-bottom: 2rem;
  ${background("main")}

  & > :not(.nomargin) {
    margin-bottom: 1rem;
  }

  & > :not(.unpadded) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const StyledFavicon = styled(Favicon)`
  cursor: pointer;
  height: 2.5rem;
  margin-right: 1rem;
  transition: transform 200ms ${transition("in")};

  & rect.screen {
    fill: ${colour("main")};
  }

  &:hover,
  &focus {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(1.1);
  }

  &:hover,
  &:focus rect.screen {
    fill: #78b0b8;
  }
`;

export default ({
  title = "Pokébox Advance",
  heading,
  headerElements,
  marginedHeader,
  toggleMenu,
  children
}) => (
  <>
    <Helmet>
      <title>{`${title} - Pokébox Advance`}</title>
    </Helmet>
    <Content>
      <Header className={!marginedHeader && "nomargin"}>
        <StyledFavicon onClick={toggleMenu} />
        <StyledTitle>{heading || title}</StyledTitle>
        {headerElements}
      </Header>
      {children}
    </Content>
  </>
);
