import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c5 = shiny ? "#c7b248" : "#919eb0";
  const c6 = shiny ? "#b8a442" : "#8591a1";
  const c7 = shiny ? "#d9c24e" : "#a4b3c7";
  const c0 = shiny ? "#f4da58" : "#c2d3eb";
  const c2 = shiny ? "#bfbec5" : "#467295";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Metang Portrait"
      id="Metang_Portrait"
      viewBox="0 0 100 50"
    >
      <title>{shiny && "Shiny "}Metang</title>
      <polygon fill={c0} points="13.02 13.44 18.2 28.28 35.4 24 13.02 13.44" />
      <path
        d="M13,13.44,35.4,24,18.2,28.28,13,13.44m-1.37-1.52.62,1.78,5.18,14.85.24.68.7-.18,17.2-4.27,2.16-.54-2-.95L13.36,12.72l-1.71-.8Z"
        fill={outline}
      />
      <polygon
        fill={c0}
        points="2.75 14.61 9.84 28.63 26.32 22.13 2.75 14.61"
      />
      <path
        d="M2.75,14.61l23.58,7.52L9.84,28.63l-7.09-14M1.19,13.27,2,15,9.13,29l.33.65.67-.27,16.49-6.51,2.07-.81-2.12-.68L3,13.85l-1.8-.58Z"
        fill={outline}
      />
      <polygon
        fill={c0}
        points="97.62 15.27 90.54 29.29 74.05 22.79 97.62 15.27"
      />
      <path
        d="M97.62,15.27l-7.08,14-16.49-6.5,23.57-7.52m1.57-1.34-1.81.58L73.81,22l-2.13.68,2.08.82L90.25,30l.67.27.33-.65,7.08-14,.86-1.69Z"
        fill={outline}
      />
      <polygon
        fill={c0}
        points="87.06 14.61 79.97 28.63 63.49 22.13 87.06 14.61"
      />
      <path
        d="M87.06,14.61,80,28.63l-16.48-6.5,23.57-7.52m1.56-1.34-1.8.58L63.25,21.37l-2.13.68,2.08.81,16.48,6.51.68.27.32-.65,7.09-14,.85-1.69Z"
        fill={outline}
      />
      <polygon
        fill={c2}
        points="82.96 18.73 73.89 2.72 49.87 2.72 25.86 2.72 16.78 18.73 5.89 21.7 7.54 30.45 10.67 33.91 27.51 43.98 36.09 45.8 49.87 46.13 63.65 45.8 72.23 43.98 89.07 33.91 92.2 30.45 93.86 21.7 82.96 18.73"
      />
      <path
        d="M73.88,2.72l9.08,16,10.9,3L92.2,30.45l-3.13,3.47L72.23,44,63.65,45.8l-13.78.33L36.09,45.8,27.51,44,10.67,33.92,7.54,30.45,5.89,21.7l10.89-3,9.08-16h48m.47-.79h-49l-.22.4L16.25,18.05,5.68,20.94,5,21.13l.14.72L6.76,30.6l0,.22L7,31l3.13,3.47.08.09.11.06L27.1,44.66l.11.07.13,0,8.59,1.82h.14l13.78.33h0l13.78-.33h.15l8.58-1.82.13,0,.11-.07L89.48,34.6l.1-.06.08-.09L92.79,31l.15-.16,0-.22,1.65-8.75.13-.72-.71-.19L83.49,18.05,74.58,2.33l-.23-.4Z"
        fill={outline}
      />
      <polygon
        fill={outline}
        points="58.71 21.44 42.18 21.44 24.86 20.78 22.18 20.78 18.98 20.44 16.6 19.25 16.96 18.54 19.2 19.66 22.25 19.98 24.88 19.98 42.2 20.64 58.7 20.64 75.85 20.15 78.63 19.99 81.47 19.51 82.7 18.43 83.22 19.03 81.82 20.26 78.69 20.78 75.88 20.94 58.71 21.44"
      />
      <polygon
        fill={outline}
        points="90.12 24.23 78.09 20.89 69.56 7.92 61.49 8.54 58.22 21.14 57.45 20.94 60.86 7.79 69.96 7.09 78.59 20.2 90.33 23.46 90.12 24.23"
      />
      <rect
        fill={outline}
        height="5.55"
        transform="translate(2.94 25.19) rotate(-22.75)"
        width="0.79"
        x="63.67"
        y="2.51"
      />
      <polygon
        fill={outline}
        points="42.81 21.32 38.92 8.38 30.85 7.92 22.39 20.44 21.73 19.99 30.44 7.1 39.53 7.62 43.57 21.09 42.81 21.32"
      />
      <polygon
        fill={outline}
        points="19.44 20.08 18.71 19.77 21.84 9.02 22.61 9.24 20.46 16.61 28.29 2.53 28.98 2.92 19.44 20.08"
      />
      <rect
        fill={outline}
        height="0.79"
        transform="translate(18.54 37.26) rotate(-69.43)"
        width="5.39"
        x="33.46"
        y="4.85"
      />
      <rect
        fill={outline}
        height="3.19"
        transform="translate(0.7 23.04) rotate(-21.25)"
        width="0.79"
        x="61.36"
        y="8.06"
      />
      <rect
        fill={outline}
        height="3.43"
        transform="matrix(0.82, -0.58, 0.58, 0.82, 0.65, 19.87)"
        width="0.79"
        x="31.24"
        y="7.19"
      />
      <rect
        fill={outline}
        height="0.79"
        transform="matrix(0.14, -0.99, 0.99, 0.14, 24.34, 46.37)"
        width="2.34"
        x="37.72"
        y="8.76"
      />
      <polygon
        fill={outline}
        points="50.12 26.23 50.1 26.23 9.08 24.07 5.74 22.26 6.12 21.56 9.3 23.29 50.12 25.43 90.11 23.46 93.65 21.36 94.06 22.04 90.34 24.24 50.12 26.23"
      />
      <rect fill={outline} height="20.3" width="0.79" x="49.72" y="25.83" />
      <rect
        fill={outline}
        height="0.79"
        transform="translate(-7.11 6.81) rotate(-21.8)"
        width="10.67"
        x="8.81"
        y="21.47"
      />
      <path
        d="M10.49,33.77,10,33.13a4.44,4.44,0,0,0,2.05-3.39C12.16,26.81,9,24,8.93,24l.52-.6c.14.13,3.52,3.07,3.4,6.38A5.19,5.19,0,0,1,10.49,33.77Z"
        fill={outline}
      />
      <path
        d="M50.11,36.38,25.19,35.8h-.05L9.91,33.53l.11-.79L25.23,35l24.89.58,23.58-.74,14.74-1.27a8,8,0,0,1-.63-6c1.22-3.84,5.25-5.84,5.42-5.92l.35.71L93.41,22l.17.35s-3.9,1.94-5,5.45a7.37,7.37,0,0,0,.85,5.95l-.32.59L73.73,35.63l-23.6.75Z"
        fill={outline}
      />
      <polygon
        fill={outline}
        points="24.87 20.55 32.13 9.65 39.06 9.65 42.03 21.04 24.87 20.55"
      />
      <ellipse
        cx="34.57"
        cy="15.31"
        fill={"#9a0530"}
        rx="4.92"
        ry="3.39"
        transform="translate(8.11 42.32) rotate(-69.49)"
      />
      <ellipse
        cx="34.57"
        cy="15.31"
        fill={outline}
        rx="2.86"
        ry="1.97"
        transform="translate(8.11 42.32) rotate(-69.49)"
      />
      <ellipse
        cx="35.86"
        cy="13.81"
        fill={"#fff"}
        rx="1.13"
        ry="0.78"
        transform="translate(10.37 42.56) rotate(-69.49)"
      />
      <polygon
        fill={outline}
        points="75.86 20.55 68.6 9.65 61.67 9.65 58.7 21.04 75.86 20.55"
      />
      <ellipse
        cx="66.17"
        cy="15.31"
        fill={"#9a0530"}
        rx="3.39"
        ry="4.92"
        transform="translate(-1.17 24.15) rotate(-20.51)"
      />
      <ellipse
        cx="66.17"
        cy="15.31"
        fill={outline}
        rx="1.97"
        ry="2.86"
        transform="translate(-1.17 24.15) rotate(-20.51)"
      />
      <ellipse
        cx="64.87"
        cy="13.81"
        fill={"#fff"}
        rx="0.78"
        ry="1.13"
        transform="translate(-0.73 23.6) rotate(-20.51)"
      />
      <polygon
        fill={c0}
        points="44.8 12.63 43.16 18.24 46.13 22.03 50.75 22.69 54.38 22.2 57.68 18.24 56.03 12.63 50.42 10.31 44.8 12.63"
      />
      <rect
        fill={outline}
        height="0.79"
        transform="translate(26.3 63.8) rotate(-59.53)"
        width="3.26"
        x="67.31"
        y="8.51"
      />
      <polygon
        fill={c5}
        points="43.16 18.21 50.69 16.14 44.8 12.63 43.16 18.21"
      />
      <polygon
        fill={c6}
        points="43.16 18.24 46.13 22.03 46.15 22.04 50.69 16.14 43.16 18.22 43.16 18.24"
      />
      <polygon
        fill={c5}
        points="46.13 22.03 50.69 22.68 50.69 16.14 46.11 22.02 46.13 22.03"
      />
      <polygon
        fill={c7}
        points="50.69 16.14 54.37 22.2 50.69 22.7 50.69 16.14"
      />
    </SVG>
  );
};
