import React, { useState } from "react";
import styled from "styled-components";

import Flex from "../utility/Flex";
import _Input from "../Input";
import { space, type, fontSize } from "../../theme";

import { portrait } from "../../assets/portrait";
import {
  selectedFontSize,
  selectedHeight,
  selectedSpace
} from "../../utility/styling";
import { injectIntl, useIntl } from "react-intl";

const PortraitContainer = styled.div`
  display: flex;
  ${selectedHeight(space("inputMatch"))}
  ${selectedSpace({ selectedMl: space("tinier") })}
  ${fontSize("text")}
`;

const Input = styled(_Input)`
  color: ${({ valid }) => valid && "white"};
  background-color: ${({ valid }) => valid && type("grass")} !important;
  border-color: ${({ valid }) => valid && type("grass")} !important;
`;

const messages = {
  pokemon: {
    id: "selector.pokemon",
    defaultMessage: "Pokémon"
  },
  unknown: {
    id: "selector.unknown.pokemon",
    defaultMessage: "Unknown Pokémon"
  }
};

export default ({ pokemon, setPokemon, pokemonData, pokemonNameNumberMap }) => {
  const [value, setValue] = useState(pokemon && pokemon.name);
  const intl = useIntl();
  return (
    <Flex>
      <Input
        type={"text"}
        placeholder={intl.formatMessage(messages.pokemon)}
        autofocus={"autofocus"}
        value={value || ""}
        onChange={event => {
          setValue(event.target.value);
          const pokemon =
            pokemonData[
              pokemonNameNumberMap[event.target.value.toUpperCase()] - 1
            ];
          setPokemon(pokemon);
          // pokemonData[nameToNumber[intl.defaultLocale][event.target.value]]
        }}
        onFocus={event => event.target.select()}
        valid={pokemon}
        required={true}
      />
      <PortraitContainer>
        {pokemon && pokemon.number
          ? portrait[pokemon.number] || pokemon.name
          : intl.formatMessage(messages.unknown)}
      </PortraitContainer>
    </Flex>
  );
};
