import React, { useState } from "react";
import styled from "styled-components";
import {
  selectedBg,
  selectedBorderRadius,
  selectedHeight,
  selectedSpace
} from "../../utility/styling";
import {
  borderRadius,
  colour,
  fontSize,
  headingSize,
  space
} from "../../theme";
import Input from "../Input";
import { useIntl } from "react-intl";
import { useNotification } from "../NotificationProvider";
import Loadable from "./Loadable";
import ChildSpacing from "./ChildSpacing";
import Flex from "../utility/Flex";

const messages = {
  name: {
    id: "trainer.name",
    defaultMessage: "Trainer name"
  },
  group: {
    id: "join.group.size",
    defaultMessage: "Group size"
  },
  from: {
    id: "time.from",
    defaultMessage: "from {time}"
  },
  to: {
    id: "time.to",
    defaultMessage: "to {time}"
  },
  join: {
    id: "raid.join",
    defaultMessage: "Join raid"
  },
  notification: {
    id: "raid.notification.join",
    defaultMessage:
      "Joined Raid at {gymName} as {name} +{group} from {from} to {to}!"
  },
  error: {
    id: "raid.notification.join.error",
    defaultMessage: "Could not join Raid: {message}"
  }
};

const Range = styled.input`
  height: 30px;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
`;

const TimeBarWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  ${selectedBorderRadius(borderRadius())}
  ${selectedHeight(space("inputMatch"))}
  ${selectedBg(colour("light"))}
`;

const TimeBar = styled.div`
  position: relative;
  height: 100%;
  width: calc(${({ from, to, max }) => `${to - from}00% / ${max}`});
  margin: 0 auto 0 calc(${({ from, max }) => `${from}00% / ${max}`});
  ${selectedBg(colour("interact"))}
  ${selectedBorderRadius(borderRadius())}
`;

const NameNumberWrapper = styled(Flex)`
  & > input:not(:last-child) {
    ${selectedSpace({ selectedMr: space("tinier") })}
  }
`;

const TimeWrapper = styled.label`
  position: absolute;
  display: flex;
  align-items: center;
  pointer-events: none;
  white-space: nowrap;
  ${fontSize("text")}
`;

const StartTimeWrapper = styled(TimeWrapper)`
  left: -0.4em;
  top: -2em;
`;

const EndTimeWrapper = styled(TimeWrapper)`
  right: -0.3em;
  bottom: -2em;
`;

const TimeIcon = styled.span`
  ${fontSize("heading.medium")}
`;

const Number = styled(Input)`
  max-width: 6em;
`;

const RaidTimeSelector = ({ start, end, range, setRange }) => {
  const max = end - start;
  const intl = useIntl();
  return (
    <div>
      <Range
        type={"range"}
        id={"range-start"}
        min={0}
        max={max}
        step={60000}
        value={range.from}
        onChange={event =>
          setRange({
            ...range,
            from: parseInt(
              event.target.value > range.to ? range.to : event.target.value
            )
          })
        }
      />
      <TimeBarWrapper>
        <TimeBar {...range} max={max}>
          <StartTimeWrapper htmlFor={"range-start"}>
            <TimeIcon>🚩</TimeIcon>
            &nbsp;
            {intl.formatMessage(messages.from, {
              time: intl.formatTime(start + range.from)
            })}
          </StartTimeWrapper>
          <EndTimeWrapper htmlFor={"range-end"}>
            {intl.formatMessage(messages.to, {
              time: intl.formatTime(start + range.to)
            })}
            &nbsp;
            <TimeIcon>💨</TimeIcon>
          </EndTimeWrapper>
        </TimeBar>
      </TimeBarWrapper>
      <Range
        type={"range"}
        id={"range-end"}
        min={0}
        max={max}
        step={60000}
        value={range.to}
        onChange={event =>
          setRange({
            ...range,
            to: parseInt(
              event.target.value < range.from ? range.from : event.target.value
            )
          })
        }
      />
    </div>
  );
};

export default ({ id, start, end, gymName, onSubmitComplete }) => {
  const [name, setName] = useState(undefined);
  const [group, setGroup] = useState(1);
  const [range, setRange] = useState({ from: 0, to: end - start });
  const [loading, setLoading] = useState(false);
  const displayNotification = useNotification();
  const intl = useIntl();
  return (
    <Loadable loading={loading}>
      <ChildSpacing>
        <NameNumberWrapper>
          <Input
            type={"text"}
            placeholder={intl.formatMessage(messages.name)}
            inverted
            onChange={event => setName(event.target.value)}
            required
            title={intl.formatMessage(messages.name)}
            tabindex={0}
          />
          <Number
            type={"number"}
            min={1}
            value={group}
            placeholder={intl.formatMessage(messages.group)}
            onChange={event => setGroup(event.target.value)}
            inverted
            required
            title={intl.formatMessage(messages.group)}
            tabindex={1}
          />
        </NameNumberWrapper>
        <RaidTimeSelector
          start={start}
          end={end}
          range={range}
          setRange={setRange}
          required
        />
        <Input
          type={"submit"}
          value={intl.formatMessage(messages.join)}
          inverted
          disabled={
            !name || !group || !range || range.from === undefined || !range.to
          }
          onClick={() => {
            fetch("https://pokeboxadvance.net/api/raid/join.php", {
              headers: new Headers(),
              method: "POST",
              mode: "cors",
              cache: "no-cache",
              body: JSON.stringify({
                raid: id,
                name,
                group,
                from: range.from,
                to: range.to
              })
            })
              .then(response => {
                if (response.ok && response.status === 200) {
                  return response.json();
                }
              })
              .then(json => {
                if (json.success) {
                  displayNotification({
                    success: true,
                    text: intl.formatMessage(messages.notification, {
                      gymName,
                      name,
                      group,
                      from: intl.formatTime(start + range.from),
                      to: intl.formatTime(start + range.to)
                    })
                  });
                  setName(undefined);
                  setRange({ from: start, to: end });
                  setLoading(false);
                  onSubmitComplete && onSubmitComplete();
                } else {
                  throw new Error(json.message);
                }
              })
              .catch(error => {
                setLoading(false);
                displayNotification({
                  success: false,
                  text: intl.formatMessage(messages.error, {
                    message: error.message
                  })
                });
              });
          }}
          required
          title={intl.formatMessage(messages.join)}
          tabindex={5}
        />
      </ChildSpacing>
    </Loadable>
  );
};
