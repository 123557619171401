import React from "react";

import Bulbasaur from "./Bulbasaur";
import Ivysaur from "./Ivysaur";
import Venusaur from "./Venusaur";
import Charmander from "./Charmander";
import Charmeleon from "./Charmeleon";
// import Charizard from "./Bulbasaur";
import Squirtle from "./Squirtle";
// import Wartortle from "./Bulbasaur";
// import Blastoise from "./Bulbasaur";
import Meowth from "./Meowth";
import Machamp from "./Machamp";
import Golem from "./Golem";
import Magneton from "./Magneton";
import Kingler from "./Kingler";
import Magikarp from "./Magikarp";
import Swinub from "./Swinub";
import Piloswine from "./Piloswine";
import Mamoswine from "./Mamoswine";
import Lugia from "./Lugia";
import Absol from "./Absol";
import Beldum from "./Beldum";
import Metang from "./Metang";
import Metagross from "./Metagross";
import Turtwig from "./Turtwig";
import Chimchar from "./Chimchar";
import Piplup from "./Piplup";
import Cobalion from "./Cobalion";

export const portrait = {
  1: <Bulbasaur />,
  2: <Ivysaur />,
  3: <Venusaur />,
  4: <Charmander />,
  5: <Charmeleon />,
  7: <Squirtle />,
  52: <Meowth />,
  68: <Machamp />,
  76: <Golem />,
  82: <Magneton />,
  99: <Kingler />,
  129: <Magikarp />,
  220: <Swinub />,
  221: <Piloswine />,
  222: <Mamoswine />,
  249: <Lugia />,
  359: <Absol />,
  374: <Beldum />,
  375: <Metang />,
  376: <Metagross />,
  387: <Turtwig />,
  390: <Chimchar />,
  393: <Piplup />,
  638: <Cobalion />
};

export {
  Bulbasaur,
  Ivysaur,
  Venusaur,
  Charmander,
  Charmeleon,
  Squirtle,
  Magikarp,
  Swinub,
  Piloswine,
  Mamoswine,
  Lugia,
  Beldum,
  Metang,
  Metagross,
  Turtwig,
  Chimchar,
  Piplup
};
