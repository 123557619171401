import React from "react";
import styled from "styled-components";
import { selectedSpace } from "../utility/styling";
import { space } from "../theme";

export default styled.div`
  ${({ noside }) =>
    selectedSpace({
      selectedPx: !noside && space("small"),
      selectedMb: space("small")
    })}
`;
