import React from "react";
import styled from "styled-components";
import { selectedSpace } from "../utility/styling";
import { space } from "../theme";
import Text from "./Text";

const Move = styled.div`
  width: 100%;
  p {
    float: left;
  }
  ${selectedSpace({ selectedP: space("small") })}
`;

export default ({ name, power, time, type }) => (
  <Move>
    <Text>{name}</Text>
    <Text>{power}</Text>
    <Text>{time}</Text>
    <Text>{type}</Text>
  </Move>
);
