import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c3 = shiny ? "#ffc753" : "#e75c4d";
  const c4 = shiny ? "#ffff9c" : "#f68dae";
  const c5 = shiny ? "#472c0e" : "#57313e";
  const c6 = shiny ? "#ffab41" : "#f7716e";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Magikarp Portrait"
      id="Magikarp_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Magikarp</title>
      <polygon
        fill={"#ead62d"}
        points="48.53 23.75 48.01 13.34 50.61 17.5 51.65 11.78 53.09 16.59 55.04 13.99 54.13 25.44 48.53 23.75"
      />
      <path
        d="M51.65,11.78l1.44,4.81L55,14l-.91,11.45-5.6-1.69L48,13.34l2.6,4.16,1-5.72M51.3,5.35l-1.12,6.16-.35,1.92-.55-.88L46.23,7.67l.29,5.74L47,23.82l.05,1.05,1,.31,5.59,1.69,1.78.54.15-1.85.91-11.45L56.94,9l-3.1,4.12-.17.23-.58-2-1.79-6Z"
        fill={outline}
      />
      <polygon
        fill={"#ead62d"}
        points="46.82 74.55 44.87 89.25 47.73 86.64 49.94 88.86 50.72 85.86 53.06 89.25 54.1 85.61 55.92 88.47 55.01 74.55 46.82 74.55"
      />
      <path
        d="M55,74.55l.92,13.92L54.1,85.6l-1,3.65-2.34-3.39-.78,3-2.21-2.21-2.86,2.6,2-14.7H55m1.4-1.5H45.51l-.17,1.3-2,14.7-.53,4,3-2.75,1.81-1.64,1.2,1.2,1.85,1.85.62-2.37.48.71,1.8,2.6.87-3,.13-.45,0,.06,3.14,4.92-.38-5.82-.91-13.92-.1-1.4Z"
        fill={outline}
      />
      <path
        d="M38.83,54.94c1.43.52.91,1.56.91,1.56S35.06,58.84,34,61.32s-1.83,15-3,17.56-6.24,9-13.53,8.72c9.24-2.74,10.93-7.29,12.1-9.37s1.43-15.35,2.73-18S37.53,54.94,38.83,54.94Z"
        fill={"#fff"}
      />
      <path
        d="M38.83,54.94c1.43.52.91,1.56.91,1.56S35.06,58.84,34,61.32s-1.83,15-3,17.56-6,8.72-13.1,8.72h-.43c9.24-2.74,10.93-7.29,12.1-9.37s1.43-15.35,2.73-18,5.21-5.33,6.51-5.33m.26-1.49h-.26c-1.38,0-3.2,1.25-4.17,2A13.53,13.53,0,0,0,31,59.61c-.74,1.47-1.09,4.73-1.59,9.93-.19,1.92-.36,3.73-.56,5.23a10.72,10.72,0,0,1-.55,2.73c-.11.19-.22.39-.33.61-1.14,2.14-3,5.73-10.89,8l-8.79,2.6,9.16.33h.48a15.25,15.25,0,0,0,9.72-3.75,18.08,18.08,0,0,0,4.75-5.86C33,78,33.47,74.88,34.1,69.83a56.82,56.82,0,0,1,1.29-7.93c.6-1.43,3.4-3.25,5-4.06l.44-.22.23-.45a2.55,2.55,0,0,0,.07-2,2.86,2.86,0,0,0-1.81-1.61l-.25-.09Z"
        fill={outline}
      />
      <path
        d="M63.38,55.46C62,56,62.47,57,62.47,57s4.68,2.34,5.72,4.82,1.83,15,3,17.56,6.24,9,13.53,8.72c-9.24-2.74-10.93-7.29-12.1-9.37s-1.43-15.35-2.73-18S64.68,55.46,63.38,55.46Z"
        fill={"#fff"}
      />
      <path
        d="M63.38,55.46c1.3,0,5.2,2.73,6.51,5.34s1.56,15.87,2.73,18,2.86,6.63,12.1,9.37h-.44c-7,0-12-6.17-13.09-8.72s-2-15.09-3-17.56S62.47,57,62.47,57s-.52-1,.91-1.56m0-1.49h-.26l-.25.09a2.86,2.86,0,0,0-1.81,1.61,2.55,2.55,0,0,0,.07,2l.23.45.44.22c1.62.81,4.42,2.63,5,4.06a56.82,56.82,0,0,1,1.29,7.93c.63,5.05,1.06,8.22,1.71,9.66a18.08,18.08,0,0,0,4.75,5.86,15.24,15.24,0,0,0,9.71,3.75h.49l9.16-.33-8.79-2.6c-7.85-2.32-9.75-5.91-10.89-8.05-.11-.22-.22-.42-.33-.61a10.72,10.72,0,0,1-.55-2.73c-.2-1.5-.38-3.31-.56-5.23-.5-5.2-.85-8.46-1.59-9.93A13.53,13.53,0,0,0,67.55,56c-1-.75-2.79-2-4.17-2Z"
        fill={outline}
      />
      <path
        d="M35.71,65.09a.66.66,0,0,1-.43-.16L21.1,52.81,2,48.68a.65.65,0,0,1-.5-.77.66.66,0,0,1,.78-.5l19.41,4.2L36.13,63.94a.66.66,0,0,1,.07.92A.65.65,0,0,1,35.71,65.09Z"
        fill={c3}
      />
      <path
        d="M2.14,47.39l.14,0,19.41,4.2L36.13,63.94a.66.66,0,0,1,.07.92.65.65,0,0,1-.92.07L21.1,52.81,2,48.68a.65.65,0,0,1-.5-.77.66.66,0,0,1,.64-.52m0-1.49a2.14,2.14,0,0,0-1.8,3.31,2.13,2.13,0,0,0,1.35.93l18.72,4,13.9,11.88a2.17,2.17,0,0,0,1.4.51,2.15,2.15,0,0,0,1.63-.75,2.1,2.1,0,0,0,.5-1.56,2.08,2.08,0,0,0-.74-1.46L22.66,50.47l-.28-.24L22,50.15,2.59,46a2.3,2.3,0,0,0-.45-.05Z"
        fill={outline}
      />
      <path
        d="M2.66,48.44S8.52,56.89,9,59.62s-.26,10.54-.26,10.54,13.27-6.5,25.76-6.37C22.7,53.9,21,52.21,21,52.21Z"
        fill={"#fff"}
      />
      <path
        d="M2.66,48.44,21,52.21s1.7,1.69,13.54,11.58h-.38c-12.38,0-25.38,6.37-25.38,6.37s.78-7.8.26-10.54S2.66,48.44,2.66,48.44M3,47,1.43,49.29s1.47,2.12,3,4.51C7,58,7.49,59.47,7.57,59.9,8,62,7.5,67.9,7.29,70L9.43,71.5a71.4,71.4,0,0,1,7.91-3.12,56.06,56.06,0,0,1,16.82-3.1h.36l1-2.64C24,53,22.13,51.22,22.06,51.15h0l-.75-.41L3,47Z"
        fill={outline}
      />
      <path
        d="M29.28,59.37a44.16,44.16,0,0,0-15.16-5.12A34.65,34.65,0,0,0,6.26,54l.47.78a34.63,34.63,0,0,1,7.28.27,44.4,44.4,0,0,1,20,8.33Z"
        fill={outline}
      />
      <path
        d="M33,62.51A45,45,0,0,0,14.55,62,36.91,36.91,0,0,0,9.2,63.36c0,.29,0,.58,0,.87a37.12,37.12,0,0,1,5.55-1.47,44.57,44.57,0,0,1,19.71,1Z"
        fill={outline}
      />
      <path
        d="M35.71,65.09a.66.66,0,0,1-.43-.16L21.1,52.81,2,48.68a.65.65,0,0,1-.5-.77.66.66,0,0,1,.78-.5l19.41,4.2L36.13,63.94a.66.66,0,0,1,.07.92A.65.65,0,0,1,35.71,65.09Z"
        fill={c3}
      />
      <path
        d="M63.29,64.31a.61.61,0,0,0,.42-.16L77.9,52,97,47.9a.65.65,0,0,0-.27-1.27L77.3,50.83,62.87,63.16a.65.65,0,0,0,.42,1.15Z"
        fill={c3}
      />
      <path
        d="M96.86,46.61a.66.66,0,0,1,.63.52.65.65,0,0,1-.5.77L77.9,52,63.71,64.15a.61.61,0,0,1-.42.16.65.65,0,0,1-.42-1.15L77.3,50.83l19.42-4.2.14,0m0-1.49a2.31,2.31,0,0,0-.46.05L77,49.37l-.37.08-.29.24L61.89,62a2.14,2.14,0,0,0,1.4,3.77,2.15,2.15,0,0,0,1.39-.51l13.9-11.88,18.73-4a2.14,2.14,0,0,0-.45-4.24Z"
        fill={outline}
      />
      <path
        d="M96.33,47.66S91.52,56,91,58.71s.91,9.89.91,9.89S77,62.88,64.46,63C76.3,53.12,78,51.43,78,51.43Z"
        fill={"#fff"}
      />
      <path
        d="M96.33,47.66S91.52,56,91,58.71s.91,9.89.91,9.89S77.28,63,64.88,63h-.42C76.3,53.12,78,51.43,78,51.43l18.34-3.77M96,46.19,77.69,50l-.75.41h0c-.08.07-1.93,1.88-13.44,11.49l1,2.64h.41c12,0,26.35,5.44,26.5,5.5l2-1.69C93,66.4,92.09,61,92.47,59c.34-1.81,3.35-7.45,5.16-10.59L96,46.19Z"
        fill={outline}
      />
      <path
        d="M84.88,53.46a44.37,44.37,0,0,0-15.16,5.13L65,62.6a44.32,44.32,0,0,1,20-8.32,34,34,0,0,1,8-.23l.39-.77A33.1,33.1,0,0,0,84.88,53.46Z"
        fill={outline}
      />
      <path
        d="M91.06,63.05a36.17,36.17,0,0,0-6.61-1.86A45.07,45.07,0,0,0,66,61.73l-1.43,1.19A44.64,44.64,0,0,1,84.26,62a35.45,35.45,0,0,1,6.91,2C91.13,63.66,91.09,63.36,91.06,63.05Z"
        fill={outline}
      />
      <path
        d="M63.29,64.31a.61.61,0,0,0,.42-.16L77.9,52,97,47.9a.65.65,0,0,0-.27-1.27L77.3,50.83,62.87,63.16a.65.65,0,0,0,.42,1.15Z"
        fill={c3}
      />
      <path
        d="M64.07,31c-3.9,8.72,5.46,14.83,6.24,14.44C71.61,44.4,71.48,30.35,64.07,31Z"
        fill={"#fff"}
      />
      <path
        d="M64.56,31c6.93,0,7,13.45,5.75,14.46,0,0,0,0-.09,0-1.24,0-9.94-6-6.15-14.46l.49,0m0-1.49c-.2,0-.41,0-.62,0l-.88.08-.36.8a11.38,11.38,0,0,0,.92,11.26,15.74,15.74,0,0,0,3.46,3.77A6.37,6.37,0,0,0,70.22,47a1.64,1.64,0,0,0,.76-.18l.14-.07.13-.1c.3-.24,1.21-1,1.19-4.74a21.13,21.13,0,0,0-1.07-6.56,10.4,10.4,0,0,0-2.37-4,6.14,6.14,0,0,0-4.44-1.84Z"
        fill={outline}
      />
      <path
        d="M39,30.61c3.9,8.72-5.47,14.83-6.25,14.44C31.41,44,31.54,30,39,30.61Z"
        fill={"#fff"}
      />
      <path
        d="M38.47,30.59l.49,0c3.78,8.45-4.91,14.46-6.15,14.46l-.1,0c-1.27-1-1.17-14.46,5.76-14.46m0-1.49h0A6.14,6.14,0,0,0,34,30.94a10.36,10.36,0,0,0-2.37,4,21.18,21.18,0,0,0-1.07,6.56c0,3.77.89,4.5,1.19,4.74l.12.1.15.07a1.64,1.64,0,0,0,.76.18A6.3,6.3,0,0,0,35.94,45a15.54,15.54,0,0,0,3.47-3.77A11.35,11.35,0,0,0,40.32,30L40,29.2l-.87-.08c-.21,0-.42,0-.62,0Z"
        fill={outline}
      />
      <ellipse cx="51.12" cy="50.26" fill={c3} rx="19.71" ry="26.41" />
      <path
        d="M51.12,23.85c10.89,0,19.71,11.82,19.71,26.41S62,76.67,51.12,76.67,31.41,64.84,31.41,50.26s8.83-26.41,19.71-26.41m0-1.5a17,17,0,0,0-8.41,2.27A22,22,0,0,0,36,30.69a29.5,29.5,0,0,0-4.45,8.84,36.34,36.34,0,0,0,0,21.46A29.61,29.61,0,0,0,36,69.83a21.94,21.94,0,0,0,6.72,6.06,16.71,16.71,0,0,0,16.82,0,22,22,0,0,0,6.73-6.06A29.61,29.61,0,0,0,70.71,61a36.34,36.34,0,0,0,0-21.46,29.5,29.5,0,0,0-4.45-8.84,22,22,0,0,0-6.73-6.07,16.91,16.91,0,0,0-8.41-2.27Z"
        fill={outline}
      />
      <ellipse cx="51.12" cy="50.26" fill={c3} rx="19.71" ry="26.41" />
      <path
        d="M43,75.75c-.06-.21-.19-.59,1.39-9,.77-4.12,1.58-8.19,1.59-8.23l.78.16c-1.21,6.09-3.1,16-3,16.86,0,0,0,0,0,0Z"
        fill={outline}
      />
      <path
        d="M58.25,75.81c-1.59-3.17-1.73-16.15-1.74-16.7h.8c0,.13.15,13.34,1.65,16.35Z"
        fill={outline}
      />
      <path
        d="M52.58,70.54A21.44,21.44,0,0,1,44.28,69l.33-.73h0a22.39,22.39,0,0,0,12.74,1l.17.78A24.1,24.1,0,0,1,52.58,70.54Z"
        fill={outline}
      />
      <path
        d="M64.12,31c-5.38-4.91-11.25-6.41-17.44-4.46A21.75,21.75,0,0,0,38.91,31l-.56-.57a22.11,22.11,0,0,1,8.06-4.63,17.25,17.25,0,0,1,8.43-.58,20.12,20.12,0,0,1,9.82,5.2Z"
        fill={outline}
      />
      <path
        d="M38.83,54.94c1.43.52.91,1.56.91,1.56S35.06,58.84,34,61.32s-1.83,15-3,17.56-6.24,9-13.53,8.72c9.24-2.74,10.93-7.29,12.1-9.37s1.43-15.35,2.73-18S37.53,54.94,38.83,54.94Z"
        fill={"#fff"}
      />
      <path
        d="M63.38,55.46C62,56,62.47,57,62.47,57s4.68,2.34,5.72,4.82,1.83,15,3,17.56,6.24,9,13.53,8.72c-9.24-2.74-10.93-7.29-12.1-9.37s-1.43-15.35-2.73-18S64.68,55.46,63.38,55.46Z"
        fill={"#fff"}
      />
      <path
        d="M41,53c0-1.3,3.25-5.86,5.07-6.9s10.8-.26,12.23.52,3.52,6.25,3.52,7.42-3.78,5.2-4.56,5.46-11.45.13-12.23-.26S41,54.29,41,53Z"
        fill={c4}
      />
      <path
        d="M43.38,52.88c0-1,2.52-4.24,3.93-5s8.37-.19,9.48.37,2.73,4.52,2.73,5.37S56.59,57.39,56,57.58a63.84,63.84,0,0,1-9.48-.19C45.9,57.11,43.38,53.82,43.38,52.88Z"
        fill={c5}
      />
      <path
        d="M43.45,52.55a1.22,1.22,0,0,0-.07.33c0,.94,2.52,4.23,3.13,4.51a63.84,63.84,0,0,0,9.48.19c.6-.19,3.53-3.11,3.53-3.95a4.61,4.61,0,0,0-.28-1.14,13.63,13.63,0,0,0-15.79.06Z"
        fill={c6}
      />
      <path
        d="M64.07,31c-3.9,8.72,5.46,14.83,6.24,14.44C71.61,44.4,71.48,30.35,64.07,31Z"
        fill={"#fff"}
      />
      <circle cx="69.08" cy="35.62" fill={outline} r="0.59" />
      <path
        d="M39,30.61c3.9,8.72-5.47,14.83-6.25,14.44C31.41,44,31.54,30,39,30.61Z"
        fill={"#fff"}
      />
      <circle cx="33.95" cy="35.23" fill={outline} r="0.59" />
    </SVG>
  );
};
