import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

import { selectedSpace } from "../../utility/styling";
import { colour, fontSize, headingSize, space, transition } from "../../theme";

import { portrait } from "../../assets/portrait";
import { tier } from "../../assets/raid";

import GymPhoto from "./GymPhoto";
import Join from "./Join";
import RaidTimer from "./RaidTimer";
import Popup from "../Popup";
import Report from "./Report";
import Flex from "../utility/Flex";
import Tabs from "../Tabs";
import Resolve from "./Resolve";
import Participants from "./Participants";
import Star from "../../assets/raid/Star";
import EX from "../../assets/icon/EX";
import Portrait from "../../assets/portrait/Portrait";
// import Text from "../Text";
// import PortraitAlternative from "./PortraitAlternative";

const messages = {
  report: {
    id: "raid.report",
    defaultMessage: "Report raid"
  },
  resolve: {
    id: "raid.resolve",
    defaultMessage: "Report Pokémon"
  },
  join: {
    id: "raid.join",
    defaultMessage: "Join raid"
  },
  participants: {
    id: "raid.participants",
    defaultMessage: "Participants"
  },
  details: {
    id: "raid.details",
    defaultMessage: "Details"
  }
};

const createArray = conditionals => {
  const result = [];
  conditionals.map(({ condition, value }) => condition && result.push(value));
  return result;
};

const Content = styled(Flex)`
  justify-content: center;
`;

const PhotoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 170px;
  min-height: 119px;
  align-self: center;
`;

const PortraitWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
  flex-wrap: wrap;
`;

const Timer = styled(RaidTimer)`
  width: 100%;
  ${({ space }) => space && "padding-left: 12%;"}
  ${({ space }) => !space && "text-align: center;"}
  ${fontSize("large")}
  ${selectedSpace({ selectedPt: space("tiniest") })}
`;

const PictureBase = styled.div`
  position: absolute;
  bottom: 0;
`;

// const OldPortrait = styled(PictureBase)`
//   width: 70%;
//   right: -30%;
// `;

const Egg = styled(PictureBase)`
  width: 62%;
  right: -25%;

  @keyframes attention {
    0% {
      transform: scale(1);
    }
    4% {
      transform: scale(1.1);
    }
    8% {
      transform: scale(1);
    }
    12% {
      transform: scale(1.1);
    }
    16% {
      transform: scale(1);
    }
  }

  animation: attention 5s infinite;
  animation-timing-function: ${transition("in")};
  animation-play-state: ${({ hatching }) => (hatching ? "running" : "paused")};
`;

const StarContainer = styled(Flex)`
  justify-content: center;
`;

const FlexStar = styled(Star)`
  flex: 0 0 16px;
  margin: 0 2px;
`;

const PageGroup = styled(Flex)`
  ${selectedSpace({ selectedMb: space("small") })}
`;

const StyledEX = styled(EX)`
  height: 1.2em;
  color: ${colour("main")};
  ${fontSize("heading.medium")}
`;

const StyledPortrait = styled(Portrait)`
  position: absolute;
  bottom: 0;
  width: 70%;
  right: -30%;
`;

export default ({ raid, gym, showing, dismiss, fetchSetRaids }) => {
  const pokemonPortrait = raid && portrait[raid.pokemon];
  const intl = useIntl();
  return (
    <Popup
      title={(gym && gym.name) || "Raid"}
      headerElements={gym && gym.ex && <StyledEX />}
      // headerElements={
      //   <>
      //     {gym && raid && navigator.share && (
      //       <div
      //         onClick={() =>
      //           navigator.share({
      //             title: `Raid at ${gym && gym.name}`,
      //             text: `${HoursMinutes.format(raid.start) -
      //               HoursMinutes.format(raid.end)}`
      //           })
      //         }
      //       >
      //         share
      //       </div>
      //     )}
      //   </>
      // }
      showing={showing}
      dismiss={dismiss}
    >
      <Content column>
        <PageGroup column>
          <PhotoWrapper>
            {gym && <GymPhoto $ {...gym} />}
            {raid && (
              <PortraitWrapper>
                {raid.pokemon ? (
                  // pokemonPortrait ? (
                  //   <Portrait>{pokemonPortrait}</Portrait>
                  // ) : (
                  //   <AlternativeText>
                  //     <PortraitAlternative
                  //       number={raid.pokemon}
                  //       form={raid.form}
                  //     />
                  //   </AlternativeText>
                  // )
                  <StyledPortrait number={raid.pokemon} form={raid.form} />
                ) : (
                  <Egg hatching={raid.start < Date.now()}>
                    {tier(raid.tier)}
                  </Egg>
                )}
                <Timer
                  space={!raid.pokemon && pokemonPortrait}
                  start={raid.start}
                  end={raid.end}
                />
              </PortraitWrapper>
            )}
          </PhotoWrapper>
          {raid && (
            <StarContainer>
              {new Array(raid.tier).fill(<FlexStar />)}
            </StarContainer>
          )}
        </PageGroup>
        <Tabs
          tabs={createArray([
            {
              condition: !raid,
              value: {
                title: intl.formatMessage(messages.report),
                content: (
                  <Report
                    {...gym && { id: gym.id, name: gym.name }}
                    onSubmitComplete={() => {
                      dismiss();
                      fetchSetRaids();
                    }}
                  />
                )
              }
            },
            {
              condition: raid,
              value: {
                title: intl.formatMessage(messages.participants),
                content: (
                  <Participants
                    {...raid && {
                      participants: raid.participants,
                      start: raid.start,
                      end: raid.end
                    }}
                  />
                )
              }
            },
            {
              condition: raid && !raid.pokemon && raid.start <= Date.now(),
              value: {
                title: intl.formatMessage(messages.resolve),
                content: (
                  <Resolve
                    {...raid && { raidId: raid.id, hatches: raid.hatches }}
                    gymName={gym && gym.name}
                    onSubmitComplete={() => {
                      dismiss();
                      fetchSetRaids();
                    }}
                  />
                )
              }
            },
            {
              condition: raid,
              value: {
                title: intl.formatMessage(messages.join),
                content: (
                  <Join
                    {...raid && {
                      id: raid.id,
                      start: raid.start,
                      end: raid.end
                    }}
                    gymName={gym && gym.name}
                    onSubmitComplete={() => {
                      dismiss();
                      fetchSetRaids();
                    }}
                  />
                )
              }
            }
          ])}
        />
      </Content>
    </Popup>
  );
};
