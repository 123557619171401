import React from "react";
import StyledSVG from "../../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 83 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.7474 47.8231C21.9374 47.8231 23.6649 20.0055 23.6649 20.0055C23.6649 20.0055 32.8271 25.7644 41.7474 25.7644C50.6678 25.7644 59.83 20.0055 59.83 20.0055C59.83 20.0055 61.5575 47.8231 41.7474 47.8231Z"
      stroke="currentcolor"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M81.5813 5.51322C77.5834 -1.17845 71.0501 5.64705 71.0501 5.64705C71.0501 5.64705 65.689 -3.10641 60.4113 3.66109C55.1336 10.4286 69.4743 23.6584 69.4743 23.6584C69.4743 23.6584 85.5792 12.2049 81.5813 5.51322Z"
      fill="currentcolor"
    />
    <path
      d="M23.1009 3.65967C18.0017 -2.2361 12.753 5.62019 12.753 5.62019C12.753 5.62019 5.95334 -2.06934 1.93098 5.5118C-2.09138 13.0929 14.3288 23.6316 14.3288 23.6316C14.3288 23.6316 28.2001 9.55544 23.1009 3.65967Z"
      fill="currentcolor"
    />
  </StyledSVG>
);
