import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.colour.light }) => {
  const c3 = shiny ? "#a7f1fc" : "#a2dbe9";
  const c4 = shiny ? "#f7ce7e" : "#febf21";
  const c0 = shiny ? "#55bbca" : "#438dcc";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Piplup Portrait"
      id="Piplup_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Piplup</title>
      <circle cx="50.37" cy="50" fill={c0} r="46.52" />
      <path
        d="M50.37,3.48A46.52,46.52,0,1,1,3.85,50,46.52,46.52,0,0,1,50.37,3.48m0-2.48A48.82,48.82,0,1,0,69.44,4.85,48.83,48.83,0,0,0,50.37,1Z"
        fill={outline}
      />
      <path
        d="M50.37,96.52A46.52,46.52,0,0,0,96.89,50a48,48,0,0,0-.42-6.15c-1.71-4.62-6.39-13.67-16.7-12.73-10.61,1-18.06,20.2-23.64,29.22a22.22,22.22,0,0,1-5.72,4.33,22.38,22.38,0,0,1-5.71-4.33c-5.58-9-13-28.25-23.64-29.22S5.78,39.8,4.2,44.26A47.31,47.31,0,0,0,3.85,50,46.52,46.52,0,0,0,50.37,96.52Z"
        fill={"#fff"}
      />
      <polygon
        fill={c3}
        points="33.99 41.45 40.67 73.57 60.59 74.2 67.26 40.82 57.15 48.44 50.05 38.42 43.59 48.44 33.99 41.45"
      />
      <circle cx="50.78" cy="71.5" fill={c4} r="11.16" />
      <path
        d="M61.9,72.5a17.33,17.33,0,0,1-15.48,3.17,23.13,23.13,0,0,1-6.73-3c0,.36.09.7.16,1.05a23.85,23.85,0,0,0,6.35,2.67,19.77,19.77,0,0,0,4.94.64,17.77,17.77,0,0,0,2.74-.21,18.47,18.47,0,0,0,7.87-3.26A10,10,0,0,0,61.9,72.5Z"
        fill={outline}
      />
      <ellipse
        cx="21.64"
        cy="60.53"
        fill={"#20365a"}
        rx="6.81"
        ry="13.01"
        transform="matrix(1, -0.07, 0.07, 1, -4.16, 1.65)"
      />
      <ellipse cx="21.56" cy="60.52" fill={outline} rx="3.06" ry="6.33" />
      <ellipse cx="20.93" cy="57.03" fill={"#fff"} rx="1.59" ry="1.47" />
      <ellipse
        cx="79.89"
        cy="60.53"
        fill={"#20365a"}
        rx="13.01"
        ry="6.81"
        transform="translate(13.95 136.02) rotate(-86.01)"
      />
      <ellipse cx="79.97" cy="60.52" fill={outline} rx="3.06" ry="6.33" />
      <ellipse cx="80.6" cy="57.03" fill={"#fff"} rx="1.59" ry="1.47" />
    </SVG>
  );
};
