import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

import { selectedSpace } from "../../utility/styling";
import { background, borderRadius, fontSize, space } from "../../theme";
import Text from "../Text";
import Trainer from "../../assets/icon/Trainer";

const messages = {
  noParticipants: {
    id: "raid.noParticipants",
    defaultMessage: "No participants"
  },
  time: {
    id: "raid.participant.time",
    defaultMessage: "{from} to {to}"
  }
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const CenteredText = styled(Text)`
  text-align: center;
  ${fontSize("heading.medium")}
`;

const BarContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: ${borderRadius()};
  ${background("light")}
  ${selectedSpace({ selectedMb: space("tinier") })}
`;

const Bar = styled.div`
  position: absolute;
  height: 100%;
  z-index: 0;
  width: calc(
    ${({ start, end, from, to }) => (to - from) / (end - start)} * 100%
  );
  margin-left: calc(
    ${({ start, end, from }) => (from - start) / (end - start)} * 100%
  );
  ${background("interact")}
`;

const ParticipantText = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  opacity: 0.9999;
  ${selectedSpace({ selectedPx: space("small"), selectedPy: space("tiny") })}
`;

const CenteredSpan = styled.span`
  display: flex;
  align-items: center;
`;

const Participant = ({ from, to, start, end, children, className }) => (
  <BarContainer className={className}>
    <Bar from={from} to={to} start={start} end={end} />
    {children}
  </BarContainer>
);

const findIdealStart = participants => {
  let start = 0;
  let maxCount = 0;
  for (const participant of participants) {
    let count = 0;
    for (const otherParticipant of participants) {
      if (
        otherParticipant.from <= participant.from &&
        participant.from <= otherParticipant.to
      ) {
        count++;
      }
    }
    if (count > maxCount || (count === maxCount && participant.from < start)) {
      maxCount = count;
      start = participant.from;
    }
  }
  return start;
};

const SuggestedStartWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: calc(${({ left }) => left} * 100%);
  ${selectedSpace({ selectedMb: space("tiny") })}
`;

const SuggestedStartMarker = styled.div`
  margin-left: -0.5em;
  ${fontSize("heading.large")}
`;

const SuggestedStartText = styled(Text)`
  ${fontSize("heading.small")}
`;

const StyledTrainer = styled(Trainer)`
  height: 1em;
`;

const SuggestedStart = ({ start, left }) => {
  const intl = useIntl();
  return (
    <SuggestedStartWrapper left={left}>
      <SuggestedStartMarker>🔥</SuggestedStartMarker>
      <SuggestedStartText>&nbsp;{intl.formatTime(start)}</SuggestedStartText>
    </SuggestedStartWrapper>
  );
};

export default ({ participants, start, end }) => {
  const intl = useIntl();
  const idealStart = findIdealStart(participants);
  return (
    <Wrapper>
      {participants.length === 0 ? (
        <CenteredText>
          {intl.formatMessage(messages.noParticipants)}
        </CenteredText>
      ) : (
        <>
          <SuggestedStart
            start={idealStart}
            left={(idealStart - start) / (end - start)}
          />
          {participants.map(({ name, from, to, group }, index) => (
            <Participant key={index} {...{ start, end, from, to }}>
              <ParticipantText>
                <CenteredSpan>
                  {name}
                  &nbsp;
                  {group && (
                    <>
                      +{group - 1}
                      &nbsp;
                      <StyledTrainer />
                      &nbsp;
                    </>
                  )}
                </CenteredSpan>
                <CenteredSpan>
                  {intl.formatMessage(messages.time, {
                    from: intl.formatTime(from),
                    to: intl.formatTime(to)
                  })}
                </CenteredSpan>
              </ParticipantText>
            </Participant>
          ))}
        </>
      )}
    </Wrapper>
  );
};
