import React from "react";
import styled from "styled-components";
import { colour } from "../../theme";

export default ({ className }) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <polygon
      fill="currentcolor"
      points="50,2.67 61.8,39 100,39 69.1,61.45 80.9,97.78 50,75.33 19.1,97.78 30.9,61.45 0,39 38.2,39"
    />
  </svg>
);
