import { css } from "styled-components";

import {
  color as _color,
  fontSize as _fontSize,
  textAlign as _textAlign,
  width as _width,
  height as _height,
  size as _size,
  space as _space,
  borderRadius as _borderRadius,
  maxWidth as _maxWidth,
  maxHeight as _maxHeight,
  top as _top,
  right as _right
} from "styled-system";

export const selectedColour = selectedColour => ({
  theme,
  color = selectedColour({ theme })
}) => _color({ color });

export const rawColour = rawColour => ({ color = rawColour }) =>
  _color({ color });

export const colour = colour =>
  typeof colour === "function" ? selectedColour(colour) : rawColour(colour);

export const selectedBg = selectedBg => ({
  theme,
  bg = selectedBg({ theme })
}) => _color({ bg });

export const rawBg = rawBg => ({ bg = rawBg }) => _color({ bg });

export const bg = bg => (typeof bg === "function" ? selectedBg(bg) : rawBg(bg));

export const selectedColours = (selectedColour, selectedBg) => ({
  theme,
  color = selectedColour({ theme }),
  bg = selectedBg({ theme })
}) => _color({ color, bg });

export const rawColors = (rawColor, rawBg) => ({
  color = rawColor,
  bg = rawBg
}) => _color({ color, bg });

export const colors = (color_, bg_) => ({
  theme,
  color = typeof color_ === "function" ? color_({ theme }) : color_,
  bg = typeof bg_ === "function" ? bg_({ theme }) : bg_
}) => _color({ color, bg });

export const selectedFontSize = selectedFontSize => ({
  theme,
  fontSize = selectedFontSize({ theme })
}) => _fontSize({ fontSize });

export const rawFontSize = rawFontSize => ({ fontSize = rawFontSize }) =>
  _fontSize({ fontSize });

export const fontSize = fontSize =>
  typeof fontSize === "function"
    ? selectedFontSize(fontSize)
    : rawFontSize(fontSize);

export const selectedTextAlign = selectedTextAlign => ({
  theme,
  textAlign = selectedTextAlign({ theme })
}) => _textAlign({ textAlign });

export const rawTextAlign = rawTextAlign => ({ textAlign = rawTextAlign }) =>
  _textAlign({ textAlign });

export const textAlign = textAlign =>
  typeof textAlign === "function"
    ? selectedTextAlign(textAlign)
    : rawTextAlign(textAlign);

export const selectedWidth = selectedWidth => ({
  theme,
  width = selectedWidth({ theme })
}) => _width({ width });

export const rawWidth = rawWidth => ({ width = rawWidth }) => _width({ width });

export const width = width =>
  typeof width === "function" ? selectedWidth(width) : rawWidth(width);

export const selectedHeight = selectedHeight => ({
  theme,
  height = selectedHeight({ theme })
}) => _height({ height });

export const rawHeight = rawHeight => ({ height = rawHeight }) =>
  _height({ height });

export const height = height =>
  typeof height === "function" ? selectedHeight(height) : rawHeight(height);

export const selectedSize = selectedSize => ({
  theme,
  size = selectedSize({ theme })
}) => _size({ size });

export const rawSize = rawSize => ({ theme, size = rawSize }) =>
  _size({ size });

export const selectedMaxWidth = selectedMaxWidth => ({
  theme,
  maxWidth = selectedMaxWidth({ theme })
}) => _maxWidth({ maxWidth });

export const rawMaxWidth = rawMaxWidth => ({ maxWidth = rawMaxWidth }) =>
  _maxWidth({ maxWidth });

export const maxWidth = maxWidth =>
  typeof maxWidth === "function"
    ? selectedMaxWidth(maxWidth)
    : rawMaxWidth(maxWidth);

export const selectedMaxHeight = selectedMaxHeight => ({
  theme,
  maxHeight = selectedMaxHeight({ theme })
}) => _maxHeight({ maxHeight });

export const rawMaxHeight = rawMaxHeight => ({ maxHeight = rawMaxHeight }) =>
  _maxHeight({ maxHeight });

export const maxHeight = maxHeight =>
  typeof maxHeight === "function"
    ? selectedMaxHeight(maxHeight)
    : rawMaxHeight(maxHeight);

// export const selectedMaxSize = selectedMaxSize => ({
//   theme,
//   maxSize = selectedMaxSize({ theme })
// }) => _maxSize({ maxSize });
//
// export const rawMaxSize = rawMaxSize => ({ theme, maxSize = rawMaxSize }) =>
//   _maxSize({ maxSize });

export const selectedSpace = ({
  selectedM,
  selectedMt,
  selectedMr,
  selectedMb,
  selectedMl,
  selectedMx,
  selectedMy,
  selectedP,
  selectedPt,
  selectedPr,
  selectedPb,
  selectedPl,
  selectedPx,
  selectedPy
}) => ({
  theme,
  m = selectedM ? selectedM({ theme }) : selectedM,
  mt = selectedMt ? selectedMt({ theme }) : selectedMt,
  mr = selectedMr ? selectedMr({ theme }) : selectedMr,
  mb = selectedMb ? selectedMb({ theme }) : selectedMb,
  ml = selectedMl ? selectedMl({ theme }) : selectedMl,
  mx = selectedMx ? selectedMx({ theme }) : selectedMx,
  my = selectedMy ? selectedMy({ theme }) : selectedMy,
  p = selectedP ? selectedP({ theme }) : selectedP,
  pt = selectedPt ? selectedPt({ theme }) : selectedPt,
  pr = selectedPr ? selectedPr({ theme }) : selectedPr,
  pb = selectedPb ? selectedPb({ theme }) : selectedPb,
  pl = selectedPl ? selectedPl({ theme }) : selectedPl,
  px = selectedPx ? selectedPx({ theme }) : selectedPx,
  py = selectedPy ? selectedPy({ theme }) : selectedPy
}) =>
  _space({
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py
  });

export const selectedBorderRadius = selectedBorderRadius => ({
  theme,
  borderRadius = selectedBorderRadius({ theme })
}) => _borderRadius({ borderRadius });

export const rawBorderRadius = rawBorderRadius => ({
  borderRadius = rawBorderRadius
}) => _borderRadius({ borderRadius });

export const borderRadius = borderRadius =>
  typeof borderRadius === "function"
    ? selectedBorderRadius(borderRadius)
    : rawBorderRadius(borderRadius);

export const selectedTop = selectedTop => ({
  theme,
  top = selectedTop({ theme })
}) => _top({ top });

export const rawTop = rawTop => ({ top = rawTop }) => _top({ top });

export const top = top =>
  typeof top === "function" ? selectedTop(top) : rawTop(top);

export const selectedRight = selectedRight => ({
  theme,
  right = selectedRight({ theme })
}) => _right({ right });

export const rawRight = rawRight => ({ right = rawRight }) => _right({ right });

export const right = right =>
  typeof right === "function" ? selectedRight(right) : rawRight(right);
