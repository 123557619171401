import React from "react";
import styled from "styled-components";
import Flex from "../utility/Flex";
import Section from "../Section";
import ActionButton from "./ActionButton";
import { rawWidth } from "../../utility/styling";

const BlockFlex = styled(Flex)`
  float: left;
  padding: 0.5rem;
  ${rawWidth([
    "calc(100% / 2)",
    "calc(100% / 3)",
    "calc(100% / 4)",
    "calc(100% / 5)"
  ])}

  & * {
    float: left;
  }
`;

const StyledFlex = styled(Flex)`
  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`;

export default ({ category, icon, actions, ...props }) => (
  <BlockFlex column {...props}>
    <StyledFlex align={"center"}>
      {icon}
      <Section>{category}</Section>
    </StyledFlex>
    <Flex wrap>
      {actions.map((action, index) => (
        <ActionButton key={index} action={action} m={"0.25rem"} />
      ))}
    </Flex>
  </BlockFlex>
);
