import React from "react";
import StyledSVG from "../../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 84 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2125 27.3156C20.2125 27.3156 25.2162 37.8963 41.7479 37.8963C58.2797 37.8963 63.2834 27.3156 63.2834 27.3156"
      stroke="currentcolor"
      stroke-width="9"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9774 8.06904C19.1761 9.84151 17.3925 11.0749 15.3209 11.0749C12.5001 11.0749 10.2134 8.78816 10.2134 5.96736C10.2134 3.89575 11.4468 2.1122 13.2192 1.31093C12.2689 1.01873 11.2596 0.86145 10.2134 0.86145C4.57271 0.86145 0 5.43416 0 11.0749C0 16.7156 4.57271 21.2883 10.2134 21.2883C15.8541 21.2883 20.4268 16.7156 20.4268 11.0749C20.4268 10.0287 20.2696 9.01934 19.9774 8.06904Z"
      fill="currentcolor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.0474 8.06904C82.2461 9.84152 80.4625 11.0749 78.3909 11.0749C75.5701 11.0749 73.2834 8.78817 73.2834 5.96737C73.2834 3.89575 74.5168 2.1122 76.2893 1.31094C75.339 1.01873 74.3296 0.86145 73.2834 0.86145C67.6427 0.86145 63.07 5.43416 63.07 11.0749C63.07 16.7156 67.6427 21.2883 73.2834 21.2883C78.9241 21.2883 83.4968 16.7156 83.4968 11.0749C83.4968 10.0287 83.3396 9.01935 83.0474 8.06904Z"
      fill="currentcolor"
    />
  </StyledSVG>
);
