import React, { useState } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import "leaflet/dist/leaflet.css";
import { Map as _Map, Popup as MapPopup, TileLayer } from "react-leaflet";
import {
  anchor,
  borderRadius,
  color,
  colour,
  fontSize,
  h,
  input,
  shadow,
  space
} from "../../theme";
import Raid from "./Raid";
import Input from "../Input";
import { useIntl } from "react-intl";
import {
  selectedBorderRadius,
  selectedColour,
  selectedFontSize,
  selectedSpace,
  selectedWidth
} from "../../utility/styling";
import _GymPhoto from "./GymPhoto";
import _Loading from "../Loading";
import Refresh from "../../assets/icon/Refresh";
import Heading from "../Heading";
import RoundButton from "../RoundButton";
import Flex from "../utility/Flex";

const messages = {
  tier: {
    id: "tier",
    defaultMessage: "Tier"
  },
  gyms: {
    id: "gyms",
    defaultMessage: "Gyms"
  }
};

const Map = styled(_Map)`
  width: 100%;
  height: 80vh;
`;

const PopupFix = createGlobalStyle`
  .leaflet-popup, .leaflet-popup-content-wrapper, .leaflet-popup-content {
    font-size: 15px;
    padding: 0;
    margin: 0 !important;
    border-radius: 0px !important;
    background: none !important;
    box-shadow: none !important;
    cursor: pointer;
  }
  
  .leaflet-popup {
    filter: drop-shadow(${shadow("mapItem")});
  }
  
  .leaflet-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
    
  .leaflet-popup-content svg {
    width: 50px;
    margin-bottom: 5px;
  }
    
  .leaflet-popup-content p {
    margin: 0 !important;
  }
  
  .leaflet-popup-tip {
    box-shadow: none !important;
  }
  
  .leaflet-control-zoom {
    border: none !important;
    box-shadow: ${shadow("mapControl")} !important;
  }
`;

const StyledMapPopup = styled(MapPopup)`
  .leaflet-popup-content {
    color: ${({ ex }) => (ex ? colour("main") : colour("light"))};
  }

  .leaflet-popup-tip {
    background: ${({ ex }) => ex && colour("main")} !important;
  }
`;

const GymPhoto = styled(_GymPhoto)`
  height: 35px;
  width: 35px;
  overflow: hidden;
`;

const Loading = styled(_Loading)`
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  z-index: 10000000;
  width: 3rem;
  ${selectedSpace({ selectedM: space("small") })}
`;

const gymRadius = 0.005;
const raidRadius = 0.02;

const MapControl = styled.div`
  position: absolute;
  z-index: 10000000;
  background: white;
  box-shadow: ${shadow("mapControl")};
  ${fontSize("text")}
  ${selectedColour(colour("light"))}
  ${selectedBorderRadius(borderRadius())}
  ${selectedSpace({ selectedP: space("tinier"), selectedM: space("small") })}
`;

const mapControl = css`
  position: absolute;
  z-index: 10000000;
  box-shadow: ${shadow("mapControl")};
  ${color("light")}
  ${selectedSpace({ selectedM: space("small") })}
`;

const Filter = styled(MapControl)`
  display: flex;
  flex-wrap: wrap;
  bottom: 0;
  left: 0;
  width: auto;
  max-width: calc(100% - 40px);
  font-family: Lato, sans-serif;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  white-space: nowrap;
  ${anchor}
  ${h}
  ${selectedSpace({ selectedP: space("tinier") })}
`;

const RefreshElement = styled(RoundButton)`
  ${mapControl}
  top: 0;
  right: 0;
`;

export default ({
  raids,
  lat = 47.56558,
  lng = 7.57336,
  center = [47.56558, 7.57336 /*47.567643, 7.578096*/],
  zoom = 16,
  setFeatured,
  loading,
  navigate,
  refreshRaids
}) => {
  const [showTiers, setShowTiers] = useState({
    1: true,
    2: true,
    3: true,
    4: true,
    5: true
  });
  const [showGyms, setShowGyms] = useState(true);
  const intl = useIntl();
  return (
    <>
      <Map
        center={[lat, lng]}
        zoom={zoom}
        onViewportChanged={viewport => {
          viewport &&
            navigate(
              `/raid/${viewport.center[0] || 0}/${viewport.center[1] ||
                0}/${viewport.zoom || 16}/`,
              { replace: true }
            );
        }}
      >
        {/*<TileLayer*/}
        {/*  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'*/}
        {/*  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/}
        {/*/>*/}
        <TileLayer
          attribution="Map tiles by Carto, under CC BY 3.0. Data by OpenStreetMap, under ODbL."
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />
        {loading && <Loading />}
        {raids &&
          raids.map(({ gym, raid }, index) => {
            return raid
              ? lat - raidRadius < gym.location[0] &&
                  gym.location[0] < lat + raidRadius &&
                  lng - raidRadius < gym.location[1] &&
                  gym.location[1] < lng + raidRadius &&
                  showTiers[raid.tier] && (
                    <StyledMapPopup
                      key={index}
                      position={gym.location}
                      ex={gym.ex}
                      autoClose={false}
                      closeButton={false}
                      closeOnEscapeKey={false}
                      closeOnClick={false}
                      autoPan={false}
                    >
                      <Raid
                        key={index}
                        {...raid}
                        raid={raid}
                        exRaid={raid.ex}
                        exGym={gym.ex}
                        setFeatured={() => setFeatured({ gym, raid })}
                      />
                    </StyledMapPopup>
                  )
              : lat - gymRadius < gym.location[0] &&
                  gym.location[0] < lat + gymRadius &&
                  lng - gymRadius < gym.location[1] &&
                  gym.location[1] < lng + gymRadius &&
                  showGyms && (
                    <StyledMapPopup
                      key={index}
                      position={gym.location}
                      ex={gym.ex}
                      autoClose={false}
                      closeButton={false}
                      closeOnEscapeKey={false}
                      closeOnClick={false}
                      autoPan={false}
                    >
                      <GymPhoto
                        key={index}
                        {...gym}
                        onClick={() => setFeatured({ gym })}
                      />
                    </StyledMapPopup>
                  );
          })}
        {!loading && (
          // <RefreshElement onClick={refreshRaids}>
          //   <Refresh />
          // </RefreshElement>

          <RefreshElement fontSize={"small"} onClick={refreshRaids}>
            &#x27F2;
          </RefreshElement>
        )}
        <Filter>
          <Label>
            {intl.formatMessage(messages.gyms)}
            <Input
              type={"checkbox"}
              checked={showGyms}
              onChange={event => setShowGyms(event.target.checked)}
            />
          </Label>
          <Label>
            {intl.formatMessage(messages.tier)} 1
            <Input
              type={"checkbox"}
              checked={showTiers[1]}
              onChange={event =>
                setShowTiers({ ...showTiers, 1: event.target.checked })
              }
            />
          </Label>
          <Label>
            {intl.formatMessage(messages.tier)} 2
            <Input
              type={"checkbox"}
              checked={showTiers[2]}
              onChange={event =>
                setShowTiers({ ...showTiers, 2: event.target.checked })
              }
            />
          </Label>
          <Label>
            {intl.formatMessage(messages.tier)} 3
            <Input
              type={"checkbox"}
              checked={showTiers[3]}
              onChange={event =>
                setShowTiers({ ...showTiers, 3: event.target.checked })
              }
            />
          </Label>
          <Label>
            {intl.formatMessage(messages.tier)} 4
            <Input
              type={"checkbox"}
              checked={showTiers[4]}
              onChange={event =>
                setShowTiers({ ...showTiers, 4: event.target.checked })
              }
            />
          </Label>
          <Label>
            {intl.formatMessage(messages.tier)} 5
            <Input
              type={"checkbox"}
              checked={showTiers[5]}
              onChange={event =>
                setShowTiers({ ...showTiers, 5: event.target.checked })
              }
            />
          </Label>
        </Filter>
      </Map>
      <PopupFix />
    </>
  );
};
