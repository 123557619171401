import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c3 = shiny ? "#d6d89b" : "#d9d0a3";
  const c4 = shiny ? "#7ed0bc" : "#71c1da";
  const c6 = shiny ? "#cfa39a" : "#cfa39a";
  const c0 = shiny ? "#a7aa39" : "#926e57";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Mamoswine Portrait"
      id="Mamoswine_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Mamoswine</title>
      <path
        d="M92,52.83C84.62,20.6,63.87,11.54,50.09,11.42c-13.79.12-34.54,9.18-41.9,41.41a32,32,0,0,0-.81,5.75c.85,20.13,21.84,25,34.19,26.19,4,.36,7.1.38,8.52.38s4.55,0,8.51-.38C71,83.59,91.94,78.71,92.79,58.58A32,32,0,0,0,92,52.83Z"
        fill={c0}
      />
      <path
        d="M50.09,11.42C63.87,11.54,84.62,20.6,92,52.83a32,32,0,0,1,.81,5.75C91.94,78.71,71,83.59,58.6,84.77c-4,.36-7.1.38-8.51.38s-4.56,0-8.52-.38C29.22,83.59,8.23,78.71,7.38,58.58a32,32,0,0,1,.81-5.75C15.55,20.6,36.3,11.54,50.09,11.42m0-2h0a38.44,38.44,0,0,0-12,2.09,41.71,41.71,0,0,0-12.64,7A49,49,0,0,0,14.2,31.77,71.59,71.59,0,0,0,6.25,52.39a34,34,0,0,0-.85,6.1v.17A25.2,25.2,0,0,0,9.71,72.13a28,28,0,0,0,9.48,8.38,47,47,0,0,0,11.46,4.43,71.41,71.41,0,0,0,10.73,1.81h0c4.24.38,7.59.39,8.69.39s4.46,0,8.7-.39h0a71.41,71.41,0,0,0,10.73-1.81A47,47,0,0,0,81,80.51a28,28,0,0,0,9.48-8.38,25.2,25.2,0,0,0,4.31-13.47v-.17a34,34,0,0,0-.85-6.1,71.59,71.59,0,0,0-8-20.62A49,49,0,0,0,74.69,18.54a41.71,41.71,0,0,0-12.64-7A38.44,38.44,0,0,0,50.1,9.44Z"
        fill={outline}
      />
      <path
        d="M62.77,18.81c-6.18,0-7.83,4.29-12.68,4.63H50c-4.85-.34-6.5-4.63-12.67-4.63s-23.7,16.53-23.7,21.6,5.18,11.13,9.81,11.13S50,36.77,50,36.77l0-.08,0,.08S72,51.54,76.65,51.54s9.81-6.06,9.81-11.13S68.94,18.81,62.77,18.81Z"
        fill={"#d2d8dc"}
      />
      <path
        d="M50,32.69c-5.51,0-14.11,8.93-16.31,9.81s-11.13,1.43-11.13,1.88,6.72.88,6.5,1.54-11,4-11,4.74,5.4,1.32,5.4,1.76-6.94,4.85-6.61,5.51,6.06-2.64,6.61-1.87-2.53,7.38-2.09,7.49c0,0,5.18-4.74,5.07-3.52s-.89,6.83-.67,7.27,3-3.75,3.31-3.31-1.32,7.28-.88,7.61,3.08-5.51,3.42-5.4-.22,6.17.11,6.5,3.08-4.74,3.74-4.41,3.86,8.16,4.52,7,1.76-5.51,2.21-5.18.88,4.52,1.21,4.63,1.65-3.52,2-3.3,1.43,4,2.1,4,1.65-3.52,2.09-3.19S51,76,51.68,75.9s2.87-4.74,3.31-4.74,4.08,5.62,4.85,5.62,2-6.62,2.53-6.73,5.51,4.08,5.85,3.75S66,67.74,66,67.3s5.18,2.2,5.51,1.76S69.54,64.32,70,64s4.19,2.43,4.52,1.88-2.43-3.31-2.21-3.75,5.29,1.1,5.51.77-4.74-5-4.85-5.51,5.52.77,5.52.55-4.74-3.31-4.85-3.64,4.85.45,4.85,0-6.18-4-6.18-4,3.09-1.11,3.2-1.66-6.72-1-7.16-1.32,4.29-2.75,4-3.2-2.64.34-5.18-1S55.54,32.69,50,32.69Z"
        fill={c3}
      />
      <path
        d="M66.65,24.87C58.72,24.87,53,29.61,50,29.61h-.09c-3,0-8.71-4.74-16.64-4.74s-10.8,8-10.8,11.79,1.65,6.39,8.48,6.39,11.55-9.75,19-9.8,12.19,9.8,19,9.8,8.49-2.64,8.49-6.39S74.59,24.87,66.65,24.87Z"
        fill={c4}
      />
      <path
        d="M31.69,27.08a7.22,7.22,0,0,0-4.36,4.28c-1.39,3.55,0,7.43,3.19,8.66s6.84-.64,8.23-4.19A7.69,7.69,0,0,0,39.27,33Z"
        fill={outline}
      />
      <path
        d="M33.88,28.79a4.49,4.49,0,0,0-4.44,3,4.33,4.33,0,0,0,2.11,5.71A4.32,4.32,0,0,0,37,34.73a4.78,4.78,0,0,0,0-3.49Z"
        fill={"#fff"}
      />
      <ellipse cx="35.48" cy="33.41" fill={outline} rx="0.66" ry="1.49" />
      <path
        d="M67.37,27.08a7.21,7.21,0,0,1,4.37,4.28c1.39,3.55,0,7.43-3.19,8.66s-6.84-.64-8.23-4.19A7.91,7.91,0,0,1,59.79,33Z"
        fill={outline}
      />
      <path
        d="M65.19,28.79a4.49,4.49,0,0,1,4.44,3,4.07,4.07,0,1,1-7.53,2.94,4.84,4.84,0,0,1-.05-3.49Z"
        fill={"#fff"}
      />
      <ellipse cx="63.59" cy="33.41" fill={outline} rx="0.66" ry="1.49" />
      <path
        d="M50.12,36.11H50c-6.28,0-11.13,17.08-9.31,19.12S47.28,55,50,55h.09c2.76,0,7.5,2.31,9.32.27S56.4,36.11,50.12,36.11Z"
        fill={c6}
      />
      <path
        d="M45.29,51a.42.42,0,0,1-.3-.12.43.43,0,0,1,0-.62c1.65-1.75,2.08-5.07,2.08-6.76a.45.45,0,0,1,.89,0c0,2.26-.61,5.54-2.33,7.36A.43.43,0,0,1,45.29,51Z"
        fill={outline}
      />
      <path
        d="M54.88,51a.45.45,0,0,1-.32-.14c-1.72-1.82-2.33-5.1-2.33-7.36a.45.45,0,0,1,.89,0c0,1.69.44,5,2.08,6.76a.43.43,0,0,1,0,.62A.42.42,0,0,1,54.88,51Z"
        fill={outline}
      />
      <path
        d="M18.84,74.57S8.48,53.74,6.39,54.29-4.19,83.06,10.13,87.14s18-11.24,10.69-15.32-5,0-5,0Z"
        fill={"#d2d8dc"}
      />
      <path
        d="M6.47,54.28c1.58,0,6.91,9.75,10,15.74h-.2c-1.47,0-.44,1.81-.44,1.81l3,2.75s-.95-1.91-2.33-4.55a12.62,12.62,0,0,1,4.31,1.8c6.44,3.6,4.34,16-6.14,16a16.74,16.74,0,0,1-4.55-.68C-4.19,83.06,4.29,54.85,6.39,54.29h.08m0-2a2.33,2.33,0,0,0-.59.08c-1,.25-1.68,1.06-2.47,2.79a32.59,32.59,0,0,0-1.54,4.36A49.45,49.45,0,0,0,0,72,24,24,0,0,0,1.85,82.27a13,13,0,0,0,3,4.16,11.7,11.7,0,0,0,4.75,2.62,18.79,18.79,0,0,0,5.09.75,12,12,0,0,0,7.63-2.51,11,11,0,0,0,3.77-5.87,11.1,11.1,0,0,0-.19-6.34,8.94,8.94,0,0,0-4.1-5,20.48,20.48,0,0,0-3.95-1.82c-1.46-2.77-3.43-6.4-5.23-9.39A47.12,47.12,0,0,0,9.84,54.7c-1.1-1.44-2.09-2.4-3.37-2.4Z"
        fill={outline}
      />
      <path
        d="M18.84,74.57S8.48,53.74,6.39,54.29-4.19,83.06,10.13,87.14s18-11.24,10.69-15.32-5,0-5,0Z"
        fill={"#d2d8dc"}
      />
      <path
        d="M81,74.57S91.37,53.74,93.47,54.29s10.58,28.77-3.75,32.85S71.76,75.9,79,71.82s5,0,5,0Z"
        fill={"#d2d8dc"}
      />
      <path
        d="M93.38,54.28h.09c2.09.56,10.58,28.77-3.75,32.85a16.67,16.67,0,0,1-4.54.68c-10.48,0-12.58-12.4-6.15-16A12.64,12.64,0,0,1,83.35,70C82,72.66,81,74.57,81,74.57l3-2.75S85,70,83.55,70h-.2c3.12-6,8.45-15.74,10-15.74m0-2c-1.28,0-2.27,1-3.37,2.4a47.12,47.12,0,0,0-2.77,4.18c-1.8,3-3.76,6.62-5.22,9.39a20.24,20.24,0,0,0-4,1.82,8.94,8.94,0,0,0-4.1,5,11.19,11.19,0,0,0-.19,6.34,11,11,0,0,0,3.77,5.87,12,12,0,0,0,7.64,2.51,18.77,18.77,0,0,0,5.08-.75A11.81,11.81,0,0,0,95,86.43a13,13,0,0,0,3-4.16A24,24,0,0,0,99.85,72,49,49,0,0,0,98,59.53a31.24,31.24,0,0,0-1.54-4.36c-.79-1.73-1.51-2.54-2.47-2.79a2.33,2.33,0,0,0-.59-.08Z"
        fill={outline}
      />
      <path
        d="M81,74.57S91.37,53.74,93.47,54.29s10.58,28.77-3.75,32.85S71.76,75.9,79,71.82s5,0,5,0Z"
        fill={"#d2d8dc"}
      />
    </SVG>
  );
};
