import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.colour.light }) => {
  const c3 = shiny ? "#c5f057" : "#f0f060";
  const c4 = shiny ? "#84ce73" : "#60c871";
  const c2 = shiny ? "#95dcc2" : "#aedb74";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Turtwig Portrait"
      id="Turtwig_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Turtwig</title>
      <path
        d="M49.33,37.7s-.69-21.48,0-21.83,1.74-.69,2.08,0,3.12,23.22,3.12,23.22Z"
        fill={"#b28a4c"}
      />
      <path
        d="M50.68,15.47a.74.74,0,0,1,.73.4c.35.69,3.12,23.22,3.12,23.22l-5.2-1.39s-.69-21.48,0-21.83a3.34,3.34,0,0,1,1.35-.4m0-2.49h0a5.57,5.57,0,0,0-2.46.66,2.72,2.72,0,0,0-1.45,2.08,8.58,8.58,0,0,0-.12,1c0,.65-.08,1.52-.1,2.67,0,1.85,0,4.4,0,7.57.09,5.37.26,10.77.26,10.82l.06,1.86,1.79.47,5.2,1.39,3.57,1L57,38.79c0-.06-.71-5.71-1.44-11.37-.42-3.33-.78-6-1-8-.58-4.09-.67-4.28-.88-4.69a3.21,3.21,0,0,0-3-1.78Z"
        fill={outline}
      />
      <path
        d="M51,35.21h-.32c-26.58.32-24.22,40.39-22.24,46.31s9.89,4.35,12.66,6.33,6.47,4.56,9.58,4.73H51c3.11-.17,6.9-2.81,9.58-4.73s10.69-.4,12.67-6.33S77.61,35.53,51,35.21Z"
        fill={c2}
      />
      <path
        d="M51,35.21c26.58.32,24.22,40.39,22.25,46.31s-9.9,4.35-12.67,6.33S54.14,92.41,51,92.58h-.32c-3.11-.17-6.9-2.81-9.58-4.73s-10.68-.4-12.66-6.33-4.34-46,22.24-46.31H51m0-2.49h-.36a23.2,23.2,0,0,0-11,2.74,22.71,22.71,0,0,0-7.55,6.85c-5,7-6.61,16.47-7.08,23.15-.5,7.35.17,14.17,1.06,16.85a8.74,8.74,0,0,0,4.8,5.28,21.32,21.32,0,0,0,5.62,1.49,11.59,11.59,0,0,1,3.16.8c3.53,2.52,7.28,5,10.9,5.19h.59c3.62-.2,7.36-2.67,10.89-5.19a11.57,11.57,0,0,1,3.17-.8,21.26,21.26,0,0,0,5.61-1.49,8.74,8.74,0,0,0,4.8-5.28c.89-2.68,1.57-9.5,1.06-16.85-.46-6.68-2.06-16.11-7.08-23.15a22.52,22.52,0,0,0-7.55-6.85,23.17,23.17,0,0,0-11-2.74Z"
        fill={outline}
      />
      <ellipse cx="51.03" cy="79.93" fill={c3} rx="30.48" ry="17.02" />
      <path
        d="M51,62.91c16.83,0,30.48,7.62,30.48,17S67.86,97,51,97s-30.48-7.62-30.48-17,13.65-17,30.48-17m0-2.49c-8.56,0-16.65,1.88-22.77,5.3A23.18,23.18,0,0,0,21,71.8a12.85,12.85,0,0,0,0,16.26,23.2,23.2,0,0,0,7.3,6.09c6.12,3.41,14.21,5.3,22.77,5.3s16.64-1.89,22.77-5.3a23.31,23.31,0,0,0,7.3-6.09,12.85,12.85,0,0,0,0-16.26,23.29,23.29,0,0,0-7.3-6.08c-6.13-3.42-14.21-5.3-22.77-5.3Z"
        fill={outline}
      />
      <path
        d="M72,4.44C50.14,11.71,53.26,31.81,53.26,31.81S78.21,27.65,72,4.44Z"
        fill={c4}
      />
      <path
        d="M72,4.44c6.24,23.21-18.71,27.37-18.71,27.37S50.14,11.71,72,4.44M73.7,1.23l-2.51.84a31.75,31.75,0,0,0-13.15,8,26.2,26.2,0,0,0-6,10A28.28,28.28,0,0,0,50.8,32.19l.39,2.5,2.48-.42c.51-.08,12.62-2.2,18.6-11.48a20.27,20.27,0,0,0,3-8.22,27.65,27.65,0,0,0-.89-10.78L73.7,1.23Z"
        fill={outline}
      />
      <path
        d="M25,9.91C47.92,12.1,49.4,32.38,49.4,32.38S24.16,33.94,25,9.91Z"
        fill={c4}
      />
      <path
        d="M25,9.91C47.92,12.1,49.4,32.38,49.4,32.38s-.33,0-.92,0C44,32.4,24.25,31.18,25,9.91m-2.4-2.73-.1,2.64c-.26,7.35,1.73,13.28,5.9,17.6a24,24,0,0,0,11.44,6.36,35.09,35.09,0,0,0,8.62,1.12c.65,0,1,0,1.08,0l2.51-.15-.18-2.52a28.38,28.38,0,0,0-4-11.52,26.1,26.1,0,0,0-8.07-8.42A31.76,31.76,0,0,0,25.25,7.43l-2.63-.25Z"
        fill={outline}
      />
      <path
        d="M49.33,37.7s-.69-21.48,0-21.83,1.74-.69,2.08,0,3.12,23.22,3.12,23.22Z"
        fill={"#b28a4c"}
      />
      <path
        d="M51,35.21h-.32c-26.58.32-24.22,40.39-22.24,46.31s9.89,4.35,12.66,6.33,6.47,4.56,9.58,4.73H51c3.11-.17,6.9-2.81,9.58-4.73s10.69-.4,12.67-6.33S77.61,35.53,51,35.21Z"
        fill={c2}
      />
      <ellipse cx="32.42" cy="71.42" fill={c3} rx="2.77" ry="10.89" />
      <ellipse cx="32.23" cy="72.02" fill={"#6b532e"} rx="1.78" ry="5.94" />
      <ellipse cx="32.23" cy="70.04" fill={"#fff"} rx="0.99" ry="2.77" />
      <ellipse cx="70.03" cy="71.42" fill={c3} rx="2.77" ry="10.89" />
      <ellipse cx="70.23" cy="72.02" fill={"#6b532e"} rx="1.78" ry="5.94" />
      <ellipse cx="70.23" cy="70.04" fill={"#fff"} rx="0.99" ry="2.77" />
      <path
        d="M47.47,74a.4.4,0,0,1-.37-.25l-1.59-4a.4.4,0,1,1,.74-.3l1.59,4a.4.4,0,0,1-.23.52A.32.32,0,0,1,47.47,74Z"
        fill={outline}
      />
      <path
        d="M53.8,74a.4.4,0,0,1-.37-.55l1.58-4a.41.41,0,0,1,.52-.22.4.4,0,0,1,.22.52l-1.58,4A.4.4,0,0,1,53.8,74Z"
        fill={outline}
      />
      <path
        d="M69.88,47.62c-3.43-7-9.26-12.29-18.85-12.41h-.32c-10.09.12-16,6-19.36,13.51,1.68-1,9.43-5.64,11.16-5.79,1.94-.16,6.63,4.37,8.09,4.37s6.79-4.53,7.93-4.53C59.36,42.77,66.32,46,69.88,47.62Z"
        fill={"#b28a4c"}
      />
    </SVG>
  );
};
