export default {
  lightest: "#8080c3",
  light: "#4d4d75",
  main: "#2c2c43",
  dark: "#151520",
  type: {
    normal: "#bcbd9c",
    fire: "#fe9141",
    water: "#5d8afe",
    electric: "#feeb4c",
    grass: "#66da52",
    ice: "#c5f7f8",
    fighting: "#ed392f",
    poison: "#c343c1",
    ground: "#e3d1a2",
    flying: "#bcbefe",
    psychic: "#fe5387",
    bug: "#b2c12f",
    rock: "#a49248",
    ghost: "#705898",
    dragon: "#8353fd",
    dark: "#32302c",
    steel: "#bdbed2",
    fairy: "#f8b6e2"
  }
};
