import React from "react";
import Page from "../component/Page";
import Loading from "../component/Loading";
import Heading from "../component/Heading";
import Flex from "../component/utility/Flex";

export default ({ navigate }) => {
  navigator.geolocation.getCurrentPosition(
    position =>
      navigate(
        `/raid/${position.coords.latitude}/${position.coords.longitude}/16`,
        { replace: true }
      ),
    () => navigate(`/raid/0/0/3`, { replace: true })
  );
  return (
    <Page>
      <Flex justify={"center"}>
        <Loading />
      </Flex>
      <Flex justify={"center"}>
        <Heading>Determinig your position</Heading>
      </Flex>
    </Page>
  );
};
