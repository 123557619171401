import React from "react";
import styled from "styled-components";

import Page from "../component/Page";
import Heading from "../component/Heading";
import Text from "../component/Text";
import { anchor, fontSize, h } from "../theme";

const Link = styled.a`
  ${anchor}
`;

const List = styled.ul`
  ${h}
  ${fontSize("small")}
`;

export default props => (
  <Page title={"Privacy"} marginedHeader {...props}>
    <Heading>Cookies</Heading>
    <Text>
      Parts of this web app use cookies to provide functionality. Please
      remember that you are in control! If you don't want any cookies to be set,
      change the relevant settings in your browser. Currently, we use cookies
      to:
      <List>
        <li>Remember that you've accepted cookies</li>
        <li>Remember your buddy interaction history</li>
      </List>
    </Text>
    <Heading>Data that you submit</Heading>
    <Text>
      Data that you enter and submit through this web app is stored in a
      database. This includes:
      <List>
        <li>Your trainer name</li>
        <li>Participation times</li>
        <li>Gym IDs</li>
        <li>Raid IDs</li>
        <li>Raid times</li>
        <li>Pokédex numbers</li>
      </List>
    </Text>
    <Text>
      All of this data is continuously deleted and is part of this web app's
      data flow.
    </Text>
    <Heading>Our service providers</Heading>
    <Text>
      This data is stored in a database on our{" "}
      <Link href={"https://www.novatrend.ch"}>web hosting provider</Link>'s
      servers. Their privacy conditions apply.
    </Text>
  </Page>
);
