import React from "react";
import styled from "styled-components";

import { selectedFontSize, selectedSpace } from "../utility/styling";
import { space } from "../theme";

import FontWeightNormal from "./utility/FontWeightNormal";
import { themeGet } from "styled-system";

export default styled(FontWeightNormal.withComponent("h1"))`
  ${selectedFontSize(themeGet("fontSize.heading.large"))}
  ${selectedSpace({ my: space("large") })}
`;
