import React from "react";
import styled from "styled-components";
import Button from "./Button";

export default styled(Button)`
  justify-content: center;
  height: 0;
  width: 0;
  padding: 1em;
`;
