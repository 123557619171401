import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c4 = shiny ? "#70b566" : "#1e7272";
  const c0 = shiny ? "#9cd68a" : "#6dc99e";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Bulbasaur Portrait"
      id="Bulbasaur_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Bulbasaur</title>
      <path
        d="M93.21,39.23a23,23,0,0,0-1.71-3.95c1.48-6.72,4.31-22,.26-24.8-3.73-2.62-15.12,3-21.28,6.46a21.11,21.11,0,0,0-3-.83c-9.85-1.9-12.08-2-17.46-2h-.74c-5.38,0-7.61.08-17.47,2a21.8,21.8,0,0,0-3.54,1C21.87,13,12.77,7.79,9.53,9.57,5.06,12,6.6,31.24,7.11,36.52A20.11,20.11,0,0,0,6,39.23c-3,9.58-2.13,24-2.13,24,0,13.58,10.19,21.9,23.73,24.94a107.88,107.88,0,0,0,44,0c13.54-3,23.73-11.36,23.73-24.94C95.34,63.26,96.25,48.81,93.21,39.23Z"
        fill={c0}
      />
      <path
        d="M11.19,9.21c4.05,0,11.56,4.34,17,7.91a21.8,21.8,0,0,1,3.54-1c9.86-1.9,12.09-2,17.47-2H50c5.38,0,7.61.08,17.46,2a21.11,21.11,0,0,1,3,.83c5.07-2.81,13.65-7.13,18.63-7.13a4.54,4.54,0,0,1,2.65.67c4,2.83,1.22,18.08-.26,24.8a23,23,0,0,1,1.71,3.95c3,9.58,2.13,24,2.13,24,0,13.58-10.19,21.9-23.73,24.94a102.15,102.15,0,0,1-22,2.26,102.08,102.08,0,0,1-22-2.26C14.09,85.16,3.9,76.84,3.9,63.26c0,0-.91-14.45,2.13-24a20.11,20.11,0,0,1,1.08-2.71C6.6,31.24,5.06,12,9.53,9.57a3.4,3.4,0,0,1,1.66-.36m0-2.53h0a5.87,5.87,0,0,0-2.88.67C5.44,8.93,4.08,13.42,3.92,21.94c-.1,5.48.35,11.31.6,14.14a24.32,24.32,0,0,0-.9,2.38C.55,48.11,1.29,62,1.37,63.34a25.66,25.66,0,0,0,2,10.11,24.81,24.81,0,0,0,5.54,8,31.88,31.88,0,0,0,8.16,5.71,44,44,0,0,0,10,3.47A104.76,104.76,0,0,0,49.62,93a104.6,104.6,0,0,0,22.54-2.32,44,44,0,0,0,10-3.47,31.76,31.76,0,0,0,8.17-5.71,25,25,0,0,0,5.54-8,25.84,25.84,0,0,0,2-10.11c.07-1.32.81-15.23-2.25-24.88a23.92,23.92,0,0,0-1.46-3.54,106.18,106.18,0,0,0,2.07-13.31,34.44,34.44,0,0,0-.09-7.37c-.41-2.84-1.37-4.75-2.93-5.84a7.06,7.06,0,0,0-4.1-1.12c-2.57,0-5.93.88-10.26,2.71-3.15,1.32-6.29,2.93-8.63,4.2a23.19,23.19,0,0,0-2.29-.57c-9.93-1.91-12.38-2-17.84-2h-.84c-5.53,0-8,.1-17.94,2a22.93,22.93,0,0,0-2.73.71,93.77,93.77,0,0,0-8.27-4.77c-3.89-1.94-6.88-2.88-9.12-2.88Z"
        fill={outline}
      />
      <path
        d="M17.28,39.07c5.33-.6,20.84,22.52,16.89,25.56s-22.06.91-23.12-.61S12,39.68,17.28,39.07Z"
        fill={"#fff"}
      />
      <path
        d="M82.9,39.07C77.57,38.47,62.06,61.59,66,64.63s22.06.91,23.12-.61S88.22,39.68,82.9,39.07Z"
        fill={"#fff"}
      />
      <path
        d="M79,41C72.41,46.48,62.8,62.16,66,64.63c2.3,1.76,9.37,1.79,15.1,1.2C81.87,58.71,82.55,46.39,79,41Z"
        fill={"#d1334c"}
      />
      <path
        d="M71.28,53.75c0,4.5.68,8.14,1.52,8.14s1.52-3.64,1.52-8.14a27.21,27.21,0,0,0-.72-6.93c-.72.94-1.44,1.92-2.12,2.92A37.3,37.3,0,0,0,71.28,53.75Z"
        fill={"#fff"}
      />
      <path
        d="M17.13,39.07C22.46,38.47,38,61.59,34,64.63S12,65.54,10.9,64,11.81,39.68,17.13,39.07Z"
        fill={"#fff"}
      />
      <path
        d="M21.24,41c6.58,5.51,16.19,21.19,13,23.66-2.3,1.76-9.37,1.79-15.1,1.2C18.36,58.71,17.68,46.39,21.24,41Z"
        fill={"#d1334c"}
      />
      <path
        d="M29,53.75c0,4.5-.68,8.14-1.52,8.14s-1.52-3.64-1.52-8.14a27.21,27.21,0,0,1,.72-6.93c.73.94,1.44,1.92,2.12,2.92A37.3,37.3,0,0,1,29,53.75Z"
        fill={"#fff"}
      />
      <path
        d="M11.76,74.44c1.17,1.73,3.39,2.49,5.31,3a45.83,45.83,0,0,0,8.49,1.29c5.11.4,10.23.3,15.35.39l.92,0,.31,0c.17,0-.2-.07,0,0,1.6.56,2.89,1.88,4.34,2.74a7.54,7.54,0,0,0,2.61,1,4.8,4.8,0,0,0,2.59-.38,29.24,29.24,0,0,0,4.48-2.93,7.52,7.52,0,0,1,.91-.54,2,2,0,0,1,.6-.2c.19,0,.38,0,.57,0l2.32.17c2.62.18,5.24.3,7.86.29A56.1,56.1,0,0,0,78,78.69a18.51,18.51,0,0,0,7.85-2.88,9.26,9.26,0,0,0,1.36-1.22c.65-.72-.42-1.8-1.07-1.07-1.66,1.85-4.2,2.74-6.61,3.31a43.84,43.84,0,0,1-9.13,1c-2.79.07-5.58,0-8.36-.18-1,0-1.91-.12-2.86-.2a11.5,11.5,0,0,0-1.55-.08c-1.5.12-2.85,1.43-4,2.27s-2.68,2-4.24,1.79c-1.82-.3-3.35-1.73-4.83-2.73a5.31,5.31,0,0,0-2.64-1.09c-1.73-.11-3.48-.05-5.21-.06-2.79,0-5.58,0-8.37-.19a65.16,65.16,0,0,1-8.59-.91,26.08,26.08,0,0,1-3.58-.91,9.39,9.39,0,0,1-1.3-.55q-.3-.15-.6-.33L14,74.52l-.13-.09-.07-.05a5.22,5.22,0,0,1-.43-.39l-.17-.19s-.11-.14,0,0l-.1-.14c-.54-.8-1.86,0-1.31.77Z"
        fill={outline}
      />
      <path
        d="M42.15,27c0,1.07,3.05,10.8,3.81,11.26s5.47.61,6.23,0,11.87-9,12.17-10.19-2.13-6.09-3.8-6.39S42.15,25.92,42.15,27Z"
        fill={c4}
      />
      <path
        d="M37.55,38.48a2.46,2.46,0,0,0-1.33,2.66c.29,1,4.14,5.32,5.17,5.62s2.66-2.81,2.66-3.7a8.18,8.18,0,0,0-1.62-3.84C41.84,38.77,38.44,38,37.55,38.48Z"
        fill={c4}
      />
      <path
        d="M54.17,42.5c0,.76.61,4.71,1.06,5s4.57-1.22,5.18-2.13,1.06-4.57.3-5.33S54.17,41.74,54.17,42.5Z"
        fill={c4}
      />
      <path
        d="M66.65,43.48a38.55,38.55,0,0,0-4.6,8.43,9,9,0,0,0-.65,3,.61.61,0,0,0,1.21,0,12.45,12.45,0,0,1,1.74-5.18,48.36,48.36,0,0,1,2.72-4.66c.2-.32.41-.64.63-.95.44-.65-.61-1.26-1-.62Z"
        fill={outline}
      />
      <path
        d="M31.67,44.1a38.12,38.12,0,0,1,4.43,8,8,8,0,0,1,.65,2.76.61.61,0,0,0,1.22,0,13.32,13.32,0,0,0-1.77-5.52,49.31,49.31,0,0,0-2.81-4.88c-.22-.34-.44-.67-.68-1-.44-.64-1.49,0-1,.62Z"
        fill={outline}
      />
      <path
        d="M43.78,72.51l2.43,2.74c.52.58,1.38-.28.86-.86l-2.43-2.74c-.52-.58-1.38.28-.86.86Z"
        fill={outline}
      />
      <path
        d="M55.18,71.65l-2.43,2.74c-.52.58.34,1.44.86.86L56,72.51c.52-.58-.33-1.45-.86-.86Z"
        fill={outline}
      />
    </SVG>
  );
};
