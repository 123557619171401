import React from "react";

export default ({ className }) => (
  <svg
    viewBox="0 0 100 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="50" cy="30.1101" r="29.9139" fill="currentcolor" />
    <path
      d="M31.9144 66.192C28.0794 65.1929 24.531 64.2684 19.1936 64.2684C8.50486 64.2684 1.23124 74.7489 0.142037 89.8034C-0.017379 92.0068 1.79086 93.8037 4 93.8037H96C98.2091 93.8037 100.017 92.0068 99.858 89.8034C98.7688 74.7489 91.4951 64.2684 80.8064 64.2684C75.469 64.2684 71.9206 65.1929 68.0856 66.192C63.5771 67.3666 58.6726 68.6443 50 68.6443C41.3273 68.6443 36.4229 67.3666 31.9144 66.192Z"
      fill="currentcolor"
    />
  </svg>
);
