import { createGlobalStyle, css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import colours from "./assets/colours";
import MontserratBlack from "./assets/fonts/Montserrat/Montserrat-Black.ttf";
import MontserratRegular from "./assets/fonts/Montserrat/Montserrat-Regular.ttf";
import MuliVariable from "./assets/fonts/Muli/Muli-VariableFont:wght.ttf";
import PoppinsBlack from "./assets/fonts/Poppins/Poppins-Black.ttf";
import PoppinsBold from "./assets/fonts/Poppins/Poppins-Bold.ttf";
import LatoRegular from "./assets/fonts/Lato/Lato-Regular.ttf";
import { selectedFontSize } from "./utility/styling";

const theme = {
  colour: {
    main: "#2c2c43",
    light: "#4d4d75",
    lightest: "#8080c3",
    medium: "#232338",
    dark: "#151520",
    accent: colours.type.dragon,
    interact: colours.type.psychic,
    success: colours.type.grass,
    error: colours.type.fighting,
    disabled: colours.type.steel,
    type: {
      normal: "#bcbd9c",
      fire: "#fe9141",
      water: "#5d8afe",
      electric: "#feeb4c",
      grass: "#66da52",
      ice: "#c5f7f8",
      fighting: "#ed392f",
      poison: "#c343c1",
      ground: "#e3d1a2",
      flying: "#bcbefe",
      psychic: "#fe5387",
      bug: "#b2c12f",
      rock: "#a49248",
      ghost: "#705898",
      dragon: "#8353fd",
      dark: "#32302c",
      steel: "#bdbed2",
      fairy: "#f8b6e2"
    }
  },
  fontSize: {
    root: [10, 12, 14, 16],
    large: "2.5rem",
    medium: "2rem",
    small: "1.5rem",
    text: "1rem",
    massive: [80, 90, 110, 120],
    heading: {
      large: "2.5rem",
      medium: "2rem",
      small: "1.5rem",
      smaller: [16, 17, 18, 19],
      tiny: [],
      tinier: []
    }
  },
  borderRadius: "3px",
  space: {
    inputMatch: [31, 38, 46, 55],
    massive: [30, 40, 68, 80],
    larger: [20, 30, 50, 60],
    large: [18, 22, 24, 26],
    medium: [15, 17, 19, 21],
    small: [12, 14, 16, 18],
    smaller: [10, 12, 14, 16],
    tiny: ["8px", "10px", 12, 14],
    tinier: ["4px", "5px", "7px", "8px"],
    tiniest: ["3px", "4px", "5px", "6px"]
  },
  portrait: {
    outline: "#4d4d75"
  },
  breakpoints: {
    sm: 50,
    md: 60,
    lg: 70
  },
  width: {
    popup: [
      "calc(100% - 40px)",
      "calc(100% - 60px)",
      "calc(100% - 100px)",
      "calc(100% - 120px)"
    ],
    raid: [110, 130, 150, 170]
  },
  shadow: {
    low: "0 0 10px #0009",
    high: "0 0 50px #000f",
    mapItem: "0 1px 0 #0005",
    mapControl: "0 1px 2px #0005",
    popup: "0 1px 6px #0005"
  },
  transition: {
    in: "cubic-bezier(0,1,0.5,1)",
    out: "cubic-bezier(0.5,0,1,0.5)"
  }
};

export const colour = key => ({ theme }) => theme.colour && theme.colour[key];

export const color = key => props =>
  `color: ${themeGet(`colour.${key}`)(props)};`;

export const background = key => ({ theme }) =>
  `background: ${theme.colour && theme.colour[key]};`;

export const type = key => ({ theme }) => theme.colour.type[key];

export const width = key => ({ theme }) => theme.width[key];

export const space = key => ({ theme }) => theme.space && theme.space[key];

export const fontSize = fontSize => props =>
  `font-size: ${themeGet(`fontSize.${fontSize}`)(props)};`;

export const headingSize = key => ({ theme }) =>
  theme.fontSize && theme.fontSize.heading && theme.fontSize.heading[key];

export const borderRadius = () => ({ theme }) => theme.borderRadius;

export const shadow = key => ({ theme }) => theme.shadow && theme.shadow[key];

export const transition = key => ({ theme }) =>
  theme.transition && theme.transition[key];

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: Montserrat;
  src: ${MontserratBlack};
  font-weight: 900;
}

@font-face {
  font-family: Muli;
  src: ${MuliVariable};
}

@font-face {
  font-family: Poppins;
  src: ${PoppinsBlack};
  font-weight: 900;
}

@font-face {
  font-family: Lato;
  src: ${LatoRegular};
  font-weight: normal;
}

html, body {
  margin: 0;
  color: white;
  font-family: Lato, sans-serif;
  background-color: ${colour("dark")};
  ${selectedFontSize(themeGet("fontSize.root"))}
}

* {
  box-sizing: border-box;
}

a, label, button, input[type="submit"], input[type="button"] {
  cursor: pointer;
}
`;

const Poppins = css`
  font-family: Poppins;
  font-weight: 900;
  letter-spacing: -0.03em;
  word-spacing: 0.2em;
`;

const Muli = css`
  font-family: Muli;
  font-weight: 900;
  letter-spacing: -0.03em;
  word-spacing: 0.1em;
`;

export const h = css`
  ${Muli}
  text-transform: uppercase;
`;

export const button = css`
  /*border-width: 5px;*/
  /*border-style: outset;*/
  padding: 0.75em 1.25em;
  border-radius: 100vw;
  cursor: pointer;
  user-select: none;
  border: none;
  transition: background 50ms linear, border-color 50ms linear,
    color 50ms linear, transform 200ms ${transition("in")};
  ${h}

  :hover,
  :focus {
    color: white;
    border-color: ${colour("accent")};
    ${background("accent")}
  }

  :active {
    color: white;
    transform: scale(0.9);
    border-color: ${colour("interact")};
    ${background("interact")}
  }
`;

export const anchor = css`
  transition: color 50ms linear, transform 200ms ${transition("in")};
  color: inherit;

  :hover,
  :focus {
    ${color("accent")}
  }
  :active {
    ${color("interact")}
  }
`;

export const input = css`
  transition: color 50ms linear, background-color 50ms linear,
    border-color 50ms linear;

  :hover,
  :focus,
  :active,
  :invalid,
  :disabled {
    color: white;
  }

  :hover:not(:disabled) {
    border-color: ${colour("accent")} !important;
    background: ${colour("accent")} !important;
  }

  :focus:not(:disabled),
  :active:not(:disabled) {
    border-color: ${colour("interact")} !important;
    background: ${colour("interact")} !important;
  }

  :invalid:not(:disabled):not(:placeholder-shown) {
    border-color: ${colour("error")};
    background: ${colour("error")};
    box-shadow: none;
  }

  :disabled {
    border-color: ${colour("disabled")};
    background: ${colour("disabled")};
    cursor: not-allowed;
  }
`;

export default theme;
