import React from "react";
import StyledSVG from "../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 101 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M74.201 10.2209C83.0479 10.2209 90.2198 17.3928 90.2198 26.2398C90.2198 29.3341 89.3425 32.2235 87.8229 34.6728C90.6293 35.3303 93.2928 36.753 95.4808 38.941C101.737 45.1968 101.737 55.3394 95.4808 61.5951C93.2928 63.7831 90.6293 65.2059 87.8229 65.8633C89.3425 68.3126 90.2198 71.202 90.2198 74.2964C90.2198 83.1433 83.0479 90.3152 74.201 90.3152C71.1066 90.3152 68.2172 89.4379 65.7679 87.9183C65.1105 90.7247 63.6877 93.3882 61.4997 95.5763C55.2439 101.832 45.1014 101.832 38.8456 95.5763C36.6576 93.3882 35.2349 90.7247 34.5774 87.9184C32.1281 89.4379 29.2387 90.3152 26.1444 90.3152C17.2974 90.3152 10.1255 83.1433 10.1255 74.2964C10.1255 71.202 11.0029 68.3126 12.5224 65.8633C9.716 65.2059 7.05249 63.7831 4.86447 61.5951C-1.39129 55.3394 -1.39129 45.1968 4.86447 38.941C7.05249 36.753 9.716 35.3303 12.5224 34.6728C11.0029 32.2235 10.1255 29.3341 10.1255 26.2398C10.1255 17.3928 17.2974 10.2209 26.1444 10.2209C29.2387 10.2209 32.1281 11.0983 34.5774 12.6178C35.2349 9.81141 36.6576 7.1479 38.8456 4.95988C45.1014 -1.29587 55.2439 -1.29587 61.4997 4.95988C63.6877 7.1479 65.1105 9.81141 65.7679 12.6178C68.2172 11.0983 71.1066 10.2209 74.201 10.2209ZM34.1538 50.2681C34.1538 59.115 41.3257 66.2869 50.1727 66.2869C59.0196 66.2869 66.1915 59.115 66.1915 50.2681C66.1915 41.4211 59.0196 34.2492 50.1727 34.2492C41.3257 34.2492 34.1538 41.4211 34.1538 50.2681Z"
      fill="currentcolor"
    />
  </StyledSVG>
);
