import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";

import Page from "../component/Page";
import RaidMap from "../component/raid/RaidMap";
import Featured from "../component/raid/Featured";
import Help from "../component/raid/Help";
import RoundButton from "../component/RoundButton";
import Flex from "../component/utility/Flex";

import { useNotification } from "../component/NotificationProvider";
import { fontSize } from "../theme";

const messages = {
  error: {
    id: "notification.error",
    defaultMessage: "Something went wrong: {message}"
  }
};

const Beta = styled.span`
  align-self: start;
  text-transform: lowercase;
  font-style: italic;
  font-weight: normal;
  margin-left: 0.25rem;
  ${fontSize("small")}
`;

export default ({ lat, lng, zoom, navigate, ...props }) => {
  const [raids, setRaids] = useState(undefined);
  const [help, setHelp] = useState(false);
  const [featuring, setFeaturing] = useState(false);
  const [featured, setFeatured] = useState(undefined);
  const [loading, setLoading] = useState(raids === undefined);
  const displayNotification = useNotification();
  const fetchSetRaids = () => {
    setLoading(true);
    fetch(
      `https://pokeboxadvance.net/api/raid/raids.php?lat=${lat}&lng=${lat}`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: { "Content-Type": "application/json" }
      }
    )
      .then(response => {
        if (response.ok && response.status === 200) {
          return response.json();
        }
      })
      .then(json => {
        setRaids(json);
        setLoading(false);
      })
      .catch(error => {
        displayNotification({
          success: false,
          text: intl.formatMessage(messages.error, { message: error.message })
        });
        setRaids([]);
        setRaids([
          {
            gym: {
              id: 6,
              name: "Zwerg Nase, 1950",
              location: [47.565685, 7.582211],
              ex: true
            },
            raid: {
              tier: 2,
              start: 1569418560000,
              end: 1569421260000,
              hatches: [[103, 1], [200, 2], 215, 302, 303],
              participants: [],
              pokemon: 68
            }
          },
          {
            gym: {
              id: 4,
              name: "Zur Landskron",
              location: [47.568667, 7.577579]
            },
            raid: {
              tier: 1,
              start: 1569418680000,
              end: 1569421380000,
              hatches: [403, 425, 504, 599],
              participants: []
            }
          },
          {
            gym: {
              id: 1,
              name: "Wandvelo-A cycle on Wall",
              location: [47.569152, 7.576699]
            },
            raid: {
              tier: 3,
              start: 1569418680000,
              end: 1569421380000,
              hatches: [403, 425, 504, 599],
              participants: []
            }
          },
          {
            gym: {
              id: 2,
              name: "Stairs to Heaven",
              location: [47.570378, 7.573225]
            },
            raid: {
              tier: 4,
              start: 1569418680000,
              end: 1569421380000,
              hatches: [403, 425, 504, 599],
              participants: []
            }
          },
          {
            gym: {
              id: 3,
              name: "Der Basler Riese",
              location: [47.566967, 7.570951],
              ex: true
            },
            raid: {
              tier: 5,
              start: 1569418680000,
              end: 1569421380000,
              hatches: [403, 425, 504, 599],
              participants: [
                {
                  name: "AL4CR1TY",
                  from: 1569420680000,
                  to: 1569421120000,
                  group: 3
                },
                { name: "Trainer", from: 1569419000000, to: 1569421120000 },
                { name: "Trainer", from: 1569418680000, to: 1569421000000 },
                { name: "Trainer", from: 1569418800000, to: 1569421000000 },
                { name: "Trainer", from: 1569418980000, to: 1569421120000 },
                { name: "Anonymous", from: 1569420680000, to: 1569421120000 }
              ]
            }
          },
          {
            gym: {
              id: 5,
              name: "Color Wall Els\u00e4sserstr.",
              location: [47.570168, 7.578698]
            }
          },
          {
            gym: {
              id: 7,
              name: "Johanneskirche",
              location: [47.565676, 7.574643]
            }
          },
          {
            gym: {
              id: 8,
              name: "Bronzechildren 3",
              location: [47.565703, 7.579019]
            }
          },
          {
            gym: {
              id: 9,
              name: "St. Johanns Ring Antique House",
              location: [47.563276, 7.576102]
            }
          },
          {
            gym: {
              id: 11,
              name: "The Last Unicorn",
              location: [47.571887, 7.577087]
            }
          },
          {
            gym: {
              id: 12,
              name: "Basilisk Kannenfeldpark",
              location: [47.564434, 7.569019],
              ex: true
            }
          },
          {
            gym: {
              id: 13,
              name: "City Jungle Mural",
              location: [47.570965, 7.569539]
            }
          },
          {
            gym: {
              id: 14,
              name: "Antoniuskirche",
              location: [47.56413, 7.573071]
            }
          },
          {
            gym: {
              id: 15,
              name: "Heavy Weight Art",
              location: [47.568227, 7.583044],
              ex: true
            }
          }
        ]);
        setLoading(false);
      });
  };
  useEffect(() => {
    !raids && fetchSetRaids();
  });
  const intl = useIntl();
  return (
    <Page
      title={"Raids"}
      heading={
        <>
          Raids
          <Beta>&beta;</Beta>
        </>
      }
      headerElements={
        <Flex justify={"end"}>
          <RoundButton
            fontSize={"small"}
            onClick={() => {
              setFeaturing(false);
              setHelp(true);
            }}
          >
            ?
          </RoundButton>
        </Flex>
      }
      {...props}
    >
      <Featured
        {...featured}
        showing={featuring}
        dismiss={() => setFeaturing(false)}
        onExited={() => setFeatured(undefined)}
        fetchSetRaids={fetchSetRaids}
      />
      <Help showing={help} dismiss={() => setHelp(false)} />
      <RaidMap
        {...{
          raids,
          lat: parseFloat(lat),
          lng: parseFloat(lng),
          zoom,
          loading,
          navigate,
          refreshRaids: fetchSetRaids
        }}
        setFeatured={featured => {
          setHelp(false);
          setFeatured(featured);
          setFeaturing(true);
        }}
      />
    </Page>
  );
};
