import React from "react";
import styled from "styled-components";

import Tier1 from "./Tier1";
import Tier2 from "./Tier2";
import Tier3 from "./Tier3";
import Flex from "../../component/utility/Flex";
import { colour } from "../../theme";

export { Tier1, Tier2, Tier3 };

const Wrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const StarContainer = styled(Flex)`
  justify-content: center;
  position: relative;
  bottom: 0;
`;

const Polygon = styled.polygon`
  fill: ${colour("light")};
`;

const Star = ({ className }) => (
  <svg
    className={className}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
  >
    <Polygon points="50,2.67 61.8,39 100,39 69.1,61.45 80.9,97.78 50,75.33 19.1,97.78 30.9,61.45 0,39 38.2,39" />
  </svg>
);

const FixedSizeStar = styled(Star)`
  flex: 0 0 16px;
`;

const Stars = tier => {
  const array = new Array(tier);
  return array.map((content, index) => <FixedSizeStar key={index} />);
};

export const tier = tier => {
  switch (tier) {
    case 5:
      return <Tier3 />;
    case 4:
    case 3:
      return <Tier2 />;
    default:
      return <Tier1 />;
  }
};
