import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useCookies } from "react-cookie";
import { fontSize, h } from "../../theme";
import Heading from "../Heading";
import Flex from "../utility/Flex";
import RoundButton from "../RoundButton";
import { width } from "styled-system";
import { getNextDay } from "../../page/Buddy";

export const action = {
  walk: {
    id: 0,
    name: { id: "buddy.interaction.walk", defaultMessage: "Walk" },
    delta: 3,
    hasCooldown: false
  },
  feed: {
    berry: {
      id: 1,
      name: { id: "buddy.interaction.berry", defaultMessage: "Berry" },
      delta: 1,
      hasCooldown: true
    },
    poffin: {
      id: 2,
      name: { id: "buddy.interaction.poffin", defaultMessage: "Poffin" },
      delta: 44,
      hasCooldown: true
    }
  },
  play: {
    id: 2,
    name: { id: "buddy.interaction.play", defaultMessage: "Play" },
    delta: 1,
    hasCooldown: true
  },
  battle: {
    trainer: {
      id: 3,
      name: {
        id: "buddy.interaction.battle.trainer",
        defaultMessage: "Trainer"
      },
      delta: 1,
      hasCooldown: true
    },
    leader: {
      id: 4,
      name: {
        id: "buddy.interaction.battle.leader",
        defaultMessage: "Train"
      },
      delta: 1,
      hasCooldown: true
    },
    gym: {
      id: 5,
      name: {
        id: "buddy.interaction.battle.gym",
        defaultMessage: "Gym"
      },
      delta: 1,
      hasCooldown: true
    },
    raid: {
      id: 6,
      name: {
        id: "buddy.interaction.battle.raid",
        defaultMessage: "Raid"
      },
      delta: 1,
      hasCooldown: true
    },
    rocket: {
      id: 7,
      name: {
        id: "buddy.interaction.battle.rocket",
        defaultMessage: "GO Rocket"
      },
      delta: 1,
      hasCooldown: true
    }
  },
  snapshot: {
    id: 8,
    name: { id: "buddy.interaction.snapshot", defaultMessage: "Snapshot" },
    delta: 1,
    hasCooldown: true
  },
  place: {
    id: 9,
    name: { id: "buddy.interaction.place", defaultMessage: "New place" },
    delta: 1,
    hasCooldown: true
  },
  bonus: {
    present: {
      id: 10,
      name: { id: "buddy.interaction.present", defaultMessage: "Present" },
      delta: 3,
      hasCooldown: false
    },
    souvenir: {
      id: 11,
      name: { id: "buddy.interaction.souvenir", defaultMessage: "Souvenir" },
      delta: 3,
      hasCooldown: false
    },
    location: {
      id: 12,
      name: { id: "buddy.interaction.location", defaultMessage: "Location" },
      delta: 3,
      hasCooldown: false
    }
  }
};

export const actions = [
  action.walk,
  action.feed.berry,
  action.play,
  action.battle.trainer,
  action.battle.leader,
  action.battle.gym,
  action.battle.raid,
  action.battle.rocket,
  action.snapshot,
  action.place,
  action.bonus.present,
  action.bonus.souvenir,
  action.bonus.location
];

const Actions = styled.table`
  display: flex;
  flex-direction: column-reverse;
  align-self: center;
  border-collapse: separate;
  border-spacing: 2rem 0.5rem;
`;

const TR = styled.tr`
  align-items: center;
  ${h}
  ${fontSize("small")}
`;

const TD = styled.td`
  ${width}
`;

const Action = ({ action, index }) => {
  const intl = useIntl();
  const [cookies, setCookie] = useCookies();
  return (
    <TR>
      <TD width={"50%"}>{intl.formatMessage(action.name)}</TD>
      <TD width={"10%"}>+{action.delta}</TD>
      <TD width={"20%"}>{intl.formatTime(action.time)}</TD>
      <TD width={"10%"}>
        <RoundButton
          onClick={() => {
            if (cookies.history) {
              cookies.history.splice(index, 1);
              setCookie("history", cookies.history, {
                path: "/buddy",
                expires: getNextDay()
              });
            }
          }}
        >
          &#x2715;
        </RoundButton>
      </TD>
    </TR>
  );
};

export default ({ history, ...props }) => {
  const intl = useIntl();
  const [, , deleteCookie] = useCookies();
  return (
    <Flex column {...props}>
      <Flex justify={"space-between"} align={"center"}>
        <Heading>
          {intl.formatMessage({
            id: "buddy.history",
            defaultMessage: "History"
          })}
        </Heading>
        <RoundButton
          fontSize={"small"}
          onClick={() => deleteCookie("history", { path: "/buddy" })}
        >
          &#x27F2;
        </RoundButton>
      </Flex>
      <Actions>
        {history &&
          history.map((action, index) => (
            <Action
              key={index}
              index={index}
              action={{ ...actions[action.action], time: action.time }}
            />
          ))}
      </Actions>
    </Flex>
  );
};
