import React from "react";
import styled from "styled-components";

import { selectedSpace } from "../utility/styling";
import { fontSize, space } from "../theme";

import FontWeightNormal from "./utility/FontWeightNormal";

export default styled(FontWeightNormal.withComponent("h3"))`
  ${fontSize("heading.small")}
  ${selectedSpace({ my: space("small") })}
`;
