import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { IntlProvider } from "react-intl";
import { Router } from "@reach/router";
import { useCookies } from "react-cookie";

import PokemonProvider from "./component/PokemonProvider";
import Footer from "./component/Footer";

import { Home, Box, Buddy, Raids, NotFound } from "./page";

import { GlobalStyle } from "./theme";
import theme from "./theme";
import RaidDirect from "./page/RaidDirect";
import NotificationProvider from "./component/NotificationProvider";
import Notification from "./component/Notification";
import Privacy from "./page/Privacy";
import Menu from "./component/Menu";
import Button from "./component/Button";
import Link from "./component/Link";

const locale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  "en-GB";
const language = locale.toLowerCase().split(/[-_]+/)[0];
// const language = "de";
// const messages = languages[language] || languages[locale];

const getCookiesExpires = () => new Date(Date.now() + 2592000000);

export default () => {
  const [messages, setMessages] = useState(undefined);
  !messages &&
    fetch(`https://pokeboxadvance.net/language/${language}.json`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(json => {
        setMessages(json);
      })
      .catch(error => {
        setMessages({});
      });
  const [pokemonData, setPokemonData] = useState(undefined);
  !pokemonData &&
    fetch("https://pokeboxadvance.net/api/pokemon.json")
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(json => {
        setPokemonData(json);
      })
      .catch(error => {
        setPokemonData([]);
      });
  const [pokemonNameNumberMap, setPokemonNameNumberMap] = useState(
    language === "en" ? [] : undefined
  );
  !pokemonNameNumberMap &&
    fetch(`https://pokeboxadvance.net/api/${language}.json`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(json => {
        setPokemonNameNumberMap(json);
      })
      .catch(error => {
        setPokemonNameNumberMap([]);
      });
  const [box, setBox] = useState(undefined);
  const pushBox = pokemon => {
    box ? setBox([...box, pokemon]) : setBox([pokemon]);
  };
  const [notifying, setNotifying] = useState(false);
  const [notification, setNotification] = useState(undefined);
  useEffect(() => {
    const notificationTimeout =
      notifying && setTimeout(() => setNotifying(false), 4000);
    return () => clearTimeout(notificationTimeout);
  }, [notifying]);
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => {
    setMenu(!menu);
  };
  const [cookies, setCookie] = useCookies();
  // if (cookies.cookies && cookies.cookies.expires.getTime() < Date.now()) {
  //   setCookie("cookies", true, {
  //     path: "/",
  //     expires: getCookiesExpires()
  //   });
  // }
  return (
    <IntlProvider locale={locale} messages={messages}>
      <ThemeProvider theme={theme}>
        <PokemonProvider
          pokemonData={pokemonData}
          pokemonNameNumberMap={pokemonNameNumberMap}
        >
          <NotificationProvider
            value={notification => {
              setNotification(notification);
              setNotifying(true);
            }}
          >
            <GlobalStyle />
            <Notification
              text={
                <>
                  This app uses cookies for functionality. See our{" "}
                  <Link to={"privacy"}>privacy policy</Link> for more
                  information.
                </>
              }
              showing={!cookies.cookies}
              dismissElement={
                <Button
                  onClick={() =>
                    setCookie("cookies", true, {
                      path: "/",
                      expires: getCookiesExpires()
                    })
                  }
                >
                  Accept
                </Button>
              }
            />
            <Notification
              {...notification}
              showing={notifying}
              onExited={() => setNotification(undefined)}
            />
            <Menu
              menu={[
                {
                  text: {
                    id: "menu.home",
                    defaultMessage: "Home"
                  },
                  to: "/"
                },
                {
                  text: {
                    id: "menu.raids",
                    defaultMessage: "Raid Coordinator"
                  },
                  to: "raid"
                },
                {
                  text: { id: "menu.buddy", defaultMessage: "Buddy Mood" },
                  to: "buddy"
                }
              ]}
              visible={menu}
              toggleMenu={toggleMenu}
            />
            <Router>
              {[
                { Component: Home, path: "/" },
                { Component: Buddy, path: "buddy" },
                { Component: RaidDirect, path: "raid" },
                { Component: Raids, path: "raid/:lat/:lng/:zoom" },
                { Component: Privacy, path: "privacy" }
              ].map(({ Component, path }, index) => (
                <Component key={index} toggleMenu={toggleMenu} path={path} />
              ))}
              <NotFound toggleMenu={toggleMenu} default />
            </Router>
            <Footer />
          </NotificationProvider>
        </PokemonProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};
