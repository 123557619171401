import React from "react";
import styled from "styled-components";

import {
  rawWidth,
  selectedBg,
  selectedBorderRadius,
  selectedColour,
  selectedSpace
} from "../utility/styling";
import { colour, space, fontSize, borderRadius, input } from "../theme";

export default styled.input`
  display: flex;
  border: 2px solid;
  min-width: 0;
  font-family: Lato, sans-serif;
  ${({ float }) => float && `float: ${float};`}
  ${rawWidth("100%")}
  ${({ inverted }) =>
    inverted ? "color: white;" : selectedColour(colour("light"))}
  ${({ inverted }) =>
    inverted ? selectedBg(colour("light")) : "background: white;"}
  border-color:${({ inverted }) => (inverted ? colour("light") : "white")};
  ${selectedSpace({ selectedPy: space("tiny"), selectedPx: space("small") })}
  ${fontSize("text")}
  ${selectedBorderRadius(borderRadius())}
  
  ${input}
  
  ::placeholder {
    opacity: 0.5;
  }
`;
