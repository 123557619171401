import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c1 = shiny ? "#ffdc68" : "#f79143";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Charmander Portrait"
      id="Charmander_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Charmander</title>
      <path
        d="M94.3,66.22c.24,2-1,4.9-2.12,7a47.63,47.63,0,0,1-6.57,9.37,53.4,53.4,0,0,1-6.25,6,48.93,48.93,0,0,1-8,5.25A44.87,44.87,0,0,1,50.33,99H48.17a44.83,44.83,0,0,1-21-5.17,48.09,48.09,0,0,1-8-5.25,52.62,52.62,0,0,1-6.25-6,47.63,47.63,0,0,1-6.57-9.37c-1.1-2.11-2.37-5-2.13-7a12.66,12.66,0,0,1,1.33-4.86,81.46,81.46,0,0,0,3.09-7.74,96.29,96.29,0,0,1,3.29-24,53.92,53.92,0,0,1,3.56-9.19,39.09,39.09,0,0,1,4.78-7.33,32,32,0,0,1,12.84-9.3A43.62,43.62,0,0,1,49.15,1h.21A43.65,43.65,0,0,1,65.42,3.81a32.08,32.08,0,0,1,12.83,9.3A38.12,38.12,0,0,1,83,20.44a53.11,53.11,0,0,1,3.57,9.19,96.32,96.32,0,0,1,3.28,24c1.63,4.56,2.54,6.55,3.09,7.74A12.48,12.48,0,0,1,94.3,66.22Zm-13.75,18C88.3,77,92.1,68.74,91.84,66.52c-.51-4.26-.69-1.88-4.43-12.44-.85-37.59-16-50.55-38.06-50.59h-.2c-22.09,0-37.2,13-38,50.59C7.35,64.64,7.18,62.26,6.67,66.52a5.14,5.14,0,0,0,.3,1.9l.12.39c.09.26.19.54.31.84.06.14.12.29.19.44s.13.31.2.48.22.48.35.73h0c.29.59.61,1.22,1,1.87h0a47.75,47.75,0,0,0,7.68,10l.65.65.67.63h0c.34.32.69.63,1.05,1h0c.59.52,1.21,1,1.84,1.54h0c.38.3.77.6,1.17.89h0c.79.59,1.61,1.17,2.46,1.72h0l.86.55h0c.73.45,1.48.88,2.24,1.3h0l.93.49a42.49,42.49,0,0,0,19.49,4.67l1.08,0,1.08,0A42.85,42.85,0,0,0,76.09,88l.62-.46c1.24-.94,2.4-1.92,3.48-2.92Z"
        fill={outline}
      />
      <path
        d="M91.84,66.52C92.1,68.74,88.3,77,80.55,84.25l-.36.33c-1.08,1-2.24,2-3.48,2.92l-.62.46a42.85,42.85,0,0,1-25.76,8.56l-1.08,0-1.08,0a42.49,42.49,0,0,1-19.49-4.67l-.93-.49h0c-.76-.42-1.51-.85-2.24-1.3h0l-.86-.55h0C23.8,89,23,88.38,22.19,87.79h0c-.4-.29-.79-.59-1.17-.89h0c-.63-.51-1.25-1-1.84-1.54h0c-.36-.32-.71-.63-1.05-1h0l-.67-.63-.65-.65a47.75,47.75,0,0,1-7.68-10h0c-.37-.65-.7-1.28-1-1.87h0c-.13-.25-.24-.5-.35-.73s-.14-.32-.2-.48-.13-.3-.19-.44c-.12-.3-.22-.58-.31-.84L7,68.42a5.14,5.14,0,0,1-.3-1.9c.51-4.26.68-1.88,4.43-12.44C12,16.49,27.06,3.53,49.15,3.49h.2c22.09,0,37.21,13,38.06,50.59C91.15,64.64,91.33,62.26,91.84,66.52Z"
        fill={c1}
      />
      <path d="M27.75,91.36l.93.49Z" fill={outline} />
      <path
        d="M25.51,90.06c.73.45,1.48.88,2.24,1.3C27,90.94,26.24,90.51,25.51,90.06Z"
        fill={outline}
      />
      <path d="M24.65,89.51l.86.55Z" fill={outline} />
      <path
        d="M22.19,87.79c.79.59,1.61,1.17,2.46,1.72C23.8,89,23,88.38,22.19,87.79Z"
        fill={outline}
      />
      <path
        d="M21,86.9c.38.3.77.6,1.17.89C21.79,87.5,21.4,87.2,21,86.9Z"
        fill={outline}
      />
      <path
        d="M19.18,85.36c.59.52,1.21,1,1.84,1.54C20.39,86.39,19.77,85.88,19.18,85.36Z"
        fill={outline}
      />
      <path
        d="M18.13,84.41c.34.32.69.63,1.05,1C18.82,85,18.47,84.73,18.13,84.41Z"
        fill={outline}
      />
      <path d="M17.46,83.78l.67.63Z" fill={outline} />
      <path
        d="M9.13,73.17a47.75,47.75,0,0,0,7.68,10A47.75,47.75,0,0,1,9.13,73.17Z"
        fill={outline}
      />
      <path
        d="M8.14,71.3c.29.59.62,1.22,1,1.87C8.75,72.52,8.43,71.89,8.14,71.3Z"
        fill={outline}
      />
      <path
        d="M7.79,70.57c.11.23.22.48.35.73C8,71.05,7.9,70.8,7.79,70.57Z"
        fill={outline}
      />
      <path
        d="M7.4,69.65c.06.14.12.29.19.44C7.52,69.94,7.46,69.79,7.4,69.65Z"
        fill={outline}
      />
      <path d="M7,68.42l.12.39Z" fill={outline} />
      <path
        d="M70.72,54.93c1.2,2.56,11.42,3.75,13-1S85,27.17,77.88,27.68,69.53,52.38,70.72,54.93Z"
        fill={"#fff"}
      />
      <path
        d="M77.88,27.68c-7.16.51-8.35,24.7-7.16,27.25.91,1.93,7,3.09,10.53,1.48a51,51,0,0,0,1.4-12.29c0-5.44-.25-10.56-1.28-14.37A3.83,3.83,0,0,0,77.88,27.68Z"
        fill={outline}
      />
      <path
        d="M78.94,57.05a12.64,12.64,0,0,0,1.48-5.18c.36-4.4-1.25-8.13-3.59-8.32s-4.54,3.22-4.9,7.63a12.79,12.79,0,0,0,.55,5.13A13,13,0,0,0,78.94,57.05Z"
        fill={"#1f8671"}
      />
      <path
        d="M80.44,34.32a11.51,11.51,0,0,0-1.73-6.59,3.26,3.26,0,0,0-.83-.05c-2.13.15-3.74,2.41-4.9,5.57,0,.35,0,.71,0,1.07,0,4.33,1.68,7.84,3.75,7.84S80.44,38.65,80.44,34.32Z"
        fill={"#fff"}
      />
      <path
        d="M27.66,54.93c-1.2,2.56-11.42,3.75-12.95-1S13.35,27.17,20.5,27.68,28.85,52.38,27.66,54.93Z"
        fill={"#fff"}
      />
      <path
        d="M20.5,27.68c7.16.51,8.35,24.7,7.16,27.25-.9,1.93-7,3.09-10.53,1.48a51,51,0,0,1-1.4-12.29c0-5.44.25-10.56,1.28-14.37A3.83,3.83,0,0,1,20.5,27.68Z"
        fill={outline}
      />
      <path
        d="M19.44,57.05A12.8,12.8,0,0,1,18,51.87c-.36-4.4,1.25-8.13,3.59-8.32s4.54,3.22,4.9,7.63a12.79,12.79,0,0,1-.55,5.13A13,13,0,0,1,19.44,57.05Z"
        fill={"#1f8671"}
      />
      <path
        d="M18,34.32a11.51,11.51,0,0,1,1.72-6.59,3.26,3.26,0,0,1,.83-.05c2.14.15,3.74,2.41,4.91,5.57,0,.35,0,.71,0,1.07,0,4.33-1.68,7.84-3.75,7.84S18,38.65,18,34.32Z"
        fill={"#fff"}
      />
      <path
        d="M84.6,68.13l-.22,1.12a23.37,23.37,0,0,1-6.61,12.26A32.86,32.86,0,0,1,67,88.39a52.54,52.54,0,0,1-17.71,3.73,41.19,41.19,0,0,1-6.68-.71,48.58,48.58,0,0,1-10.47-3.07,34.51,34.51,0,0,1-10.72-7A27.22,27.22,0,0,1,14.15,69l-.34-1.27,1.31.14c5.27.59,12.83.67,18.34.73,4.91.05,7.51.09,8.37.32a18.46,18.46,0,0,1,3.59,1.81c1.39.8,3.29,1.91,4,1.91s2.08-.88,3.21-1.58a14.71,14.71,0,0,1,3.56-1.8c1.12-.28,3.1-.32,7.45-.41,4.14-.09,10.39-.21,19.81-.64ZM49.26,91.22c4.6,0,30.32-2.38,34.24-22.15-18.91.86-25,.52-27.09,1s-5.28,3.4-7,3.4-6-3.23-7.84-3.75-17.37,0-26.57-1C20.47,88.84,44.66,91.22,49.26,91.22Z"
        fill={outline}
      />
      <path
        d="M83.5,69.07C79.58,88.84,53.86,91.22,49.26,91.22S20.47,88.84,15,68.73c9.2,1,24.7.51,26.57,1s6.14,3.75,7.84,3.75,4.94-2.89,7-3.4S64.59,69.93,83.5,69.07Z"
        fill={"#523a3a"}
      />
      <path
        d="M49.26,91.22c3.69,0,21-1.54,29.69-12.34a28.28,28.28,0,0,0-9.14-9.31c-8.5.23-12,.17-13.4.53-2,.51-5.28,3.4-7,3.4s-6-3.23-7.84-3.74c-1-.27-5.6-.26-11.13-.33a28.15,28.15,0,0,0-9.67,10C30,89.75,45.65,91.22,49.26,91.22Z"
        fill={"#e08082"}
      />
      <path
        d="M31.41,69.44c-2,0-4.23-.06-6.42-.12,1.26,2.44,2.66,4.89,3.2,4.92S30.14,71.86,31.41,69.44Z"
        fill={"#fff"}
      />
      <path
        d="M67.1,69.64c1.2,2.3,2.49,4.5,3,4.53s1.91-2.32,3.16-4.7Z"
        fill={"#fff"}
      />
      <path
        d="M63.05,88.85a42.89,42.89,0,0,0,6.11-2.43c-1.74-2-3.53-3.79-4-3.64S63.72,86,63.05,88.85Z"
        fill={"#fff"}
      />
      <path
        d="M35.19,88.57c-.64-2.58-1.39-5.13-1.89-5.28s-1.91,1.31-3.45,3A40.57,40.57,0,0,0,35.19,88.57Z"
        fill={"#fff"}
      />
      <path
        d="M42,64.39a3,3,0,0,1,2,2.09c.09.63,1.05.36,1-.26a3.87,3.87,0,0,0-2.44-2.69c-.59-.24-1.09.61-.51.86Z"
        fill={outline}
      />
      <path
        d="M56,63.53a3.87,3.87,0,0,0-2.44,2.69c-.09.62.87.89,1,.26a3,3,0,0,1,2-2.09c.59-.25.09-1.1-.5-.86Z"
        fill={outline}
      />
      <path
        d="M23.11,22.65c.14.1,0,0,.11.09s.33.27.49.41a14.44,14.44,0,0,1,1.51,1.52A10.61,10.61,0,0,1,27.83,30c.08.63,1,.37,1-.26a12.58,12.58,0,0,0-5.16-8c-.52-.38-1,.48-.51.85Z"
        fill={outline}
      />
      <path
        d="M74.73,21.8a12.64,12.64,0,0,0-5.17,8c-.08.62.87.89,1,.26a10.61,10.61,0,0,1,2.61-5.35,15.37,15.37,0,0,1,1.5-1.52l.48-.4.12-.1c.52-.37,0-1.23-.5-.85Z"
        fill={outline}
      />
    </SVG>
  );
};
