import React from "react";
import StyledSVG from "../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 108 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.863715 52.5625C-1.8297 55.9768 30.3214 105.761 52.6293 90.5147C74.9372 75.2678 44.3918 26.1255 39.5236 27.8892C34.6555 29.6529 36.7393 48.1068 36.7393 48.1068C36.7393 48.1068 21.825 33.2927 18.317 35.3685C14.809 37.4443 21.8952 57.814 21.8952 57.814C21.8952 57.814 3.55713 49.1481 0.863715 52.5625Z"
      fill="currentcolor"
    />
    <path
      d="M56.4254 9.93384C52.6119 12.0244 62.7548 70.4138 89.2467 65.0955C115.739 59.7771 106.823 2.60625 101.653 2.3276C96.4823 2.04894 91.19 19.8501 91.19 19.8501C91.19 19.8501 83.2496 0.386181 79.2094 0.926241C75.1692 1.4663 73.733 22.9856 73.733 22.9856C73.733 22.9856 60.2388 7.84328 56.4254 9.93384Z"
      fill="currentcolor"
    />
  </StyledSVG>
);
