import React from "react";
import styled from "styled-components";
import { selectedSpace } from "../../utility/styling";
import { space } from "../../theme";

export default styled.div`
  flex: 1;
  transform-origin: center top;

  > div,
  > input:not(:last-child),
  > select:not(:last-child) {
    ${selectedSpace({ selectedMb: space("tiny") })}
  }
`;
