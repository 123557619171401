import React from "react";
import styled from "styled-components";
import { selectedBorderRadius, selectedSpace } from "../utility/styling";
import { borderRadius, shadow, space, transition } from "../theme";
import { margin } from "styled-system";

export default styled.div`
  left: 0;
  right: 0;
  box-shadow: ${shadow("popup")};
  ${selectedSpace({
    selectedM: space("larger"),
    selectedP: space("large")
  })}
  ${selectedBorderRadius(borderRadius())}
  
  &.enter {
    transform: scale(0);
    transition: transform 200ms ${transition("in")};
  }
  &.exit.exit-active {
    transform: scale(0);
  }

  &.exit {
    transform: scale(1);
    transition: transform 150ms ${transition("out")};
  }
  &.enter.enter-active {
    transform: scale(1);
  }
`;
