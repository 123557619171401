import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Absol Portrait"
      id="Absol_Portrait"
      viewBox="0 0 100 100"
    >
      <path
        d="M54.6887 86.9159C46.8486 86.9159 21.9765 78.972 28.6001 49.8456C39.0085 58.5835 49.1466 63.2177 54.6887 63.2177C60.2308 63.2177 70.3689 58.5835 80.7773 49.8456C87.4009 78.972 62.5288 86.9159 54.6887 86.9159Z"
        fill="#8398CF"
      />
      <path
        d="M49.6347 80.1967C49.2693 80.0017 48.815 80.1399 48.62 80.5054C48.4251 80.8709 48.5633 81.3252 48.9288 81.5201L49.6347 80.1967ZM60.7363 81.5106C61.0965 81.3062 61.2228 80.8484 61.0183 80.4881C60.8138 80.1279 60.356 80.0017 59.9958 80.2062L60.7363 81.5106ZM48.9288 81.5201C50.5174 82.3675 52.7825 82.741 54.9347 82.7326C57.0819 82.7243 59.2839 82.3351 60.7363 81.5106L59.9958 80.2062C58.8649 80.8482 56.9603 81.2248 54.9289 81.2327C52.9026 81.2405 50.9172 80.8807 49.6347 80.1967L48.9288 81.5201Z"
        fill="#4D4D75"
      />
      <path
        d="M54.8239 77.9392C53.689 77.9392 52.097 76.2198 52.3726 75.7174C52.6482 75.215 53.851 74.9132 54.8239 74.9132C55.7968 74.9132 56.9996 75.215 57.2752 75.7174C57.5508 76.2198 55.9589 77.9392 54.8239 77.9392Z"
        fill="#4D4D75"
      />
      <path
        d="M47.8567 69.524C39.8318 71.7128 30.6896 68.0317 31.8069 57.7842C37.1907 57.7842 43.9966 61.9628 47.8567 69.524Z"
        fill="white"
      />
      <path
        d="M37.5501 59.1696C40.6457 60.6089 43.7285 63.1448 46.1501 66.6591C46.058 67.8397 45.8899 68.955 45.6588 69.9737C42.7156 70.3791 39.761 70.0078 37.3338 68.8267C37.1106 67.5082 36.9875 66.0639 36.9875 64.5496C36.9875 62.6006 37.1914 60.7675 37.5501 59.1696Z"
        fill="#6A3336"
      />
      <ellipse
        cx="42.4589"
        cy="65.8875"
        rx="1.42446"
        ry="2.6401"
        fill="white"
      />
      <path
        d="M47.8567 69.524C39.8318 71.7128 30.6896 68.0317 31.8069 57.7842C37.1907 57.7842 43.9966 61.9628 47.8567 69.524Z"
        stroke="#4D4D75"
      />
      <path
        d="M61.7914 69.5239C69.8163 71.7127 78.9585 68.0316 77.8411 57.7841C72.4573 57.7841 65.6515 61.9627 61.7914 69.5239Z"
        fill="white"
      />
      <path
        d="M72.0979 59.1694C69.0023 60.6088 65.9195 63.1447 63.4979 66.659C63.5901 67.8396 63.7581 68.9548 63.9892 69.9735C66.9324 70.379 69.8871 70.0077 72.3143 68.8266C72.5374 67.5081 72.6605 66.0637 72.6605 64.5494C72.6605 62.6005 72.4566 60.7674 72.0979 59.1694Z"
        fill="#6A3336"
      />
      <ellipse
        rx="1.42446"
        ry="2.6401"
        transform="matrix(-1 0 0 1 67.1891 65.8875)"
        fill="white"
      />
      <path
        d="M61.7914 69.5239C69.8163 71.7127 78.9585 68.0316 77.8411 57.7841C72.4573 57.7841 65.6515 61.9627 61.7914 69.5239Z"
        stroke="#4D4D75"
      />
      <path
        d="M20.6147 42.9715C21.682 44.4647 24.4093 45.625 26.916 45.625C31.4765 49.9734 30.4138 53.1999 28.2921 58.248C26.052 56.2873 23.839 53.6808 20.0332 53.4738C14.3773 53.166 14.3138 54.7275 10.9996 54.7275C7.68536 54.7275 3.87327 52.0903 4.5723 39.9534C6.01515 14.9017 32.1843 0.896969 59.0975 2.28031C14.5161 15.7995 19.5475 41.4783 20.6147 42.9715Z"
        fill="#8398CF"
      />
      <path
        d="M95.5114 54.7444C95.5114 66.9246 90.6451 83.606 77.1277 95.6535C78.7498 83.4736 79.2905 76.4568 74.6946 58.1866C66.9896 63.0851 62.3937 64.409 55.3646 64.409C41.5768 64.409 25.8966 51.6993 25.8966 42.2995C25.8966 32.8996 29.9518 16.4829 55.3646 16.4829C88.212 16.4829 95.5114 42.5642 95.5114 54.7444Z"
        fill="white"
      />
      <ellipse
        cx="54.8239"
        cy="46.867"
        rx="5.13663"
        ry="12.1139"
        fill="#8398CF"
      />
      <path
        d="M76.3843 95.5545L76.1174 97.5586L77.6267 96.2134C91.3334 83.9973 96.2614 67.0954 96.2614 54.7444C96.2614 48.5356 94.4072 38.8025 88.3594 30.601C82.283 22.3606 72.0192 15.7329 55.3646 15.7329C42.4839 15.7329 34.8768 19.9028 30.5134 25.3999C26.1762 30.864 25.1466 37.512 25.1466 42.2995C25.1466 43.1018 25.2515 43.9181 25.4481 44.7392C24.7111 44.6064 23.9885 44.3817 23.3395 44.0898C22.3665 43.6521 21.6238 43.0933 21.2253 42.5359C21.2263 42.5373 21.2255 42.5356 21.223 42.5305C21.2114 42.5066 21.1636 42.4081 21.0977 42.1953C21.0243 41.9578 20.9441 41.6304 20.8721 41.2194C20.7283 40.3986 20.6218 39.2703 20.6579 37.9045C20.7301 35.1754 21.3714 31.5132 23.4076 27.4732C27.471 19.4111 37.1586 9.71695 59.3152 2.99804L59.136 1.5313C45.5475 0.83285 32.1168 4.01573 21.879 10.5722C11.6284 17.1368 4.56122 27.1021 3.82354 39.9103C3.47087 46.0335 4.24288 49.9057 5.63448 52.272C7.06413 54.703 9.10762 55.4775 10.9996 55.4775C12.4952 55.4775 13.4027 55.1783 14.3077 54.88L14.3126 54.8784L14.3407 54.8691C15.52 54.4803 16.8241 54.0503 19.9925 54.2227C22.9203 54.382 24.819 56.0882 26.7618 57.8744C26.3701 69.3771 31.4845 76.8385 37.6248 81.406C43.7851 85.9883 50.9822 87.6659 54.6887 87.6659C57.303 87.6659 61.617 86.8341 66.0705 84.7698C70.0238 82.9374 74.1324 80.113 77.2713 75.9826C77.9218 82.9572 77.3484 88.3148 76.3843 95.5545Z"
        stroke="#4D4D75"
        stroke-width="1.5"
      />
    </SVG>
  );
};
