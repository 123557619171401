import React from "react";
import styled from "styled-components";

import Page from "../component/Page";
import Text from "../component/Text";
import Preview from "../component/home/Preview";
import Heading from "../component/Heading";

import { selectedSpace } from "../utility/styling";
import { fontSize, space } from "../theme";

const StyledPreview = styled(Preview)`
  ${({ soon }) => soon && "filter: grayscale(100%);"}
  ${({ soon }) => soon && "pointer-events: none;"}
  ${selectedSpace({ selectedMb: space("large") })}
`;

const ComingSoon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  ${({ special }) =>
    special ? "font-style: italic;" : "text-transform: uppercase;"}
  ${selectedSpace({ selectedP: space("small") })};
  ${fontSize("small")}
`;

export default props => (
  <Page title={"Home"} heading={"Pokébox Advance"} {...props}>
    <StyledPreview
      background={`url(https://pokemongolive.com/img/posts/raids_loading.png)`}
      to={"raid"}
      className={"unpadded"}
    >
      <ComingSoon>Beta</ComingSoon>
      <Heading>Raid Coordinator</Heading>
      <Text>Report raids happening around you and coordinate with others!</Text>
    </StyledPreview>
    <StyledPreview
      background={`url(https://pokemongolive.com/img/posts/buddyadventurelaunch.jpg)`}
      to={"buddy"}
      className={"unpadded"}
    >
      <Heading>Buddy Mood</Heading>
      <Text>Keep track of your buddy's mood.</Text>
    </StyledPreview>
    <StyledPreview
      // background={`url(https://pokemongolive.com/img/posts/buddyadventurelaunch.jpg)`}
      soon
      to={"buddy"}
      className={"unpadded"}
    >
      <ComingSoon>Coming soon</ComingSoon>
      <Heading>Database</Heading>
      <Text>Pok&eacute;mon and move stats to compare and sort.</Text>
    </StyledPreview>
    <StyledPreview
      // background={`url(https://pokemongolive.com/img/posts/buddyadventurelaunch.jpg)`}
      soon
      to={"buddy"}
      className={"unpadded"}
    >
      <ComingSoon>Coming soon</ComingSoon>
      <Heading>Wiki</Heading>
      <Text>
        Information about Pokémon GO for when you need to look something up.
      </Text>
    </StyledPreview>
  </Page>
);
