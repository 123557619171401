import React from "react";
import styled from "styled-components";
import { selectedSpace } from "../../utility/styling";
import { space } from "../../theme";

const PaddedLabel = styled.label`
  display: flex;
  align-items: center;
  ${selectedSpace({ selectedPy: space("small"), selectedPx: space("small") })}
`;

export default PaddedLabel;
