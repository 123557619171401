import React from "react";
import StyledSVG from "../../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 84 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.7479 46.748C18.1551 46.748 20.2125 25.1053 20.2125 25.1053H63.2834C63.2834 25.1053 65.3408 46.748 41.7479 46.748Z"
      stroke="currentcolor"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9774 8.15888C19.1761 9.93136 17.3925 11.1647 15.3209 11.1647C12.5001 11.1647 10.2134 8.878 10.2134 6.05721C10.2134 3.98559 11.4468 2.20204 13.2192 1.40078C12.2689 1.10857 11.2596 0.951294 10.2134 0.951294C4.57271 0.951294 0 5.524 0 11.1647C0 16.8054 4.57271 21.3781 10.2134 21.3781C15.8541 21.3781 20.4268 16.8054 20.4268 11.1647C20.4268 10.1186 20.2696 9.10919 19.9774 8.15888Z"
      fill="currentcolor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.0474 8.15889C82.2461 9.93136 80.4625 11.1647 78.3909 11.1647C75.5701 11.1647 73.2834 8.87801 73.2834 6.05722C73.2834 3.9856 74.5168 2.20204 76.2893 1.40078C75.339 1.10858 74.3296 0.951294 73.2834 0.951294C67.6427 0.951294 63.07 5.524 63.07 11.1647C63.07 16.8054 67.6427 21.3781 73.2834 21.3781C78.9241 21.3781 83.4968 16.8054 83.4968 11.1647C83.4968 10.1186 83.3396 9.10919 83.0474 8.15889Z"
      fill="currentcolor"
    />
  </StyledSVG>
);
