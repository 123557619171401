import React from "react";
import { useIntl } from "react-intl";
import { useCookies } from "react-cookie";
import Button from "../Button";
import { getNextDay } from "../../page/Buddy";

export default ({ action, ...props }) => {
  const intl = useIntl();
  const [cookies, setCookie] = useCookies();
  return (
    <Button
      {...props}
      onClick={() => {
        cookies.history
          ? setCookie(
              "history",
              [...cookies.history, { action: action.id, time: Date.now() }],
              {
                path: "/buddy",
                expires: getNextDay()
              }
            )
          : setCookie("history", [{ action: action.id, time: Date.now() }], {
              path: "/buddy",
              expires: getNextDay()
            });
      }}
    >
      {intl.formatMessage(action.name)}
    </Button>
  );
};
