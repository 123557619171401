import React, { useState } from "react";
import { injectIntl, useIntl } from "react-intl";

import { useNotification } from "../NotificationProvider";

import Input from "../Input";
import Select from "../Select";
import Loadable from "./Loadable";
import ChildSpacing from "./ChildSpacing";

const messages = {
  tier: {
    id: "tier",
    defaultMessage: "Tier"
  },
  tier1: {
    id: "tier.1",
    defaultMessage: "Tier 1"
  },
  tier2: {
    id: "tier.2",
    defaultMessage: "Tier 2"
  },
  tier3: {
    id: "tier.3",
    defaultMessage: "Tier 3"
  },
  tier4: {
    id: "tier.4",
    defaultMessage: "Tier 4"
  },
  tier5: {
    id: "tier.5",
    defaultMessage: "Tier 5"
  },
  time: {
    id: "raid.report.time",
    defaultMessage: "Time"
  },
  submit: {
    id: "raid.report",
    defaultMessage: "Report Raid"
  },
  notification: {
    id: "raid.notification.report",
    defaultMessage: "Reported a Tier {tier} Raid at {gymName} at {start}!"
  },
  error: {
    id: "raid.notification.report.error",
    defaultMessage: "Could not report Raid: {message}"
  }
};

export default ({ id, name, onSubmitComplete }) => {
  const [tier, setTier] = useState(1);
  const [start, setStart] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const displayNotification = useNotification();
  const today = new Date();
  const intl = useIntl();
  return (
    <Loadable loading={loading}>
      <ChildSpacing>
        <Select
          value={tier}
          onChange={event => setTier(event.target.value)}
          inverted
        >
          <optgroup label={intl.formatMessage(messages.tier)}>
            <option value={1}>{intl.formatMessage(messages.tier1)}</option>
            <option value={2}>{intl.formatMessage(messages.tier2)}</option>
            <option value={3}>{intl.formatMessage(messages.tier3)}</option>
            <option value={4}>{intl.formatMessage(messages.tier4)}</option>
            <option value={5}>{intl.formatMessage(messages.tier5)}</option>
          </optgroup>
        </Select>
        <Input
          type={"time"}
          min={"06:00"}
          max={"22:00"}
          placeholder={intl.formatMessage(messages.time)}
          required={true}
          inverted
          onChange={event => {
            const [hour, minute] = event.target.value.split(":");
            setStart(
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                hour,
                minute
              ).getTime() || undefined
            );
          }}
        />
        <Input
          type={"submit"}
          value={intl.formatMessage(messages.submit)}
          disabled={!tier || !start}
          onClick={() => {
            setLoading(true);
            fetch("https://pokeboxadvance.net/api/raid/report.php", {
              method: "POST",
              mode: "cors",
              cache: "no-cache",
              body: JSON.stringify({ gym: id, tier, start })
            })
              .then(response => {
                if (response.ok && response.status === 200) {
                  return response.json();
                }
              })
              .then(json => {
                if (json.success) {
                  displayNotification({
                    success: true,
                    text: intl.formatMessage(messages.notification, {
                      tier,
                      gymName: name,
                      start: intl.formatTime(start)
                    })
                  });
                  setTier(undefined);
                  setStart(undefined);
                  setLoading(false);
                  onSubmitComplete && onSubmitComplete();
                } else {
                  throw new Error(json.message);
                }
              })
              .catch(error => {
                setLoading(false);
                displayNotification({
                  success: false,
                  text: intl.formatMessage(messages.error, {
                    message: error.message
                  })
                });
              });
          }}
          inverted
        />
      </ChildSpacing>
    </Loadable>
  );
};
