import React from "react";
import StyledSVG from "../portrait/StyledSVG";

export default props => (
  <StyledSVG
    {...props}
    viewBox="0 0 101 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.8476 2.28793C35.8476 1.18336 36.743 0.287933 37.8476 0.287933H63.4427C64.5473 0.287933 65.4427 1.18336 65.4427 2.28793V7.68672L91.6451 7.68671C96.6157 7.68671 100.645 11.7161 100.645 16.6867V72.6746C100.645 77.6451 96.6157 81.6746 91.6451 81.6746H9.64514C4.67458 81.6746 0.645142 77.6451 0.645142 72.6746V16.6867C0.645142 11.7162 4.67458 7.68671 9.64514 7.68671L35.8476 7.68672V2.28793ZM50.6451 64.6806C61.6908 64.6806 70.6451 55.7263 70.6451 44.6806C70.6451 33.6349 61.6908 24.6806 50.6451 24.6806C39.5994 24.6806 30.6451 33.6349 30.6451 44.6806C30.6451 55.7263 39.5994 64.6806 50.6451 64.6806Z"
      fill="currentcolor"
    />
  </StyledSVG>
);
