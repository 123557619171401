import React, { useState } from "react";
import styled from "styled-components";

import _Section from "./Section";
import Flex from "./utility/Flex";
import { space, transition, anchor } from "../theme";
import { selectedSpace } from "../utility/styling";

const Header = styled(Flex)`
  justify-content: space-evenly;
  ${selectedSpace({ selectedMb: space("medium") })}
`;

const Section = styled(_Section)`
  cursor: pointer;
  border-bottom: 0.2em solid white;
  ${({ selected }) => selected && "border-bottom-color: currentcolor;"}
  ${anchor}
  transition: color 50ms linear, border-bottom 300ms ${transition("in")};
`;

const GroupWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const GroupSlider = styled(Flex)`
  position: relative;
  width: ${({ length = 1 }) => length}00%;
  transition: left 500ms ${transition("in")};
  left: calc(-${({ left }) => left}00%);
`;

const TabGroup = styled(Flex)`
  overflow: visible;
  width: 100%;
  width: calc(100% / ${({ length = 1 }) => length});
  ${selectedSpace({ selectedPx: space("tiny") })}
`;

export default ({ tabs = [] }) => {
  const [open, setOpen] = useState(0);
  return (
    <>
      <Header>
        {tabs.map(({ title }, index) => (
          <Section
            key={index}
            selected={open === index}
            onClick={() => setOpen(index)}
          >
            {title}
          </Section>
        ))}
      </Header>
      <GroupWrapper>
        <GroupSlider length={tabs.length} left={open}>
          {tabs.map(({ content }, index) => (
            <TabGroup length={tabs.length} key={index} column>
              {content}
            </TabGroup>
          ))}
        </GroupSlider>
      </GroupWrapper>
    </>
  );
};
