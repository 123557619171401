import React from "react";
import styled from "styled-components";
import { size } from "styled-system";
import { fontSize } from "../../theme";

const Image = styled.img`
  width: 100%;
  border-radius: 100vw;
  border: solid;
  border-width: ${({ ex }) => (ex ? "3px" : "2px")};
  border-color: currentcolor;
  ${fontSize("text")}
  ${size}
`;

export default ({ id, name, ex, onClick, className }) => (
  <Image
    src={`https://pokeboxadvance.net/assets/gyms/${id}.jpg`}
    alt={name}
    ex={ex}
    onClick={onClick}
    className={className}
  />
);
