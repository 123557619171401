import React from "react";
import styled from "styled-components";
import Input from "./Input";
import _Dropdown from "../assets/Dropdown";
import { selectedSpace } from "../utility/styling";
import { space } from "../theme";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Select = styled(Input.withComponent("select"))`
  > option:disabled {
    opacity: 0.5;
  }
`;

const Dropdown = styled(_Dropdown)`
  position: absolute;
  right: 0;
  height: 0.5em;
  ${selectedSpace({ selectedMr: space("small") })}
`;

// export default props => (
//   <Wrapper {...props}>
//     <Dropdown height={"0.4em"} width={"auto"} />
//     <Select {...props}>{props.children}</Select>
//   </Wrapper>
// );

export default props => <Select {...props}>{props.children}</Select>;
