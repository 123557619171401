import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c0 = shiny ? "#9cd68a" : "#59c5de";
  const c2 = shiny ? "#b19b51" : "#92342f";
  const c3 = shiny ? "#74dd79" : "#53cb77";
  const c4 = shiny ? "#d8cb3d" : "#d1334c";
  const c5 = shiny ? "#bf8c8b" : "#a97777";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Venusaur Portrait"
      id="Venusaur_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Venusaur</title>
      <path
        d="M87.74,36.93c2.72-4.94,4.94-10.11,3.52-11.87-1.73-2.13-10-1.2-16.78,0A45.75,45.75,0,0,0,50,18.22a46.7,46.7,0,0,0-25.93,7.52c-6.6-1.11-14.32-1.9-16,.15-1.39,1.73.71,6.73,3.36,11.58-7,9-10.21,18.77-10.21,23.28,0,9.54,19.09,28,48.76,28s48.76-19.71,48.76-28C98.76,56.68,95.31,46.37,87.74,36.93Z"
        fill={c0}
      />
      <path
        d="M50,18.22a45.75,45.75,0,0,1,24.48,6.83A72.36,72.36,0,0,1,86,23.79c2.57,0,4.56.35,5.31,1.27,1.42,1.76-.8,6.93-3.52,11.87,7.57,9.44,11,19.75,11,23.82,0,8.3-19.09,28-48.76,28S1.24,70.29,1.24,60.75c0-4.51,3.2-14.25,10.21-23.28C8.8,32.62,6.7,27.62,8.09,25.89c.75-.92,2.74-1.27,5.31-1.27a69.32,69.32,0,0,1,10.67,1.12A46.7,46.7,0,0,1,50,18.22M50,17a48,48,0,0,0-26.2,7.46,67.45,67.45,0,0,0-10.4-1.06c-3.33,0-5.32.56-6.28,1.74s-1,3,0,5.79A42.26,42.26,0,0,0,10,37.35a58,58,0,0,0-7.4,13A30.67,30.67,0,0,0,0,60.75c0,5.56,5.41,12.87,13.79,18.61a59.1,59.1,0,0,0,15.7,7.55A68.19,68.19,0,0,0,50,90a65.17,65.17,0,0,0,20.53-3.27,60.21,60.21,0,0,0,15.69-7.85,44.94,44.94,0,0,0,10.09-9.51c2.38-3.19,3.69-6.25,3.69-8.62,0-4.27-3.35-14.42-10.77-24a43.76,43.76,0,0,0,3-6.6c1-2.85,1-4.73,0-5.92s-3-1.74-6.28-1.74a70.56,70.56,0,0,0-11.22,1.2A47.17,47.17,0,0,0,50,17Z"
        fill={outline}
      />
      <path
        d="M10.37,28.8c-.83.83,3.12,10.79,4.15,11.2s6.64-4.15,6.43-5.39S11.2,28,10.37,28.8Z"
        fill={c2}
      />
      <path
        d="M89,28.18c.76.9-3.55,10.54-4.62,10.87s-6.51-4.48-6-5.48S88.26,27.29,89,28.18Z"
        fill={c2}
      />
      <polygon
        fill={c3}
        points="83.4 29.63 74.69 25.06 82.78 27.34 82.57 25.27 76.56 22.57 81.12 23.4 79.67 21.12 50 14.28 20.33 21.12 18.88 23.4 23.44 22.57 17.43 25.27 17.22 27.34 25.31 25.06 16.6 29.63 19.09 32.33 27.8 29.42 19.71 35.44 23.03 37.3 32.37 33.57 25.73 40 29.46 42.49 36.93 37.72 32.57 43.94 36.93 47.05 43.36 41.87 40.04 49.13 45.02 51.62 50 44.98 54.98 51.62 59.96 49.13 56.64 41.87 63.07 47.05 67.43 43.94 63.07 37.72 70.54 42.49 74.27 40 67.64 33.57 76.97 37.3 80.29 35.44 72.2 29.42 80.91 32.33 83.4 29.63"
      />
      <path
        d="M50,14.27l29.67,6.85,1.45,2.28-4.56-.83,6,2.7.21,2.08-8.09-2.29,8.71,4.57-2.49,2.69-8.71-2.9,8.09,6L77,37.3l-9.34-3.73L74.27,40l-3.73,2.49-7.47-4.77,4.36,6.22-4.36,3.11-6.43-5.18L60,49.13l-5,2.49L50,45l-5,6.64-5-2.49,3.32-7.26-6.43,5.18-4.36-3.11,4.36-6.22-7.47,4.77L25.73,40l6.64-6.43L23,37.3l-3.32-1.86,8.09-6-8.71,2.9L16.6,29.63l8.71-4.57-8.09,2.29.21-2.08,6-2.7-4.56.83,1.45-2.28L50,14.27M50,13l-.28.06L20.05,19.91l-.49.11-.28.43-1.45,2.29L17,24.12h0l-.66.3-.07.72L16,27.22l-.14,1.4-1.26.66,1.1,1.19,2.49,2.7.55.59.76-.25,1.34-.45L19,34.44l-1.54,1.14,1.67.94,3.32,1.87.52.29.55-.22L27,37.07l-2.1,2-1.11,1.07L25,41l3.73,2.49.68.45.68-.44,2.18-1.39-.76,1.08-.7,1,1,.72,4.36,3.11.77.55.73-.6L40,46.15l-1.13,2.46-.5,1.1,1.07.53,5,2.49.93.47.63-.83,4-5.32,4,5.32.63.83.93-.47,5-2.49,1.07-.53-.5-1.1L60,46.15,62.29,48l.73.6.77-.55L68.15,45l1-.72-.7-1-.76-1.08,2.18,1.39.68.44.68-.45L75,41l1.29-.86-1.11-1.07-2.1-2,3.47,1.39.55.22.52-.29,3.32-1.87,1.67-.94L81,34.44l-1.85-1.38,1.34.45.76.25.55-.59,2.49-2.7,1.1-1.19-1.26-.66L84,27.22l-.21-2.07-.07-.72-.66-.3h0l-.88-1.38-1.45-2.29L80.44,20,80,19.91,50.28,13.06,50,13Z"
        fill={outline}
      />
      <path
        d="M48.34,14.48,50,32.53l1.66-18S50.41,11.16,48.34,14.48Z"
        fill={c3}
      />
      <path
        d="M50.12,13c1,0,1.54,1.47,1.54,1.47L50,32.53l-1.66-18C49,13.38,49.63,13,50.12,13m0-.83c-.89,0-1.7.61-2.48,1.86a.88.88,0,0,0-.13.52l1.66,18a.83.83,0,0,0,1.66,0l1.66-18a.92.92,0,0,0-.05-.37c-.08-.21-.8-2-2.32-2Z"
        fill={outline}
      />
      <path
        d="M75.93,73.2a12.83,12.83,0,0,1,1.66,3.73c2.08-1.24,3.32-5,3.32-5l-5,1.25"
        fill={c4}
      />
      <path
        d="M47.3,76.52a.62.62,0,0,1-.6-.48,1.93,1.93,0,0,0-1.55-1.4.62.62,0,1,1,.16-1.23,3.17,3.17,0,0,1,2.6,2.33.63.63,0,0,1-.46.76Z"
        fill={outline}
      />
      <path
        d="M54.77,76.52l-.15,0a.62.62,0,0,1-.45-.76,3.15,3.15,0,0,1,2.6-2.33.62.62,0,0,1,.69.54.61.61,0,0,1-.53.69A1.94,1.94,0,0,0,55.38,76,.64.64,0,0,1,54.77,76.52Z"
        fill={outline}
      />
      <path
        d="M19.92,71.74S23.65,85.44,50,85.44s31.22-13.7,31.22-13.7L68.88,74.23,50,79.21,33,74.65Z"
        fill={c5}
      />
      <path
        d="M50,85.75c-13.7,0-21.22-3.76-25.12-6.92-4.25-3.44-5.22-6.86-5.26-7l-.14-.5,13.58,3L50,78.89l18.8-5,12.9-2.6-.18.52c0,.14-1.3,3.55-5.84,7C71.53,82,63.7,85.75,50,85.75ZM20.4,72.17a16.16,16.16,0,0,0,4.91,6.21c3.82,3.08,11.21,6.75,24.69,6.75s21.23-3.69,25.3-6.79a18.12,18.12,0,0,0,5.4-6.17L68.94,74.54,50,79.53l-.08,0L32.91,75Z"
        fill={outline}
      />
      <path
        d="M81.77,53a12.22,12.22,0,0,0-1.87-3.91A61.45,61.45,0,0,0,67.83,60c2,3.6,5.67,5.61,9,4.68C81,63.49,83.23,58.26,81.77,53Z"
        fill={"#fff"}
      />
      <path
        d="M75.67,52.24A62.58,62.58,0,0,0,71,56.49v0c.42,2.19,2.26,3.69,4.12,3.34s3-2.42,2.61-4.61A4.3,4.3,0,0,0,75.67,52.24Z"
        fill={c4}
      />
      <path
        d="M74,53.69h0c-.54.49-1.06,1-1.55,1.43v.13A1.56,1.56,0,1,0,74,53.69Z"
        fill={"#fff"}
      />
      <path
        d="M66.14,53.28a9.14,9.14,0,0,1,10.33-7.77,3.89,3.89,0,0,1,.47.09,8,8,0,0,0-1.71-.49,8.21,8.21,0,0,0-9.27,7A8.76,8.76,0,0,0,66,55.19,10.27,10.27,0,0,1,66.14,53.28Z"
        fill={outline}
      />
      <path
        d="M34.63,54.31A9.14,9.14,0,0,0,24.3,46.55a3.89,3.89,0,0,0-.47.09,7.48,7.48,0,0,1,1.71-.49,8.2,8.2,0,0,1,9.27,7,8.76,8.76,0,0,1-.06,3.11A10.43,10.43,0,0,0,34.63,54.31Z"
        fill={outline}
      />
      <path
        d="M19,54a12.27,12.27,0,0,1,1.87-3.92A61.76,61.76,0,0,1,32.94,61c-2,3.6-5.67,5.61-9,4.68C19.73,64.53,17.54,59.3,19,54Z"
        fill={"#fff"}
      />
      <path
        d="M25.11,53.28c1.79,1.48,3.35,2.94,4.65,4.25h0c-.42,2.2-2.26,3.7-4.12,3.35s-3-2.42-2.61-4.62A4.29,4.29,0,0,1,25.11,53.28Z"
        fill={c4}
      />
      <path
        d="M26.81,54.73h0c.54.48,1.06,1,1.55,1.42v.14a1.56,1.56,0,0,1-3.11,0A1.56,1.56,0,0,1,26.81,54.73Z"
        fill={"#fff"}
      />
      <path
        d="M92.94,64.41a.41.41,0,0,0-.58,0c-7.21,6.42-13.46,7.54-21.38,9l-.41.07A91.69,91.69,0,0,0,59.69,76.3c-3.94,1.22-7.34,2.28-9.79,2.29S44,77.52,40.11,76.3a92.38,92.38,0,0,0-10.89-2.89l-.41-.07c-7.92-1.42-14.17-2.54-21.38-9a.41.41,0,0,0-.58,0,.42.42,0,0,0,0,.59c7.38,6.57,13.74,7.71,21.78,9.15l.42.08a90.15,90.15,0,0,1,10.78,2.86c4,1.25,7.45,2.32,10,2.33h0c2.58,0,6-1.08,10-2.33a90.32,90.32,0,0,1,10.79-2.86l.41-.08c8.05-1.44,14.4-2.58,21.78-9.15A.41.41,0,0,0,92.94,64.41Z"
        fill={outline}
      />
      <path
        d="M16.68,71.18c.42,1.28,2.25,6.8,2.61,5.34.26-1,.52-2.93.68-4.23C18.86,72,17.77,71.61,16.68,71.18Z"
        fill={"#fff"}
      />
      <path
        d="M84.49,70.35c-.42,1.28-2.25,6.8-2.61,5.34-.26-1-.52-2.93-.68-4.23C82.31,71.14,83.4,70.78,84.49,70.35Z"
        fill={"#fff"}
      />
      <path
        d="M24.69,79.84c.62-1,1.66-2.7,1.66-2.7s1,2.07,1.87,3.94C27,81.49,25.31,81.49,24.69,79.84Z"
        fill={"#fff"}
      />
      <path
        d="M27,80.87c.62-1,1.66-2.69,1.66-2.69s1,2.07,1.87,3.94C29.25,82.53,27.59,82.53,27,80.87Z"
        fill={"#fff"}
      />
      <path
        d="M77.16,79.17c-.63-1-1.68-2.73-1.68-2.73s-1,2.1-1.89,4C74.85,80.85,76.53,80.85,77.16,79.17Z"
        fill={"#fff"}
      />
      <path
        d="M74.88,80.17l-1.66-2.69s-1,2.07-1.87,3.94C72.59,81.83,74.25,81.83,74.88,80.17Z"
        fill={"#fff"}
      />
    </SVG>
  );
};
