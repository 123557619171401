import React from "react";
import styled from "styled-components";

import { selectedSpace } from "../utility/styling";
import { space } from "../theme";

export default styled.div`
  max-width: 800px;
  justify-self: center;
  ${selectedSpace({ selectedMx: space("smaller") })}
`;
