import React from "react";
import styled from "styled-components";

import { selectedFontSize } from "../utility/styling";
import { fontSize } from "../theme";

import FontWeightNormal from "./utility/FontWeightNormal";
import { themeGet } from "styled-system";

export default styled(FontWeightNormal.withComponent("h2"))`
  ${selectedFontSize(themeGet("fontSize.heading.medium"))};
`;
