import React from "react";

import theme from "../../theme";

import SVG from "./StyledSVG";

export default ({ shiny, outline = theme.portrait.outline }) => {
  const c0 = shiny ? "#ffcf72" : "#e27f6e";
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      data-name="Charmeleon Portrait"
      id="Charmeleon_Portrait"
      viewBox="0 0 100 100"
    >
      <title>{shiny && "Shiny "}Charmeleon</title>
      <path
        d="M79.31,74,49,70.52h0L18.61,74S34.5,94.91,46.48,98.12a13.73,13.73,0,0,0,5,0C63.42,94.91,79.31,74,79.31,74Z"
        fill={c0}
      />
      <path
        d="M49,70.52,79.31,74S63.42,94.91,51.43,98.12a9.17,9.17,0,0,1-2.47.22,9.26,9.26,0,0,1-2.48-.22C34.5,94.91,18.61,74,18.61,74L49,70.52h0m.09-1.66h-.28L18.42,72.32l-2.89.33L17.29,75a136.64,136.64,0,0,0,9.57,11c7.43,7.65,13.85,12.26,19.1,13.7a9,9,0,0,0,3,.31,9.06,9.06,0,0,0,3-.31c5.25-1.44,11.67-6,19.09-13.7a135.63,135.63,0,0,0,9.58-11l1.75-2.32-2.89-.33L49.15,68.87Z"
        fill={outline}
      />
      <path
        d="M77.24,73.28,48.89,70.06h0L20.54,73.28s14.84,19.56,26,22.56a12.81,12.81,0,0,0,4.62,0C62.4,92.84,77.24,73.28,77.24,73.28Z"
        fill={"#78372d"}
      />
      <path
        d="M70.34,71,48.82,67.76h0L27.3,71S38.56,90.54,47.06,93.54a7.44,7.44,0,0,0,3.51,0C59.07,90.54,70.34,71,70.34,71Z"
        fill={"#d3807a"}
      />
      <path
        d="M73.93,34.93c-3.72-5.52-13.52-10.35-15.31-13.24S55.45,1.55,49,1.55h-.14c-6.48,0-7.86,17.24-9.66,20.14S27.58,29.41,23.85,34.93,15.24,65.08,16.1,71.39s15.61,7.82,19.34,9.06,8.14,8,13.38,8H49c5.25,0,9.66-6.76,13.39-8s18.48-2.76,19.33-9.06S77.66,40.45,73.93,34.93Z"
        fill={c0}
      />
      <path
        d="M49,1.55c6.49,0,7.87,17.24,9.66,20.14s11.59,7.72,15.31,13.24,8.61,30.15,7.75,36.46-15.61,7.82-19.33,9.06-8.14,8-13.39,8h-.14c-5.24,0-9.66-6.76-13.38-8S17,77.69,16.1,71.39s4-30.94,7.75-36.46S37.37,24.58,39.16,21.69,42.34,1.55,48.82,1.55H49M49-.11h-.14a5.67,5.67,0,0,0-4.19,2A13.89,13.89,0,0,0,42.09,6a61.66,61.66,0,0,0-2.93,9.91,29,29,0,0,1-1.4,4.94c-.6,1-2.93,2.62-5.18,4.23-3.66,2.61-7.81,5.57-10.1,9-2.69,4-5.15,15.19-5.84,18.53C15.31,59,13.94,67.8,14.46,71.61c.83,6.12,10.57,8.21,17,9.59,1.47.31,2.74.59,3.45.82a16.15,16.15,0,0,1,4.22,3c2.77,2.38,5.91,5.08,9.68,5.08H49c3.78,0,6.92-2.7,9.69-5.08a16.15,16.15,0,0,1,4.22-3c.7-.23,2-.51,3.44-.82,6.45-1.38,16.18-3.47,17-9.59.52-3.81-.85-12.66-2.17-19.08C80.46,49.19,78,38,75.3,34c-2.29-3.39-6.44-6.35-10.1-9C63,23.43,60.62,21.77,60,20.81a28.79,28.79,0,0,1-1.41-4.94A60.66,60.66,0,0,0,55.69,6a14.09,14.09,0,0,0-2.53-4.11,5.71,5.71,0,0,0-4.2-2Z"
        fill={outline}
      />
      <path
        d="M41.53,89.14C41,89,38.85,90.93,39,91.53s2.35,2.1,2.77,1.49S42.07,89.27,41.53,89.14Z"
        fill={"#fff"}
      />
      <path
        d="M56.39,89.1c.54-.1,2.56,2,2.38,2.54s-2.48,2-2.86,1.31S55.84,89.19,56.39,89.1Z"
        fill={"#fff"}
      />
      <path d="M42.47,73.83a18.67,18.67,0,0,0,2.21,4.83" fill={"#da7059"} />
      <path
        d="M44.68,79.21a.56.56,0,0,1-.45-.23,19.25,19.25,0,0,1-2.29-5,.56.56,0,0,1,.38-.68.54.54,0,0,1,.68.38,18.21,18.21,0,0,0,2.13,4.66.55.55,0,0,1-.45.87Z"
        fill={outline}
      />
      <path d="M55.44,74.1a18.67,18.67,0,0,1-2.21,4.83" fill={"#da7059"} />
      <path
        d="M53.23,79.48a.55.55,0,0,1-.32-.1.56.56,0,0,1-.13-.77A18.21,18.21,0,0,0,54.91,74a.55.55,0,1,1,1.06.31,18.74,18.74,0,0,1-2.29,5A.53.53,0,0,1,53.23,79.48Z"
        fill={outline}
      />
      <path d="M39.09,47.41a21.1,21.1,0,0,1,2.63,7.73" fill={"#da7059"} />
      <path
        d="M41.71,55.69a.54.54,0,0,1-.54-.48,20.5,20.5,0,0,0-2.54-7.5.56.56,0,0,1,.17-.77.55.55,0,0,1,.76.18,21.38,21.38,0,0,1,2.7,8,.55.55,0,0,1-.48.62Z"
        fill={outline}
      />
      <path d="M58.54,47.41a21.2,21.2,0,0,0-2.62,7.73" fill={"#da7059"} />
      <path
        d="M55.92,55.69h-.06a.55.55,0,0,1-.48-.62,21.38,21.38,0,0,1,2.7-8,.55.55,0,0,1,.76-.18.56.56,0,0,1,.17.77,20.56,20.56,0,0,0-2.54,7.5A.54.54,0,0,1,55.92,55.69Z"
        fill={outline}
      />
      <path
        d="M74.26,51.07c.32.43,1.27,10.1-1.27,12.7S67.58,67,62.82,65.36,73.94,50.64,74.26,51.07Z"
        fill={"#fff"}
      />
      <path
        d="M62.82,65.36c-2.53-.84.73-4.91,4.41-8.5,2.3.53,4,3.22,4,6.48a8.35,8.35,0,0,1-.22,2C69,66.52,66.43,66.57,62.82,65.36Z"
        fill={"#38586f"}
      />
      <path
        d="M68.85,62a4.8,4.8,0,0,1-1.95,4.2,14.37,14.37,0,0,1-2.74-.46A5.34,5.34,0,0,1,62.82,62V62a29.38,29.38,0,0,1,3.63-4.32C67.83,58.06,68.85,59.86,68.85,62Z"
        fill={outline}
      />
      <path
        d="M66.81,57.28a2.39,2.39,0,1,1-2.25,2.39C65.24,58.88,66,58.07,66.81,57.28Z"
        fill={"#fff"}
      />
      <path
        d="M23.6,51.07c-.32.43-1.27,10.1,1.27,12.7S30.28,67,35.05,65.36,23.92,50.64,23.6,51.07Z"
        fill={"#fff"}
      />
      <path
        d="M35.05,65.36c2.52-.84-.74-4.91-4.42-8.5-2.3.53-4.05,3.22-4.05,6.48a8.35,8.35,0,0,0,.22,2C28.91,66.52,31.43,66.57,35.05,65.36Z"
        fill={"#38586f"}
      />
      <path
        d="M29,62A4.8,4.8,0,0,0,31,66.23a14.37,14.37,0,0,0,2.74-.46A5.34,5.34,0,0,0,35.05,62S35,62,35,62a28.77,28.77,0,0,0-3.63-4.32C30,58.06,29,59.86,29,62Z"
        fill={outline}
      />
      <path
        d="M31.06,57.28a2.39,2.39,0,1,0,2.24,2.39C32.62,58.88,31.85,58.07,31.06,57.28Z"
        fill={"#fff"}
      />
    </SVG>
  );
};
